@use "sass:map";

// Variables

// Screen sizes
$screen-xs-min: 0;
$screen-xs-max: 599px;
$screen-s-min: 600px;
$screen-s-max: 899px;
$screen-m-min: 900px;
$screen-m-max: 1199px;
$screen-l-min: 1200px;
$screen-l-max: 1599px;
$screen-xl-min: 1600px;

$breakpoints: (
  "s": $screen-s-min,
  "m": $screen-m-min,
  "l": $screen-l-min,
  "xl": $screen-xl-min,
);

// Layout
$container-min-width: 22.5rem;
$container-max-width: 80rem;
$container-padding: 1rem;
$container-padding-lg: 2.5rem;

// Grid
$grid-column-gap: 1.5rem;
$grid-column-gap-s: .75rem;
$grid-column-gap-l: 2.5rem;
$grid-columns: 12;

// Size
$sizes: (
  "auto": auto,
  "25": 25%,
  "50": 50%,
  "66": 66.6%,
  "75": 75%,
  "100": 100%,
  "100px": 100px,
  "150px": 150px,
  "200px": 200px,
  "250px": 250px,
  "300px": 300px,
  "350px": 350px,
  "400px": 400px,
  "450px": 450px,
  "500px": 500px,
  "550px": 550px,
  "600px": 600px,
  "650px": 650px,
  "700px": 700px,
  "750px": 750px,
  "800px": 800px,
  "900px": 900px,
  "950px": 950px,
  "1000px": 1000px,
  "1100px": 1100px,
  "1200px": 1200px,
);

$sizes-char: (
  "10ch": 10ch,
  "15ch": 15ch,
  "20ch": 20ch,
  "25ch": 25ch,
  "30ch": 30ch,
  "35ch": 35ch,
  "40ch": 40ch,
  "45ch": 45ch,
  "50ch": 50ch,
  "55ch": 55ch,
  "60ch": 60ch,
  "65ch": 65ch,
  "70ch": 70ch,
  "75ch": 75ch,
  "80ch": 80ch,
);

// Space
$spacer-1: .25rem;
$spacer-2: .5rem;
$spacer-3: .75rem;
$spacer-4: 1rem;
$spacer-5: 1.25rem;
$spacer-6: 1.5rem;
$spacer-8: 2rem;
$spacer-10: 2.5rem;
$spacer-12: 3rem;
$spacer-14: 3.5rem;
$spacer-16: 4rem;
$spacer-20: 5rem;
$spacer-24: 6rem;
$spacer-32: 8rem;
$spacer-40: 10rem;

$spacers: (
  0: 0,
  1: $spacer-1,
  2: $spacer-2,
  3: $spacer-3,
  4: $spacer-4,
  5: $spacer-5,
  6: $spacer-6,
  8: $spacer-8,
  10: $spacer-10,
  12: $spacer-12,
  14: $spacer-14,
  16: $spacer-16,
  20: $spacer-20,
  24: $spacer-24,
  32: $spacer-32,
  40: $spacer-40,
  1em: 1em,
);

// Fonts
$font-family-primary: 'Open Sans', sans-serif;
$font-family-secondary: 'Readex Pro', sans-serif;
$font-family-tertiary: 'Work Sans', sans-serif;
$font-size-base: 1rem;
$font-size-2xs: .75rem;
$font-size-xs: .875rem;
$font-size-s: .9375rem;
$font-size-m: 1.125rem;
$font-size-l: 1.25rem;
$font-size-xl: 1.5rem;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$line-height-base: 1.5;
$line-height-s: 1.2;
$line-height-l: 2;
$line-height-heading: 1.2;

$font-sizes: (
  "base": $font-size-base,
  "2xs": $font-size-2xs,
  "xs": $font-size-xs,
  "s": $font-size-s,
  "m": $font-size-m,
  "l": $font-size-l,
  "xl": $font-size-xl,
);

$font-weights: (
  "light": $font-weight-light,
  "normal": $font-weight-normal,
  "medium": $font-weight-medium,
  "semibold": $font-weight-semibold,
  "bold": $font-weight-bold,
);

$line-heights: (
  "1": 1,
  "base": $line-height-base,
  "heading": $line-height-heading,
  "s": $line-height-s,
  "l": $line-height-l,
);

$text-align: (
  "left": left,
  "center": center,
  "right": right,
);

$text-wrap: (
  "wrap": wrap,
  "nowrap": nowrap,
);

// Colors
$brand-blue: #076bad;
$brand-blue-light: #489ed7;
$brand-blue-dark: #2e5888;
$brand-red: #ee2f36;

$brand-colors: (
  "brand-blue": $brand-blue,
  "brand-blue-light": $brand-blue-light,
  "brand-blue-dark": $brand-blue-dark,
  "brand-red": $brand-red,
);

$gray-100: #f7f7fd;
$gray-200: #e5e7f1;
$gray-300: #d9d9e3;
$gray-400: #adb4b9;
$gray-500: #6c7780;
$gray-600: #6e697a;
$gray-700: #484848;
$gray-800: #333;
$gray-900: #0f1f32;

$blue-light: #5eacca;

//$text-primary-default: #2a2536;
//$text-primary-inverted: #fff;
//$text-secondary-default: #5b5665;
//$text-primary-inverted: #fff;
//$text-secondary-disabled: #c2c8ce;
//
//$action-link-default: $brand-blue;
//$action-link-hover: #0087d6;
//$action-link-active: #007bc3;
//$action-link-disabled: #c2c8ce;
//
//$action-primary-default: #69cdf6; 
//$action-primary-hover: #4abef3; 
//$action-primary-active: #2aaff1; 
//$action-primary-disabled: #c2c8ce; 
//
//$action-secondary-default: #69cdf6; 
//$action-secondary-hover: #4abef3; 
//$action-secondary-active: #2aaff1; 
//$action-secondary-disabled: #c2c8ce; 

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900,
);

$red: #d00;
$yellow: #f9c000;
$green: #36d49b;

$text-color: $gray-900;
$text-muted: $gray-400;
$danger-color: $brand-red;
$success-color: $green;
$warning-color: $yellow;

$body-bg: #fff;

// Color
$colors: map.merge(
  (
    "body": $text-color,
    "reset": inherit,
    "white": #fff,
    "secondary": $gray-600,
    "success": $success-color,
    "danger": $danger-color,
    "warning": $warning-color,
  ),
  $brand-colors
);

// Border
$border-color: $gray-200;
$border-width: 1px;

// Border radius
$border-radius-s: .25rem;
$border-radius-m: .375rem;
$border-radius-l: .75rem;
$border-radius-xl: 1.25rem;
$border-radius-2xl: 2rem;
$border-radius-3xl: 2.5rem;

$border-radii: (
  s: $border-radius-s,
  m: $border-radius-m,
  l: $border-radius-l,
  xl: $border-radius-xl,
  2xl: $border-radius-2xl,
  3xl: $border-radius-3xl,
  circle: 9999px,
);

// Transitions
$transition-speed-slow: .7s;
$transition-speed-normal: .3s;
$transition-speed-fast: .15s;

// Display
$display: (
  none: none,
  inline: inline,
  inline-block: inline-block,
  block: block,
  inline-flex: inline-flex,
  flex: flex,
  inline-grid: inline-grid,
  grid: grid,
);

// Opacity
$opacity: (
  0: 0,
  10: .1,
  20: .2,
  30: .3,
  40: .4,
  50: .5,
  60: .6,
  70: .7,
  80: .8,
  90: .9,
  100: 1,
);

// Pointer events
$pointer-events: (
  "auto": auto,
  "none": none,
);

// Border
$borders: (
  "0": 0,
  "1": $border-width solid $border-color
);

// Flex
$flex-directions: (
  row: row,
  row-reverse: row-reverse,
  column: column,
  column-reverse: column-reverse,
);

$flex-wrap: (
  wrap: wrap,
  nowrap: nowrap,
);

$flex-justify-content: (
  start: flex-start,
  center: center,
  end: flex-end,
  between: space-between,
  around: space-around,
  stretch: stretch,
);

$flex-align-items: (
  start: flex-start,
  center: center,
  end: flex-end,
  baseline: baseline,
);

$flex-shrink: (
  0: 0,
  1: 1,
);

$flex-values: (
  auto: auto,
  none: none,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
);

$orders: (
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
);

// Header
$header-breakpoint: $screen-l-min;
$header-height: 100px;
$header-transition-speed: $transition-speed-normal;
$header-transition-easing: ease-in-out;
$header-transition: $header-transition-speed $header-transition-easing;
$header-bg: #fff;
$nav-toggle-width: 2.25rem;

// Scrollbar
$scrollbar-width: 10px;
$scrollbar-track-color: transparent;
$scrollbar-thumb-color: $gray-300;
$scrollbar-thumb-hover-color: $gray-400;

// Section margin/padding multipliers
$section-multipliers: (
  "0": 0,
  "025": .25,
  "05": .5,
  "075": .75,
  "1": 1,
  "125": 1.25,
  "15": 1.5,
  "175": 1.75,
  "2": 2,
);

// Section background positions
$section-bg-positions: (
  "0": 0,
  "25": 25%,
  "50": 50%,
  "75": 75%,
  "1": 100%,
);

// Aspect ratios
$aspect-ratios: (
  4x3: "4 / 3",
  16x9: "16 / 9",
);
