@use "sass:math";
@use "variables" as var;
@use "functions";

// Mixins

@mixin ellipsis() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin link-stretched() {
  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }
}

@mixin build-columns($cols, $prefix: '') {
  @if $prefix != '' {
    $prefix: #{$prefix}\:;
  }
  @while $cols > 0 {
    .#{$prefix}col-#{$cols} {
      width: math.percentage(math.div($cols, var.$grid-columns));
      $cols: $cols - 1;
    }
  }
}

@mixin fluid($property, $min-value, $max-value, $min-vw: var.$container-min-width, $max-vw: var.$container-max-width) {
  $u1: math.unit($min-value);
  $u2: math.unit($max-value);
  $u3: math.unit($min-vw);
  $u4: math.unit($max-vw);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      #{$property}: clamp(#{$min-value}, calc(#{$min-value} + #{functions.strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{functions.strip-unit($max-vw - $min-vw)})), #{$max-value});
    }
  }
}

@mixin breakpoint-iterator($map, $name, $property, $negative: false) {
  // Default: no breakpoint prefix
  @include rule-iterator($map, '', $name, $property, $negative);

  // Prefixed: loop over breakpoints
  @each $key, $value in var.$breakpoints {
    @media (min-width: #{$value}) {
      @include rule-iterator($map, $key, $name, $property, $negative);
    }
  }
}

@mixin rule-iterator($map, $prefix, $name, $properties, $negative: false) {
  @if $prefix != '' {
    $prefix: #{$prefix}\:;
  }
  @each $key, $value in $map {
    @if $negative == true {
      $key: n#{$key};
      $value: -#{$value};
    }
    
    // Add x/y classes for margin/padding
    #{if($name == 'mt' or $name == 'mb', '.#{$prefix}my-#{$key},', '')}
    #{if($name == 'ml' or $name == 'mr', '.#{$prefix}mx-#{$key},', '')}
    #{if($name == 'pt' or $name == 'pb', '.#{$prefix}py-#{$key},', '')}
    #{if($name == 'pl' or $name == 'pr', '.#{$prefix}px-#{$key},', '')}
    .#{$prefix}#{$name}-#{$key} {
      @each $property in $properties {
        #{$property}: #{$value} !important;
      }
    }
  }
}

// Custom scrollbar
@mixin scrollbar($axis: 'y') {
  scrollbar-width: thin;
  scrollbar-color: var.$scrollbar-thumb-color var.$scrollbar-track-color;

  &::-webkit-scrollbar {
    display: unset;

    @if ($axis: 'x') {
      height: var.$scrollbar-width;
    }

    @if ($axis: 'y') {
      width: var.$scrollbar-width;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: var.$scrollbar-track-color;
    border-radius: (var.$scrollbar-width * .5);
  }

  &::-webkit-scrollbar-thumb {
    border: (var.$scrollbar-width * .25) solid transparent;
    background-color: var.$scrollbar-thumb-color;
    border-radius: (var.$scrollbar-width * .5);
    background-clip: content-box;

    &:hover {
      background-color: var.$scrollbar-thumb-hover-color;
    }
  }
}

@mixin focus-ring() {
  outline: 0;
  // TODO!
  //outline: .25rem solid rgba(var.$action-link-default, 0);
  //outline-offset: 1px;
  //
  //&:focus-visible {
  //  outline-color: rgba(var.$action-link-default, .15);
  //}
}
