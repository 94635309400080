.fs-base {
  font-size: 1rem !important;
}

.fs-2xs {
  font-size: 0.75rem !important;
}

.fs-xs {
  font-size: 0.875rem !important;
}

.fs-s {
  font-size: 0.9375rem !important;
}

.fs-m {
  font-size: 1.125rem !important;
}

.fs-l {
  font-size: 1.25rem !important;
}

.fs-xl {
  font-size: 1.5rem !important;
}

@media (min-width: 600px) {
  .s\:fs-base {
    font-size: 1rem !important;
  }

  .s\:fs-2xs {
    font-size: 0.75rem !important;
  }

  .s\:fs-xs {
    font-size: 0.875rem !important;
  }

  .s\:fs-s {
    font-size: 0.9375rem !important;
  }

  .s\:fs-m {
    font-size: 1.125rem !important;
  }

  .s\:fs-l {
    font-size: 1.25rem !important;
  }

  .s\:fs-xl {
    font-size: 1.5rem !important;
  }
}
@media (min-width: 900px) {
  .m\:fs-base {
    font-size: 1rem !important;
  }

  .m\:fs-2xs {
    font-size: 0.75rem !important;
  }

  .m\:fs-xs {
    font-size: 0.875rem !important;
  }

  .m\:fs-s {
    font-size: 0.9375rem !important;
  }

  .m\:fs-m {
    font-size: 1.125rem !important;
  }

  .m\:fs-l {
    font-size: 1.25rem !important;
  }

  .m\:fs-xl {
    font-size: 1.5rem !important;
  }
}
@media (min-width: 1200px) {
  .l\:fs-base {
    font-size: 1rem !important;
  }

  .l\:fs-2xs {
    font-size: 0.75rem !important;
  }

  .l\:fs-xs {
    font-size: 0.875rem !important;
  }

  .l\:fs-s {
    font-size: 0.9375rem !important;
  }

  .l\:fs-m {
    font-size: 1.125rem !important;
  }

  .l\:fs-l {
    font-size: 1.25rem !important;
  }

  .l\:fs-xl {
    font-size: 1.5rem !important;
  }
}
@media (min-width: 1600px) {
  .xl\:fs-base {
    font-size: 1rem !important;
  }

  .xl\:fs-2xs {
    font-size: 0.75rem !important;
  }

  .xl\:fs-xs {
    font-size: 0.875rem !important;
  }

  .xl\:fs-s {
    font-size: 0.9375rem !important;
  }

  .xl\:fs-m {
    font-size: 1.125rem !important;
  }

  .xl\:fs-l {
    font-size: 1.25rem !important;
  }

  .xl\:fs-xl {
    font-size: 1.5rem !important;
  }
}
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

@media (min-width: 600px) {
  .s\:text-left {
    text-align: left !important;
  }

  .s\:text-center {
    text-align: center !important;
  }

  .s\:text-right {
    text-align: right !important;
  }
}
@media (min-width: 900px) {
  .m\:text-left {
    text-align: left !important;
  }

  .m\:text-center {
    text-align: center !important;
  }

  .m\:text-right {
    text-align: right !important;
  }
}
@media (min-width: 1200px) {
  .l\:text-left {
    text-align: left !important;
  }

  .l\:text-center {
    text-align: center !important;
  }

  .l\:text-right {
    text-align: right !important;
  }
}
@media (min-width: 1600px) {
  .xl\:text-left {
    text-align: left !important;
  }

  .xl\:text-center {
    text-align: center !important;
  }

  .xl\:text-right {
    text-align: right !important;
  }
}
.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

@media (min-width: 600px) {
  .s\:fw-light {
    font-weight: 300 !important;
  }

  .s\:fw-normal {
    font-weight: 400 !important;
  }

  .s\:fw-medium {
    font-weight: 500 !important;
  }

  .s\:fw-semibold {
    font-weight: 600 !important;
  }

  .s\:fw-bold {
    font-weight: 700 !important;
  }
}
@media (min-width: 900px) {
  .m\:fw-light {
    font-weight: 300 !important;
  }

  .m\:fw-normal {
    font-weight: 400 !important;
  }

  .m\:fw-medium {
    font-weight: 500 !important;
  }

  .m\:fw-semibold {
    font-weight: 600 !important;
  }

  .m\:fw-bold {
    font-weight: 700 !important;
  }
}
@media (min-width: 1200px) {
  .l\:fw-light {
    font-weight: 300 !important;
  }

  .l\:fw-normal {
    font-weight: 400 !important;
  }

  .l\:fw-medium {
    font-weight: 500 !important;
  }

  .l\:fw-semibold {
    font-weight: 600 !important;
  }

  .l\:fw-bold {
    font-weight: 700 !important;
  }
}
@media (min-width: 1600px) {
  .xl\:fw-light {
    font-weight: 300 !important;
  }

  .xl\:fw-normal {
    font-weight: 400 !important;
  }

  .xl\:fw-medium {
    font-weight: 500 !important;
  }

  .xl\:fw-semibold {
    font-weight: 600 !important;
  }

  .xl\:fw-bold {
    font-weight: 700 !important;
  }
}
.text-wrap {
  white-space: wrap !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

@media (min-width: 600px) {
  .s\:text-wrap {
    white-space: wrap !important;
  }

  .s\:text-nowrap {
    white-space: nowrap !important;
  }
}
@media (min-width: 900px) {
  .m\:text-wrap {
    white-space: wrap !important;
  }

  .m\:text-nowrap {
    white-space: nowrap !important;
  }
}
@media (min-width: 1200px) {
  .l\:text-wrap {
    white-space: wrap !important;
  }

  .l\:text-nowrap {
    white-space: nowrap !important;
  }
}
@media (min-width: 1600px) {
  .xl\:text-wrap {
    white-space: wrap !important;
  }

  .xl\:text-nowrap {
    white-space: nowrap !important;
  }
}
.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.text-body {
  color: #0f1f32 !important;
}

.text-reset {
  color: inherit !important;
}

.text-white {
  color: #fff !important;
}

.text-secondary {
  color: #6e697a !important;
}

.text-success {
  color: #36d49b !important;
}

.text-danger {
  color: #ee2f36 !important;
}

.text-warning {
  color: #f9c000 !important;
}

.text-brand-blue {
  color: #076bad !important;
}

.text-brand-blue-light {
  color: #489ed7 !important;
}

.text-brand-blue-dark {
  color: #2e5888 !important;
}

.text-brand-red {
  color: #ee2f36 !important;
}

.text-gray-100 {
  color: #f7f7fd !important;
}

.text-gray-200 {
  color: #e5e7f1 !important;
}

.text-gray-300 {
  color: #d9d9e3 !important;
}

.text-gray-400 {
  color: #adb4b9 !important;
}

.text-gray-500 {
  color: #6c7780 !important;
}

.text-gray-600 {
  color: #6e697a !important;
}

.text-gray-700 {
  color: #484848 !important;
}

.text-gray-800 {
  color: #333 !important;
}

.text-gray-900 {
  color: #0f1f32 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-heading {
  line-height: 1.2 !important;
}

.lh-s {
  line-height: 1.2 !important;
}

.lh-l {
  line-height: 2 !important;
}

.text-break {
  word-break: break-word;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-no-transform {
  text-transform: none !important;
}
.text-muted {
  color: #adb4b9;
}

.font-primary {
  font-family: "Open Sans", sans-serif;
}
.font-secondary {
  font-family: "Readex Pro", sans-serif;
}
.font-tertiary {
  font-family: "Work Sans", sans-serif;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bg-gray-100 {
  background-color: #f7f7fd !important;
}

.bg-gray-200 {
  background-color: #e5e7f1 !important;
}

.bg-gray-300 {
  background-color: #d9d9e3 !important;
}

.bg-gray-400 {
  background-color: #adb4b9 !important;
}

.bg-gray-500 {
  background-color: #6c7780 !important;
}

.bg-gray-600 {
  background-color: #6e697a !important;
}

.bg-gray-700 {
  background-color: #484848 !important;
}

.bg-gray-800 {
  background-color: #333 !important;
}

.bg-gray-900 {
  background-color: #0f1f32 !important;
}

@media (min-width: 600px) {
  .s\:bg-gray-100 {
    background-color: #f7f7fd !important;
  }

  .s\:bg-gray-200 {
    background-color: #e5e7f1 !important;
  }

  .s\:bg-gray-300 {
    background-color: #d9d9e3 !important;
  }

  .s\:bg-gray-400 {
    background-color: #adb4b9 !important;
  }

  .s\:bg-gray-500 {
    background-color: #6c7780 !important;
  }

  .s\:bg-gray-600 {
    background-color: #6e697a !important;
  }

  .s\:bg-gray-700 {
    background-color: #484848 !important;
  }

  .s\:bg-gray-800 {
    background-color: #333 !important;
  }

  .s\:bg-gray-900 {
    background-color: #0f1f32 !important;
  }
}
@media (min-width: 900px) {
  .m\:bg-gray-100 {
    background-color: #f7f7fd !important;
  }

  .m\:bg-gray-200 {
    background-color: #e5e7f1 !important;
  }

  .m\:bg-gray-300 {
    background-color: #d9d9e3 !important;
  }

  .m\:bg-gray-400 {
    background-color: #adb4b9 !important;
  }

  .m\:bg-gray-500 {
    background-color: #6c7780 !important;
  }

  .m\:bg-gray-600 {
    background-color: #6e697a !important;
  }

  .m\:bg-gray-700 {
    background-color: #484848 !important;
  }

  .m\:bg-gray-800 {
    background-color: #333 !important;
  }

  .m\:bg-gray-900 {
    background-color: #0f1f32 !important;
  }
}
@media (min-width: 1200px) {
  .l\:bg-gray-100 {
    background-color: #f7f7fd !important;
  }

  .l\:bg-gray-200 {
    background-color: #e5e7f1 !important;
  }

  .l\:bg-gray-300 {
    background-color: #d9d9e3 !important;
  }

  .l\:bg-gray-400 {
    background-color: #adb4b9 !important;
  }

  .l\:bg-gray-500 {
    background-color: #6c7780 !important;
  }

  .l\:bg-gray-600 {
    background-color: #6e697a !important;
  }

  .l\:bg-gray-700 {
    background-color: #484848 !important;
  }

  .l\:bg-gray-800 {
    background-color: #333 !important;
  }

  .l\:bg-gray-900 {
    background-color: #0f1f32 !important;
  }
}
@media (min-width: 1600px) {
  .xl\:bg-gray-100 {
    background-color: #f7f7fd !important;
  }

  .xl\:bg-gray-200 {
    background-color: #e5e7f1 !important;
  }

  .xl\:bg-gray-300 {
    background-color: #d9d9e3 !important;
  }

  .xl\:bg-gray-400 {
    background-color: #adb4b9 !important;
  }

  .xl\:bg-gray-500 {
    background-color: #6c7780 !important;
  }

  .xl\:bg-gray-600 {
    background-color: #6e697a !important;
  }

  .xl\:bg-gray-700 {
    background-color: #484848 !important;
  }

  .xl\:bg-gray-800 {
    background-color: #333 !important;
  }

  .xl\:bg-gray-900 {
    background-color: #0f1f32 !important;
  }
}
.bg-body {
  background-color: #0f1f32 !important;
}

.bg-reset {
  background-color: inherit !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-secondary {
  background-color: #6e697a !important;
}

.bg-success {
  background-color: #36d49b !important;
}

.bg-danger {
  background-color: #ee2f36 !important;
}

.bg-warning {
  background-color: #f9c000 !important;
}

.bg-brand-blue {
  background-color: #076bad !important;
}

.bg-brand-blue-light {
  background-color: #489ed7 !important;
}

.bg-brand-blue-dark {
  background-color: #2e5888 !important;
}

.bg-brand-red {
  background-color: #ee2f36 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

@media (min-width: 600px) {
  .s\:bg-body {
    background-color: #0f1f32 !important;
  }

  .s\:bg-reset {
    background-color: inherit !important;
  }

  .s\:bg-white {
    background-color: #fff !important;
  }

  .s\:bg-secondary {
    background-color: #6e697a !important;
  }

  .s\:bg-success {
    background-color: #36d49b !important;
  }

  .s\:bg-danger {
    background-color: #ee2f36 !important;
  }

  .s\:bg-warning {
    background-color: #f9c000 !important;
  }

  .s\:bg-brand-blue {
    background-color: #076bad !important;
  }

  .s\:bg-brand-blue-light {
    background-color: #489ed7 !important;
  }

  .s\:bg-brand-blue-dark {
    background-color: #2e5888 !important;
  }

  .s\:bg-brand-red {
    background-color: #ee2f36 !important;
  }

  .s\:bg-transparent {
    background-color: transparent !important;
  }
}
@media (min-width: 900px) {
  .m\:bg-body {
    background-color: #0f1f32 !important;
  }

  .m\:bg-reset {
    background-color: inherit !important;
  }

  .m\:bg-white {
    background-color: #fff !important;
  }

  .m\:bg-secondary {
    background-color: #6e697a !important;
  }

  .m\:bg-success {
    background-color: #36d49b !important;
  }

  .m\:bg-danger {
    background-color: #ee2f36 !important;
  }

  .m\:bg-warning {
    background-color: #f9c000 !important;
  }

  .m\:bg-brand-blue {
    background-color: #076bad !important;
  }

  .m\:bg-brand-blue-light {
    background-color: #489ed7 !important;
  }

  .m\:bg-brand-blue-dark {
    background-color: #2e5888 !important;
  }

  .m\:bg-brand-red {
    background-color: #ee2f36 !important;
  }

  .m\:bg-transparent {
    background-color: transparent !important;
  }
}
@media (min-width: 1200px) {
  .l\:bg-body {
    background-color: #0f1f32 !important;
  }

  .l\:bg-reset {
    background-color: inherit !important;
  }

  .l\:bg-white {
    background-color: #fff !important;
  }

  .l\:bg-secondary {
    background-color: #6e697a !important;
  }

  .l\:bg-success {
    background-color: #36d49b !important;
  }

  .l\:bg-danger {
    background-color: #ee2f36 !important;
  }

  .l\:bg-warning {
    background-color: #f9c000 !important;
  }

  .l\:bg-brand-blue {
    background-color: #076bad !important;
  }

  .l\:bg-brand-blue-light {
    background-color: #489ed7 !important;
  }

  .l\:bg-brand-blue-dark {
    background-color: #2e5888 !important;
  }

  .l\:bg-brand-red {
    background-color: #ee2f36 !important;
  }

  .l\:bg-transparent {
    background-color: transparent !important;
  }
}
@media (min-width: 1600px) {
  .xl\:bg-body {
    background-color: #0f1f32 !important;
  }

  .xl\:bg-reset {
    background-color: inherit !important;
  }

  .xl\:bg-white {
    background-color: #fff !important;
  }

  .xl\:bg-secondary {
    background-color: #6e697a !important;
  }

  .xl\:bg-success {
    background-color: #36d49b !important;
  }

  .xl\:bg-danger {
    background-color: #ee2f36 !important;
  }

  .xl\:bg-warning {
    background-color: #f9c000 !important;
  }

  .xl\:bg-brand-blue {
    background-color: #076bad !important;
  }

  .xl\:bg-brand-blue-light {
    background-color: #489ed7 !important;
  }

  .xl\:bg-brand-blue-dark {
    background-color: #2e5888 !important;
  }

  .xl\:bg-brand-red {
    background-color: #ee2f36 !important;
  }

  .xl\:bg-transparent {
    background-color: transparent !important;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-grid {
  display: grid !important;
}

@media (min-width: 600px) {
  .s\:d-none {
    display: none !important;
  }

  .s\:d-inline {
    display: inline !important;
  }

  .s\:d-inline-block {
    display: inline-block !important;
  }

  .s\:d-block {
    display: block !important;
  }

  .s\:d-inline-flex {
    display: inline-flex !important;
  }

  .s\:d-flex {
    display: flex !important;
  }

  .s\:d-inline-grid {
    display: inline-grid !important;
  }

  .s\:d-grid {
    display: grid !important;
  }
}
@media (min-width: 900px) {
  .m\:d-none {
    display: none !important;
  }

  .m\:d-inline {
    display: inline !important;
  }

  .m\:d-inline-block {
    display: inline-block !important;
  }

  .m\:d-block {
    display: block !important;
  }

  .m\:d-inline-flex {
    display: inline-flex !important;
  }

  .m\:d-flex {
    display: flex !important;
  }

  .m\:d-inline-grid {
    display: inline-grid !important;
  }

  .m\:d-grid {
    display: grid !important;
  }
}
@media (min-width: 1200px) {
  .l\:d-none {
    display: none !important;
  }

  .l\:d-inline {
    display: inline !important;
  }

  .l\:d-inline-block {
    display: inline-block !important;
  }

  .l\:d-block {
    display: block !important;
  }

  .l\:d-inline-flex {
    display: inline-flex !important;
  }

  .l\:d-flex {
    display: flex !important;
  }

  .l\:d-inline-grid {
    display: inline-grid !important;
  }

  .l\:d-grid {
    display: grid !important;
  }
}
@media (min-width: 1600px) {
  .xl\:d-none {
    display: none !important;
  }

  .xl\:d-inline {
    display: inline !important;
  }

  .xl\:d-inline-block {
    display: inline-block !important;
  }

  .xl\:d-block {
    display: block !important;
  }

  .xl\:d-inline-flex {
    display: inline-flex !important;
  }

  .xl\:d-flex {
    display: flex !important;
  }

  .xl\:d-inline-grid {
    display: inline-grid !important;
  }

  .xl\:d-grid {
    display: grid !important;
  }
}
.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.m-32 {
  margin: 8rem !important;
}

.m-40 {
  margin: 10rem !important;
}

.m-1em {
  margin: 1em !important;
}

.m-auto {
  margin: auto !important;
}

@media (min-width: 600px) {
  .s\:m-0 {
    margin: 0 !important;
  }

  .s\:m-1 {
    margin: 0.25rem !important;
  }

  .s\:m-2 {
    margin: 0.5rem !important;
  }

  .s\:m-3 {
    margin: 0.75rem !important;
  }

  .s\:m-4 {
    margin: 1rem !important;
  }

  .s\:m-5 {
    margin: 1.25rem !important;
  }

  .s\:m-6 {
    margin: 1.5rem !important;
  }

  .s\:m-8 {
    margin: 2rem !important;
  }

  .s\:m-10 {
    margin: 2.5rem !important;
  }

  .s\:m-12 {
    margin: 3rem !important;
  }

  .s\:m-14 {
    margin: 3.5rem !important;
  }

  .s\:m-16 {
    margin: 4rem !important;
  }

  .s\:m-20 {
    margin: 5rem !important;
  }

  .s\:m-24 {
    margin: 6rem !important;
  }

  .s\:m-32 {
    margin: 8rem !important;
  }

  .s\:m-40 {
    margin: 10rem !important;
  }

  .s\:m-1em {
    margin: 1em !important;
  }

  .s\:m-auto {
    margin: auto !important;
  }
}
@media (min-width: 900px) {
  .m\:m-0 {
    margin: 0 !important;
  }

  .m\:m-1 {
    margin: 0.25rem !important;
  }

  .m\:m-2 {
    margin: 0.5rem !important;
  }

  .m\:m-3 {
    margin: 0.75rem !important;
  }

  .m\:m-4 {
    margin: 1rem !important;
  }

  .m\:m-5 {
    margin: 1.25rem !important;
  }

  .m\:m-6 {
    margin: 1.5rem !important;
  }

  .m\:m-8 {
    margin: 2rem !important;
  }

  .m\:m-10 {
    margin: 2.5rem !important;
  }

  .m\:m-12 {
    margin: 3rem !important;
  }

  .m\:m-14 {
    margin: 3.5rem !important;
  }

  .m\:m-16 {
    margin: 4rem !important;
  }

  .m\:m-20 {
    margin: 5rem !important;
  }

  .m\:m-24 {
    margin: 6rem !important;
  }

  .m\:m-32 {
    margin: 8rem !important;
  }

  .m\:m-40 {
    margin: 10rem !important;
  }

  .m\:m-1em {
    margin: 1em !important;
  }

  .m\:m-auto {
    margin: auto !important;
  }
}
@media (min-width: 1200px) {
  .l\:m-0 {
    margin: 0 !important;
  }

  .l\:m-1 {
    margin: 0.25rem !important;
  }

  .l\:m-2 {
    margin: 0.5rem !important;
  }

  .l\:m-3 {
    margin: 0.75rem !important;
  }

  .l\:m-4 {
    margin: 1rem !important;
  }

  .l\:m-5 {
    margin: 1.25rem !important;
  }

  .l\:m-6 {
    margin: 1.5rem !important;
  }

  .l\:m-8 {
    margin: 2rem !important;
  }

  .l\:m-10 {
    margin: 2.5rem !important;
  }

  .l\:m-12 {
    margin: 3rem !important;
  }

  .l\:m-14 {
    margin: 3.5rem !important;
  }

  .l\:m-16 {
    margin: 4rem !important;
  }

  .l\:m-20 {
    margin: 5rem !important;
  }

  .l\:m-24 {
    margin: 6rem !important;
  }

  .l\:m-32 {
    margin: 8rem !important;
  }

  .l\:m-40 {
    margin: 10rem !important;
  }

  .l\:m-1em {
    margin: 1em !important;
  }

  .l\:m-auto {
    margin: auto !important;
  }
}
@media (min-width: 1600px) {
  .xl\:m-0 {
    margin: 0 !important;
  }

  .xl\:m-1 {
    margin: 0.25rem !important;
  }

  .xl\:m-2 {
    margin: 0.5rem !important;
  }

  .xl\:m-3 {
    margin: 0.75rem !important;
  }

  .xl\:m-4 {
    margin: 1rem !important;
  }

  .xl\:m-5 {
    margin: 1.25rem !important;
  }

  .xl\:m-6 {
    margin: 1.5rem !important;
  }

  .xl\:m-8 {
    margin: 2rem !important;
  }

  .xl\:m-10 {
    margin: 2.5rem !important;
  }

  .xl\:m-12 {
    margin: 3rem !important;
  }

  .xl\:m-14 {
    margin: 3.5rem !important;
  }

  .xl\:m-16 {
    margin: 4rem !important;
  }

  .xl\:m-20 {
    margin: 5rem !important;
  }

  .xl\:m-24 {
    margin: 6rem !important;
  }

  .xl\:m-32 {
    margin: 8rem !important;
  }

  .xl\:m-40 {
    margin: 10rem !important;
  }

  .xl\:m-1em {
    margin: 1em !important;
  }

  .xl\:m-auto {
    margin: auto !important;
  }
}
.my-0,
.mt-0 {
  margin-top: 0 !important;
}

.my-1,
.mt-1 {
  margin-top: 0.25rem !important;
}

.my-2,
.mt-2 {
  margin-top: 0.5rem !important;
}

.my-3,
.mt-3 {
  margin-top: 0.75rem !important;
}

.my-4,
.mt-4 {
  margin-top: 1rem !important;
}

.my-5,
.mt-5 {
  margin-top: 1.25rem !important;
}

.my-6,
.mt-6 {
  margin-top: 1.5rem !important;
}

.my-8,
.mt-8 {
  margin-top: 2rem !important;
}

.my-10,
.mt-10 {
  margin-top: 2.5rem !important;
}

.my-12,
.mt-12 {
  margin-top: 3rem !important;
}

.my-14,
.mt-14 {
  margin-top: 3.5rem !important;
}

.my-16,
.mt-16 {
  margin-top: 4rem !important;
}

.my-20,
.mt-20 {
  margin-top: 5rem !important;
}

.my-24,
.mt-24 {
  margin-top: 6rem !important;
}

.my-32,
.mt-32 {
  margin-top: 8rem !important;
}

.my-40,
.mt-40 {
  margin-top: 10rem !important;
}

.my-1em,
.mt-1em {
  margin-top: 1em !important;
}

.my-auto,
.mt-auto {
  margin-top: auto !important;
}

.my-container,
.mt-container {
  margin-top: calc(var(--margin-y) * var(--margin-multiplier-top, 1)) !important;
}

@media (min-width: 600px) {
  .s\:my-0,
.s\:mt-0 {
    margin-top: 0 !important;
  }

  .s\:my-1,
.s\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .s\:my-2,
.s\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .s\:my-3,
.s\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .s\:my-4,
.s\:mt-4 {
    margin-top: 1rem !important;
  }

  .s\:my-5,
.s\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .s\:my-6,
.s\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .s\:my-8,
.s\:mt-8 {
    margin-top: 2rem !important;
  }

  .s\:my-10,
.s\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .s\:my-12,
.s\:mt-12 {
    margin-top: 3rem !important;
  }

  .s\:my-14,
.s\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .s\:my-16,
.s\:mt-16 {
    margin-top: 4rem !important;
  }

  .s\:my-20,
.s\:mt-20 {
    margin-top: 5rem !important;
  }

  .s\:my-24,
.s\:mt-24 {
    margin-top: 6rem !important;
  }

  .s\:my-32,
.s\:mt-32 {
    margin-top: 8rem !important;
  }

  .s\:my-40,
.s\:mt-40 {
    margin-top: 10rem !important;
  }

  .s\:my-1em,
.s\:mt-1em {
    margin-top: 1em !important;
  }

  .s\:my-auto,
.s\:mt-auto {
    margin-top: auto !important;
  }

  .s\:my-container,
.s\:mt-container {
    margin-top: calc(var(--margin-y) * var(--margin-multiplier-top, 1)) !important;
  }
}
@media (min-width: 900px) {
  .m\:my-0,
.m\:mt-0 {
    margin-top: 0 !important;
  }

  .m\:my-1,
.m\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .m\:my-2,
.m\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .m\:my-3,
.m\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .m\:my-4,
.m\:mt-4 {
    margin-top: 1rem !important;
  }

  .m\:my-5,
.m\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .m\:my-6,
.m\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .m\:my-8,
.m\:mt-8 {
    margin-top: 2rem !important;
  }

  .m\:my-10,
.m\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .m\:my-12,
.m\:mt-12 {
    margin-top: 3rem !important;
  }

  .m\:my-14,
.m\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .m\:my-16,
.m\:mt-16 {
    margin-top: 4rem !important;
  }

  .m\:my-20,
.m\:mt-20 {
    margin-top: 5rem !important;
  }

  .m\:my-24,
.m\:mt-24 {
    margin-top: 6rem !important;
  }

  .m\:my-32,
.m\:mt-32 {
    margin-top: 8rem !important;
  }

  .m\:my-40,
.m\:mt-40 {
    margin-top: 10rem !important;
  }

  .m\:my-1em,
.m\:mt-1em {
    margin-top: 1em !important;
  }

  .m\:my-auto,
.m\:mt-auto {
    margin-top: auto !important;
  }

  .m\:my-container,
.m\:mt-container {
    margin-top: calc(var(--margin-y) * var(--margin-multiplier-top, 1)) !important;
  }
}
@media (min-width: 1200px) {
  .l\:my-0,
.l\:mt-0 {
    margin-top: 0 !important;
  }

  .l\:my-1,
.l\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .l\:my-2,
.l\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .l\:my-3,
.l\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .l\:my-4,
.l\:mt-4 {
    margin-top: 1rem !important;
  }

  .l\:my-5,
.l\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .l\:my-6,
.l\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .l\:my-8,
.l\:mt-8 {
    margin-top: 2rem !important;
  }

  .l\:my-10,
.l\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .l\:my-12,
.l\:mt-12 {
    margin-top: 3rem !important;
  }

  .l\:my-14,
.l\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .l\:my-16,
.l\:mt-16 {
    margin-top: 4rem !important;
  }

  .l\:my-20,
.l\:mt-20 {
    margin-top: 5rem !important;
  }

  .l\:my-24,
.l\:mt-24 {
    margin-top: 6rem !important;
  }

  .l\:my-32,
.l\:mt-32 {
    margin-top: 8rem !important;
  }

  .l\:my-40,
.l\:mt-40 {
    margin-top: 10rem !important;
  }

  .l\:my-1em,
.l\:mt-1em {
    margin-top: 1em !important;
  }

  .l\:my-auto,
.l\:mt-auto {
    margin-top: auto !important;
  }

  .l\:my-container,
.l\:mt-container {
    margin-top: calc(var(--margin-y) * var(--margin-multiplier-top, 1)) !important;
  }
}
@media (min-width: 1600px) {
  .xl\:my-0,
.xl\:mt-0 {
    margin-top: 0 !important;
  }

  .xl\:my-1,
.xl\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .xl\:my-2,
.xl\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .xl\:my-3,
.xl\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .xl\:my-4,
.xl\:mt-4 {
    margin-top: 1rem !important;
  }

  .xl\:my-5,
.xl\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .xl\:my-6,
.xl\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .xl\:my-8,
.xl\:mt-8 {
    margin-top: 2rem !important;
  }

  .xl\:my-10,
.xl\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .xl\:my-12,
.xl\:mt-12 {
    margin-top: 3rem !important;
  }

  .xl\:my-14,
.xl\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .xl\:my-16,
.xl\:mt-16 {
    margin-top: 4rem !important;
  }

  .xl\:my-20,
.xl\:mt-20 {
    margin-top: 5rem !important;
  }

  .xl\:my-24,
.xl\:mt-24 {
    margin-top: 6rem !important;
  }

  .xl\:my-32,
.xl\:mt-32 {
    margin-top: 8rem !important;
  }

  .xl\:my-40,
.xl\:mt-40 {
    margin-top: 10rem !important;
  }

  .xl\:my-1em,
.xl\:mt-1em {
    margin-top: 1em !important;
  }

  .xl\:my-auto,
.xl\:mt-auto {
    margin-top: auto !important;
  }

  .xl\:my-container,
.xl\:mt-container {
    margin-top: calc(var(--margin-y) * var(--margin-multiplier-top, 1)) !important;
  }
}
.my-0,
.mb-0 {
  margin-bottom: 0 !important;
}

.my-1,
.mb-1 {
  margin-bottom: 0.25rem !important;
}

.my-2,
.mb-2 {
  margin-bottom: 0.5rem !important;
}

.my-3,
.mb-3 {
  margin-bottom: 0.75rem !important;
}

.my-4,
.mb-4 {
  margin-bottom: 1rem !important;
}

.my-5,
.mb-5 {
  margin-bottom: 1.25rem !important;
}

.my-6,
.mb-6 {
  margin-bottom: 1.5rem !important;
}

.my-8,
.mb-8 {
  margin-bottom: 2rem !important;
}

.my-10,
.mb-10 {
  margin-bottom: 2.5rem !important;
}

.my-12,
.mb-12 {
  margin-bottom: 3rem !important;
}

.my-14,
.mb-14 {
  margin-bottom: 3.5rem !important;
}

.my-16,
.mb-16 {
  margin-bottom: 4rem !important;
}

.my-20,
.mb-20 {
  margin-bottom: 5rem !important;
}

.my-24,
.mb-24 {
  margin-bottom: 6rem !important;
}

.my-32,
.mb-32 {
  margin-bottom: 8rem !important;
}

.my-40,
.mb-40 {
  margin-bottom: 10rem !important;
}

.my-1em,
.mb-1em {
  margin-bottom: 1em !important;
}

.my-auto,
.mb-auto {
  margin-bottom: auto !important;
}

.my-container,
.mb-container {
  margin-bottom: calc(var(--margin-y) * var(--margin-multiplier-bottom, 1)) !important;
}

@media (min-width: 600px) {
  .s\:my-0,
.s\:mb-0 {
    margin-bottom: 0 !important;
  }

  .s\:my-1,
.s\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .s\:my-2,
.s\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .s\:my-3,
.s\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .s\:my-4,
.s\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .s\:my-5,
.s\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .s\:my-6,
.s\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .s\:my-8,
.s\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .s\:my-10,
.s\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .s\:my-12,
.s\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .s\:my-14,
.s\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .s\:my-16,
.s\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .s\:my-20,
.s\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .s\:my-24,
.s\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .s\:my-32,
.s\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .s\:my-40,
.s\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .s\:my-1em,
.s\:mb-1em {
    margin-bottom: 1em !important;
  }

  .s\:my-auto,
.s\:mb-auto {
    margin-bottom: auto !important;
  }

  .s\:my-container,
.s\:mb-container {
    margin-bottom: calc(var(--margin-y) * var(--margin-multiplier-bottom, 1)) !important;
  }
}
@media (min-width: 900px) {
  .m\:my-0,
.m\:mb-0 {
    margin-bottom: 0 !important;
  }

  .m\:my-1,
.m\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .m\:my-2,
.m\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .m\:my-3,
.m\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .m\:my-4,
.m\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .m\:my-5,
.m\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .m\:my-6,
.m\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .m\:my-8,
.m\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .m\:my-10,
.m\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .m\:my-12,
.m\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .m\:my-14,
.m\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .m\:my-16,
.m\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .m\:my-20,
.m\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .m\:my-24,
.m\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .m\:my-32,
.m\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .m\:my-40,
.m\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .m\:my-1em,
.m\:mb-1em {
    margin-bottom: 1em !important;
  }

  .m\:my-auto,
.m\:mb-auto {
    margin-bottom: auto !important;
  }

  .m\:my-container,
.m\:mb-container {
    margin-bottom: calc(var(--margin-y) * var(--margin-multiplier-bottom, 1)) !important;
  }
}
@media (min-width: 1200px) {
  .l\:my-0,
.l\:mb-0 {
    margin-bottom: 0 !important;
  }

  .l\:my-1,
.l\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .l\:my-2,
.l\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .l\:my-3,
.l\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .l\:my-4,
.l\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .l\:my-5,
.l\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .l\:my-6,
.l\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .l\:my-8,
.l\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .l\:my-10,
.l\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .l\:my-12,
.l\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .l\:my-14,
.l\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .l\:my-16,
.l\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .l\:my-20,
.l\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .l\:my-24,
.l\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .l\:my-32,
.l\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .l\:my-40,
.l\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .l\:my-1em,
.l\:mb-1em {
    margin-bottom: 1em !important;
  }

  .l\:my-auto,
.l\:mb-auto {
    margin-bottom: auto !important;
  }

  .l\:my-container,
.l\:mb-container {
    margin-bottom: calc(var(--margin-y) * var(--margin-multiplier-bottom, 1)) !important;
  }
}
@media (min-width: 1600px) {
  .xl\:my-0,
.xl\:mb-0 {
    margin-bottom: 0 !important;
  }

  .xl\:my-1,
.xl\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .xl\:my-2,
.xl\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .xl\:my-3,
.xl\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .xl\:my-4,
.xl\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .xl\:my-5,
.xl\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .xl\:my-6,
.xl\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .xl\:my-8,
.xl\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .xl\:my-10,
.xl\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .xl\:my-12,
.xl\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .xl\:my-14,
.xl\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .xl\:my-16,
.xl\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .xl\:my-20,
.xl\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .xl\:my-24,
.xl\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .xl\:my-32,
.xl\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .xl\:my-40,
.xl\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .xl\:my-1em,
.xl\:mb-1em {
    margin-bottom: 1em !important;
  }

  .xl\:my-auto,
.xl\:mb-auto {
    margin-bottom: auto !important;
  }

  .xl\:my-container,
.xl\:mb-container {
    margin-bottom: calc(var(--margin-y) * var(--margin-multiplier-bottom, 1)) !important;
  }
}
.mx-0,
.ml-0 {
  margin-left: 0 !important;
}

.mx-1,
.ml-1 {
  margin-left: 0.25rem !important;
}

.mx-2,
.ml-2 {
  margin-left: 0.5rem !important;
}

.mx-3,
.ml-3 {
  margin-left: 0.75rem !important;
}

.mx-4,
.ml-4 {
  margin-left: 1rem !important;
}

.mx-5,
.ml-5 {
  margin-left: 1.25rem !important;
}

.mx-6,
.ml-6 {
  margin-left: 1.5rem !important;
}

.mx-8,
.ml-8 {
  margin-left: 2rem !important;
}

.mx-10,
.ml-10 {
  margin-left: 2.5rem !important;
}

.mx-12,
.ml-12 {
  margin-left: 3rem !important;
}

.mx-14,
.ml-14 {
  margin-left: 3.5rem !important;
}

.mx-16,
.ml-16 {
  margin-left: 4rem !important;
}

.mx-20,
.ml-20 {
  margin-left: 5rem !important;
}

.mx-24,
.ml-24 {
  margin-left: 6rem !important;
}

.mx-32,
.ml-32 {
  margin-left: 8rem !important;
}

.mx-40,
.ml-40 {
  margin-left: 10rem !important;
}

.mx-1em,
.ml-1em {
  margin-left: 1em !important;
}

.mx-auto,
.ml-auto {
  margin-left: auto !important;
}

@media (min-width: 600px) {
  .s\:mx-0,
.s\:ml-0 {
    margin-left: 0 !important;
  }

  .s\:mx-1,
.s\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .s\:mx-2,
.s\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .s\:mx-3,
.s\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .s\:mx-4,
.s\:ml-4 {
    margin-left: 1rem !important;
  }

  .s\:mx-5,
.s\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .s\:mx-6,
.s\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .s\:mx-8,
.s\:ml-8 {
    margin-left: 2rem !important;
  }

  .s\:mx-10,
.s\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .s\:mx-12,
.s\:ml-12 {
    margin-left: 3rem !important;
  }

  .s\:mx-14,
.s\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .s\:mx-16,
.s\:ml-16 {
    margin-left: 4rem !important;
  }

  .s\:mx-20,
.s\:ml-20 {
    margin-left: 5rem !important;
  }

  .s\:mx-24,
.s\:ml-24 {
    margin-left: 6rem !important;
  }

  .s\:mx-32,
.s\:ml-32 {
    margin-left: 8rem !important;
  }

  .s\:mx-40,
.s\:ml-40 {
    margin-left: 10rem !important;
  }

  .s\:mx-1em,
.s\:ml-1em {
    margin-left: 1em !important;
  }

  .s\:mx-auto,
.s\:ml-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 900px) {
  .m\:mx-0,
.m\:ml-0 {
    margin-left: 0 !important;
  }

  .m\:mx-1,
.m\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .m\:mx-2,
.m\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .m\:mx-3,
.m\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .m\:mx-4,
.m\:ml-4 {
    margin-left: 1rem !important;
  }

  .m\:mx-5,
.m\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .m\:mx-6,
.m\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .m\:mx-8,
.m\:ml-8 {
    margin-left: 2rem !important;
  }

  .m\:mx-10,
.m\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .m\:mx-12,
.m\:ml-12 {
    margin-left: 3rem !important;
  }

  .m\:mx-14,
.m\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .m\:mx-16,
.m\:ml-16 {
    margin-left: 4rem !important;
  }

  .m\:mx-20,
.m\:ml-20 {
    margin-left: 5rem !important;
  }

  .m\:mx-24,
.m\:ml-24 {
    margin-left: 6rem !important;
  }

  .m\:mx-32,
.m\:ml-32 {
    margin-left: 8rem !important;
  }

  .m\:mx-40,
.m\:ml-40 {
    margin-left: 10rem !important;
  }

  .m\:mx-1em,
.m\:ml-1em {
    margin-left: 1em !important;
  }

  .m\:mx-auto,
.m\:ml-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .l\:mx-0,
.l\:ml-0 {
    margin-left: 0 !important;
  }

  .l\:mx-1,
.l\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .l\:mx-2,
.l\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .l\:mx-3,
.l\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .l\:mx-4,
.l\:ml-4 {
    margin-left: 1rem !important;
  }

  .l\:mx-5,
.l\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .l\:mx-6,
.l\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .l\:mx-8,
.l\:ml-8 {
    margin-left: 2rem !important;
  }

  .l\:mx-10,
.l\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .l\:mx-12,
.l\:ml-12 {
    margin-left: 3rem !important;
  }

  .l\:mx-14,
.l\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .l\:mx-16,
.l\:ml-16 {
    margin-left: 4rem !important;
  }

  .l\:mx-20,
.l\:ml-20 {
    margin-left: 5rem !important;
  }

  .l\:mx-24,
.l\:ml-24 {
    margin-left: 6rem !important;
  }

  .l\:mx-32,
.l\:ml-32 {
    margin-left: 8rem !important;
  }

  .l\:mx-40,
.l\:ml-40 {
    margin-left: 10rem !important;
  }

  .l\:mx-1em,
.l\:ml-1em {
    margin-left: 1em !important;
  }

  .l\:mx-auto,
.l\:ml-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1600px) {
  .xl\:mx-0,
.xl\:ml-0 {
    margin-left: 0 !important;
  }

  .xl\:mx-1,
.xl\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .xl\:mx-2,
.xl\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .xl\:mx-3,
.xl\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .xl\:mx-4,
.xl\:ml-4 {
    margin-left: 1rem !important;
  }

  .xl\:mx-5,
.xl\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .xl\:mx-6,
.xl\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .xl\:mx-8,
.xl\:ml-8 {
    margin-left: 2rem !important;
  }

  .xl\:mx-10,
.xl\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .xl\:mx-12,
.xl\:ml-12 {
    margin-left: 3rem !important;
  }

  .xl\:mx-14,
.xl\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .xl\:mx-16,
.xl\:ml-16 {
    margin-left: 4rem !important;
  }

  .xl\:mx-20,
.xl\:ml-20 {
    margin-left: 5rem !important;
  }

  .xl\:mx-24,
.xl\:ml-24 {
    margin-left: 6rem !important;
  }

  .xl\:mx-32,
.xl\:ml-32 {
    margin-left: 8rem !important;
  }

  .xl\:mx-40,
.xl\:ml-40 {
    margin-left: 10rem !important;
  }

  .xl\:mx-1em,
.xl\:ml-1em {
    margin-left: 1em !important;
  }

  .xl\:mx-auto,
.xl\:ml-auto {
    margin-left: auto !important;
  }
}
.mx-0,
.mr-0 {
  margin-right: 0 !important;
}

.mx-1,
.mr-1 {
  margin-right: 0.25rem !important;
}

.mx-2,
.mr-2 {
  margin-right: 0.5rem !important;
}

.mx-3,
.mr-3 {
  margin-right: 0.75rem !important;
}

.mx-4,
.mr-4 {
  margin-right: 1rem !important;
}

.mx-5,
.mr-5 {
  margin-right: 1.25rem !important;
}

.mx-6,
.mr-6 {
  margin-right: 1.5rem !important;
}

.mx-8,
.mr-8 {
  margin-right: 2rem !important;
}

.mx-10,
.mr-10 {
  margin-right: 2.5rem !important;
}

.mx-12,
.mr-12 {
  margin-right: 3rem !important;
}

.mx-14,
.mr-14 {
  margin-right: 3.5rem !important;
}

.mx-16,
.mr-16 {
  margin-right: 4rem !important;
}

.mx-20,
.mr-20 {
  margin-right: 5rem !important;
}

.mx-24,
.mr-24 {
  margin-right: 6rem !important;
}

.mx-32,
.mr-32 {
  margin-right: 8rem !important;
}

.mx-40,
.mr-40 {
  margin-right: 10rem !important;
}

.mx-1em,
.mr-1em {
  margin-right: 1em !important;
}

.mx-auto,
.mr-auto {
  margin-right: auto !important;
}

@media (min-width: 600px) {
  .s\:mx-0,
.s\:mr-0 {
    margin-right: 0 !important;
  }

  .s\:mx-1,
.s\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .s\:mx-2,
.s\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .s\:mx-3,
.s\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .s\:mx-4,
.s\:mr-4 {
    margin-right: 1rem !important;
  }

  .s\:mx-5,
.s\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .s\:mx-6,
.s\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .s\:mx-8,
.s\:mr-8 {
    margin-right: 2rem !important;
  }

  .s\:mx-10,
.s\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .s\:mx-12,
.s\:mr-12 {
    margin-right: 3rem !important;
  }

  .s\:mx-14,
.s\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .s\:mx-16,
.s\:mr-16 {
    margin-right: 4rem !important;
  }

  .s\:mx-20,
.s\:mr-20 {
    margin-right: 5rem !important;
  }

  .s\:mx-24,
.s\:mr-24 {
    margin-right: 6rem !important;
  }

  .s\:mx-32,
.s\:mr-32 {
    margin-right: 8rem !important;
  }

  .s\:mx-40,
.s\:mr-40 {
    margin-right: 10rem !important;
  }

  .s\:mx-1em,
.s\:mr-1em {
    margin-right: 1em !important;
  }

  .s\:mx-auto,
.s\:mr-auto {
    margin-right: auto !important;
  }
}
@media (min-width: 900px) {
  .m\:mx-0,
.m\:mr-0 {
    margin-right: 0 !important;
  }

  .m\:mx-1,
.m\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .m\:mx-2,
.m\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .m\:mx-3,
.m\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .m\:mx-4,
.m\:mr-4 {
    margin-right: 1rem !important;
  }

  .m\:mx-5,
.m\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .m\:mx-6,
.m\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .m\:mx-8,
.m\:mr-8 {
    margin-right: 2rem !important;
  }

  .m\:mx-10,
.m\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .m\:mx-12,
.m\:mr-12 {
    margin-right: 3rem !important;
  }

  .m\:mx-14,
.m\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .m\:mx-16,
.m\:mr-16 {
    margin-right: 4rem !important;
  }

  .m\:mx-20,
.m\:mr-20 {
    margin-right: 5rem !important;
  }

  .m\:mx-24,
.m\:mr-24 {
    margin-right: 6rem !important;
  }

  .m\:mx-32,
.m\:mr-32 {
    margin-right: 8rem !important;
  }

  .m\:mx-40,
.m\:mr-40 {
    margin-right: 10rem !important;
  }

  .m\:mx-1em,
.m\:mr-1em {
    margin-right: 1em !important;
  }

  .m\:mx-auto,
.m\:mr-auto {
    margin-right: auto !important;
  }
}
@media (min-width: 1200px) {
  .l\:mx-0,
.l\:mr-0 {
    margin-right: 0 !important;
  }

  .l\:mx-1,
.l\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .l\:mx-2,
.l\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .l\:mx-3,
.l\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .l\:mx-4,
.l\:mr-4 {
    margin-right: 1rem !important;
  }

  .l\:mx-5,
.l\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .l\:mx-6,
.l\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .l\:mx-8,
.l\:mr-8 {
    margin-right: 2rem !important;
  }

  .l\:mx-10,
.l\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .l\:mx-12,
.l\:mr-12 {
    margin-right: 3rem !important;
  }

  .l\:mx-14,
.l\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .l\:mx-16,
.l\:mr-16 {
    margin-right: 4rem !important;
  }

  .l\:mx-20,
.l\:mr-20 {
    margin-right: 5rem !important;
  }

  .l\:mx-24,
.l\:mr-24 {
    margin-right: 6rem !important;
  }

  .l\:mx-32,
.l\:mr-32 {
    margin-right: 8rem !important;
  }

  .l\:mx-40,
.l\:mr-40 {
    margin-right: 10rem !important;
  }

  .l\:mx-1em,
.l\:mr-1em {
    margin-right: 1em !important;
  }

  .l\:mx-auto,
.l\:mr-auto {
    margin-right: auto !important;
  }
}
@media (min-width: 1600px) {
  .xl\:mx-0,
.xl\:mr-0 {
    margin-right: 0 !important;
  }

  .xl\:mx-1,
.xl\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .xl\:mx-2,
.xl\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .xl\:mx-3,
.xl\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .xl\:mx-4,
.xl\:mr-4 {
    margin-right: 1rem !important;
  }

  .xl\:mx-5,
.xl\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .xl\:mx-6,
.xl\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .xl\:mx-8,
.xl\:mr-8 {
    margin-right: 2rem !important;
  }

  .xl\:mx-10,
.xl\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .xl\:mx-12,
.xl\:mr-12 {
    margin-right: 3rem !important;
  }

  .xl\:mx-14,
.xl\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .xl\:mx-16,
.xl\:mr-16 {
    margin-right: 4rem !important;
  }

  .xl\:mx-20,
.xl\:mr-20 {
    margin-right: 5rem !important;
  }

  .xl\:mx-24,
.xl\:mr-24 {
    margin-right: 6rem !important;
  }

  .xl\:mx-32,
.xl\:mr-32 {
    margin-right: 8rem !important;
  }

  .xl\:mx-40,
.xl\:mr-40 {
    margin-right: 10rem !important;
  }

  .xl\:mx-1em,
.xl\:mr-1em {
    margin-right: 1em !important;
  }

  .xl\:mx-auto,
.xl\:mr-auto {
    margin-right: auto !important;
  }
}
.mx-n0,
.ml-n0 {
  margin-left: -0 !important;
}

.mx-n1,
.ml-n1 {
  margin-left: -0.25rem !important;
}

.mx-n2,
.ml-n2 {
  margin-left: -0.5rem !important;
}

.mx-n3,
.ml-n3 {
  margin-left: -0.75rem !important;
}

.mx-n4,
.ml-n4 {
  margin-left: -1rem !important;
}

.mx-n5,
.ml-n5 {
  margin-left: -1.25rem !important;
}

.mx-n6,
.ml-n6 {
  margin-left: -1.5rem !important;
}

.mx-n8,
.ml-n8 {
  margin-left: -2rem !important;
}

.mx-n10,
.ml-n10 {
  margin-left: -2.5rem !important;
}

.mx-n12,
.ml-n12 {
  margin-left: -3rem !important;
}

.mx-n14,
.ml-n14 {
  margin-left: -3.5rem !important;
}

.mx-n16,
.ml-n16 {
  margin-left: -4rem !important;
}

.mx-n20,
.ml-n20 {
  margin-left: -5rem !important;
}

.mx-n24,
.ml-n24 {
  margin-left: -6rem !important;
}

.mx-n32,
.ml-n32 {
  margin-left: -8rem !important;
}

.mx-n40,
.ml-n40 {
  margin-left: -10rem !important;
}

.mx-n1em,
.ml-n1em {
  margin-left: -1em !important;
}

@media (min-width: 600px) {
  .s\:mx-n0,
.s\:ml-n0 {
    margin-left: -0 !important;
  }

  .s\:mx-n1,
.s\:ml-n1 {
    margin-left: -0.25rem !important;
  }

  .s\:mx-n2,
.s\:ml-n2 {
    margin-left: -0.5rem !important;
  }

  .s\:mx-n3,
.s\:ml-n3 {
    margin-left: -0.75rem !important;
  }

  .s\:mx-n4,
.s\:ml-n4 {
    margin-left: -1rem !important;
  }

  .s\:mx-n5,
.s\:ml-n5 {
    margin-left: -1.25rem !important;
  }

  .s\:mx-n6,
.s\:ml-n6 {
    margin-left: -1.5rem !important;
  }

  .s\:mx-n8,
.s\:ml-n8 {
    margin-left: -2rem !important;
  }

  .s\:mx-n10,
.s\:ml-n10 {
    margin-left: -2.5rem !important;
  }

  .s\:mx-n12,
.s\:ml-n12 {
    margin-left: -3rem !important;
  }

  .s\:mx-n14,
.s\:ml-n14 {
    margin-left: -3.5rem !important;
  }

  .s\:mx-n16,
.s\:ml-n16 {
    margin-left: -4rem !important;
  }

  .s\:mx-n20,
.s\:ml-n20 {
    margin-left: -5rem !important;
  }

  .s\:mx-n24,
.s\:ml-n24 {
    margin-left: -6rem !important;
  }

  .s\:mx-n32,
.s\:ml-n32 {
    margin-left: -8rem !important;
  }

  .s\:mx-n40,
.s\:ml-n40 {
    margin-left: -10rem !important;
  }

  .s\:mx-n1em,
.s\:ml-n1em {
    margin-left: -1em !important;
  }
}
@media (min-width: 900px) {
  .m\:mx-n0,
.m\:ml-n0 {
    margin-left: -0 !important;
  }

  .m\:mx-n1,
.m\:ml-n1 {
    margin-left: -0.25rem !important;
  }

  .m\:mx-n2,
.m\:ml-n2 {
    margin-left: -0.5rem !important;
  }

  .m\:mx-n3,
.m\:ml-n3 {
    margin-left: -0.75rem !important;
  }

  .m\:mx-n4,
.m\:ml-n4 {
    margin-left: -1rem !important;
  }

  .m\:mx-n5,
.m\:ml-n5 {
    margin-left: -1.25rem !important;
  }

  .m\:mx-n6,
.m\:ml-n6 {
    margin-left: -1.5rem !important;
  }

  .m\:mx-n8,
.m\:ml-n8 {
    margin-left: -2rem !important;
  }

  .m\:mx-n10,
.m\:ml-n10 {
    margin-left: -2.5rem !important;
  }

  .m\:mx-n12,
.m\:ml-n12 {
    margin-left: -3rem !important;
  }

  .m\:mx-n14,
.m\:ml-n14 {
    margin-left: -3.5rem !important;
  }

  .m\:mx-n16,
.m\:ml-n16 {
    margin-left: -4rem !important;
  }

  .m\:mx-n20,
.m\:ml-n20 {
    margin-left: -5rem !important;
  }

  .m\:mx-n24,
.m\:ml-n24 {
    margin-left: -6rem !important;
  }

  .m\:mx-n32,
.m\:ml-n32 {
    margin-left: -8rem !important;
  }

  .m\:mx-n40,
.m\:ml-n40 {
    margin-left: -10rem !important;
  }

  .m\:mx-n1em,
.m\:ml-n1em {
    margin-left: -1em !important;
  }
}
@media (min-width: 1200px) {
  .l\:mx-n0,
.l\:ml-n0 {
    margin-left: -0 !important;
  }

  .l\:mx-n1,
.l\:ml-n1 {
    margin-left: -0.25rem !important;
  }

  .l\:mx-n2,
.l\:ml-n2 {
    margin-left: -0.5rem !important;
  }

  .l\:mx-n3,
.l\:ml-n3 {
    margin-left: -0.75rem !important;
  }

  .l\:mx-n4,
.l\:ml-n4 {
    margin-left: -1rem !important;
  }

  .l\:mx-n5,
.l\:ml-n5 {
    margin-left: -1.25rem !important;
  }

  .l\:mx-n6,
.l\:ml-n6 {
    margin-left: -1.5rem !important;
  }

  .l\:mx-n8,
.l\:ml-n8 {
    margin-left: -2rem !important;
  }

  .l\:mx-n10,
.l\:ml-n10 {
    margin-left: -2.5rem !important;
  }

  .l\:mx-n12,
.l\:ml-n12 {
    margin-left: -3rem !important;
  }

  .l\:mx-n14,
.l\:ml-n14 {
    margin-left: -3.5rem !important;
  }

  .l\:mx-n16,
.l\:ml-n16 {
    margin-left: -4rem !important;
  }

  .l\:mx-n20,
.l\:ml-n20 {
    margin-left: -5rem !important;
  }

  .l\:mx-n24,
.l\:ml-n24 {
    margin-left: -6rem !important;
  }

  .l\:mx-n32,
.l\:ml-n32 {
    margin-left: -8rem !important;
  }

  .l\:mx-n40,
.l\:ml-n40 {
    margin-left: -10rem !important;
  }

  .l\:mx-n1em,
.l\:ml-n1em {
    margin-left: -1em !important;
  }
}
@media (min-width: 1600px) {
  .xl\:mx-n0,
.xl\:ml-n0 {
    margin-left: -0 !important;
  }

  .xl\:mx-n1,
.xl\:ml-n1 {
    margin-left: -0.25rem !important;
  }

  .xl\:mx-n2,
.xl\:ml-n2 {
    margin-left: -0.5rem !important;
  }

  .xl\:mx-n3,
.xl\:ml-n3 {
    margin-left: -0.75rem !important;
  }

  .xl\:mx-n4,
.xl\:ml-n4 {
    margin-left: -1rem !important;
  }

  .xl\:mx-n5,
.xl\:ml-n5 {
    margin-left: -1.25rem !important;
  }

  .xl\:mx-n6,
.xl\:ml-n6 {
    margin-left: -1.5rem !important;
  }

  .xl\:mx-n8,
.xl\:ml-n8 {
    margin-left: -2rem !important;
  }

  .xl\:mx-n10,
.xl\:ml-n10 {
    margin-left: -2.5rem !important;
  }

  .xl\:mx-n12,
.xl\:ml-n12 {
    margin-left: -3rem !important;
  }

  .xl\:mx-n14,
.xl\:ml-n14 {
    margin-left: -3.5rem !important;
  }

  .xl\:mx-n16,
.xl\:ml-n16 {
    margin-left: -4rem !important;
  }

  .xl\:mx-n20,
.xl\:ml-n20 {
    margin-left: -5rem !important;
  }

  .xl\:mx-n24,
.xl\:ml-n24 {
    margin-left: -6rem !important;
  }

  .xl\:mx-n32,
.xl\:ml-n32 {
    margin-left: -8rem !important;
  }

  .xl\:mx-n40,
.xl\:ml-n40 {
    margin-left: -10rem !important;
  }

  .xl\:mx-n1em,
.xl\:ml-n1em {
    margin-left: -1em !important;
  }
}
.mx-n0,
.mr-n0 {
  margin-right: -0 !important;
}

.mx-n1,
.mr-n1 {
  margin-right: -0.25rem !important;
}

.mx-n2,
.mr-n2 {
  margin-right: -0.5rem !important;
}

.mx-n3,
.mr-n3 {
  margin-right: -0.75rem !important;
}

.mx-n4,
.mr-n4 {
  margin-right: -1rem !important;
}

.mx-n5,
.mr-n5 {
  margin-right: -1.25rem !important;
}

.mx-n6,
.mr-n6 {
  margin-right: -1.5rem !important;
}

.mx-n8,
.mr-n8 {
  margin-right: -2rem !important;
}

.mx-n10,
.mr-n10 {
  margin-right: -2.5rem !important;
}

.mx-n12,
.mr-n12 {
  margin-right: -3rem !important;
}

.mx-n14,
.mr-n14 {
  margin-right: -3.5rem !important;
}

.mx-n16,
.mr-n16 {
  margin-right: -4rem !important;
}

.mx-n20,
.mr-n20 {
  margin-right: -5rem !important;
}

.mx-n24,
.mr-n24 {
  margin-right: -6rem !important;
}

.mx-n32,
.mr-n32 {
  margin-right: -8rem !important;
}

.mx-n40,
.mr-n40 {
  margin-right: -10rem !important;
}

.mx-n1em,
.mr-n1em {
  margin-right: -1em !important;
}

@media (min-width: 600px) {
  .s\:mx-n0,
.s\:mr-n0 {
    margin-right: -0 !important;
  }

  .s\:mx-n1,
.s\:mr-n1 {
    margin-right: -0.25rem !important;
  }

  .s\:mx-n2,
.s\:mr-n2 {
    margin-right: -0.5rem !important;
  }

  .s\:mx-n3,
.s\:mr-n3 {
    margin-right: -0.75rem !important;
  }

  .s\:mx-n4,
.s\:mr-n4 {
    margin-right: -1rem !important;
  }

  .s\:mx-n5,
.s\:mr-n5 {
    margin-right: -1.25rem !important;
  }

  .s\:mx-n6,
.s\:mr-n6 {
    margin-right: -1.5rem !important;
  }

  .s\:mx-n8,
.s\:mr-n8 {
    margin-right: -2rem !important;
  }

  .s\:mx-n10,
.s\:mr-n10 {
    margin-right: -2.5rem !important;
  }

  .s\:mx-n12,
.s\:mr-n12 {
    margin-right: -3rem !important;
  }

  .s\:mx-n14,
.s\:mr-n14 {
    margin-right: -3.5rem !important;
  }

  .s\:mx-n16,
.s\:mr-n16 {
    margin-right: -4rem !important;
  }

  .s\:mx-n20,
.s\:mr-n20 {
    margin-right: -5rem !important;
  }

  .s\:mx-n24,
.s\:mr-n24 {
    margin-right: -6rem !important;
  }

  .s\:mx-n32,
.s\:mr-n32 {
    margin-right: -8rem !important;
  }

  .s\:mx-n40,
.s\:mr-n40 {
    margin-right: -10rem !important;
  }

  .s\:mx-n1em,
.s\:mr-n1em {
    margin-right: -1em !important;
  }
}
@media (min-width: 900px) {
  .m\:mx-n0,
.m\:mr-n0 {
    margin-right: -0 !important;
  }

  .m\:mx-n1,
.m\:mr-n1 {
    margin-right: -0.25rem !important;
  }

  .m\:mx-n2,
.m\:mr-n2 {
    margin-right: -0.5rem !important;
  }

  .m\:mx-n3,
.m\:mr-n3 {
    margin-right: -0.75rem !important;
  }

  .m\:mx-n4,
.m\:mr-n4 {
    margin-right: -1rem !important;
  }

  .m\:mx-n5,
.m\:mr-n5 {
    margin-right: -1.25rem !important;
  }

  .m\:mx-n6,
.m\:mr-n6 {
    margin-right: -1.5rem !important;
  }

  .m\:mx-n8,
.m\:mr-n8 {
    margin-right: -2rem !important;
  }

  .m\:mx-n10,
.m\:mr-n10 {
    margin-right: -2.5rem !important;
  }

  .m\:mx-n12,
.m\:mr-n12 {
    margin-right: -3rem !important;
  }

  .m\:mx-n14,
.m\:mr-n14 {
    margin-right: -3.5rem !important;
  }

  .m\:mx-n16,
.m\:mr-n16 {
    margin-right: -4rem !important;
  }

  .m\:mx-n20,
.m\:mr-n20 {
    margin-right: -5rem !important;
  }

  .m\:mx-n24,
.m\:mr-n24 {
    margin-right: -6rem !important;
  }

  .m\:mx-n32,
.m\:mr-n32 {
    margin-right: -8rem !important;
  }

  .m\:mx-n40,
.m\:mr-n40 {
    margin-right: -10rem !important;
  }

  .m\:mx-n1em,
.m\:mr-n1em {
    margin-right: -1em !important;
  }
}
@media (min-width: 1200px) {
  .l\:mx-n0,
.l\:mr-n0 {
    margin-right: -0 !important;
  }

  .l\:mx-n1,
.l\:mr-n1 {
    margin-right: -0.25rem !important;
  }

  .l\:mx-n2,
.l\:mr-n2 {
    margin-right: -0.5rem !important;
  }

  .l\:mx-n3,
.l\:mr-n3 {
    margin-right: -0.75rem !important;
  }

  .l\:mx-n4,
.l\:mr-n4 {
    margin-right: -1rem !important;
  }

  .l\:mx-n5,
.l\:mr-n5 {
    margin-right: -1.25rem !important;
  }

  .l\:mx-n6,
.l\:mr-n6 {
    margin-right: -1.5rem !important;
  }

  .l\:mx-n8,
.l\:mr-n8 {
    margin-right: -2rem !important;
  }

  .l\:mx-n10,
.l\:mr-n10 {
    margin-right: -2.5rem !important;
  }

  .l\:mx-n12,
.l\:mr-n12 {
    margin-right: -3rem !important;
  }

  .l\:mx-n14,
.l\:mr-n14 {
    margin-right: -3.5rem !important;
  }

  .l\:mx-n16,
.l\:mr-n16 {
    margin-right: -4rem !important;
  }

  .l\:mx-n20,
.l\:mr-n20 {
    margin-right: -5rem !important;
  }

  .l\:mx-n24,
.l\:mr-n24 {
    margin-right: -6rem !important;
  }

  .l\:mx-n32,
.l\:mr-n32 {
    margin-right: -8rem !important;
  }

  .l\:mx-n40,
.l\:mr-n40 {
    margin-right: -10rem !important;
  }

  .l\:mx-n1em,
.l\:mr-n1em {
    margin-right: -1em !important;
  }
}
@media (min-width: 1600px) {
  .xl\:mx-n0,
.xl\:mr-n0 {
    margin-right: -0 !important;
  }

  .xl\:mx-n1,
.xl\:mr-n1 {
    margin-right: -0.25rem !important;
  }

  .xl\:mx-n2,
.xl\:mr-n2 {
    margin-right: -0.5rem !important;
  }

  .xl\:mx-n3,
.xl\:mr-n3 {
    margin-right: -0.75rem !important;
  }

  .xl\:mx-n4,
.xl\:mr-n4 {
    margin-right: -1rem !important;
  }

  .xl\:mx-n5,
.xl\:mr-n5 {
    margin-right: -1.25rem !important;
  }

  .xl\:mx-n6,
.xl\:mr-n6 {
    margin-right: -1.5rem !important;
  }

  .xl\:mx-n8,
.xl\:mr-n8 {
    margin-right: -2rem !important;
  }

  .xl\:mx-n10,
.xl\:mr-n10 {
    margin-right: -2.5rem !important;
  }

  .xl\:mx-n12,
.xl\:mr-n12 {
    margin-right: -3rem !important;
  }

  .xl\:mx-n14,
.xl\:mr-n14 {
    margin-right: -3.5rem !important;
  }

  .xl\:mx-n16,
.xl\:mr-n16 {
    margin-right: -4rem !important;
  }

  .xl\:mx-n20,
.xl\:mr-n20 {
    margin-right: -5rem !important;
  }

  .xl\:mx-n24,
.xl\:mr-n24 {
    margin-right: -6rem !important;
  }

  .xl\:mx-n32,
.xl\:mr-n32 {
    margin-right: -8rem !important;
  }

  .xl\:mx-n40,
.xl\:mr-n40 {
    margin-right: -10rem !important;
  }

  .xl\:mx-n1em,
.xl\:mr-n1em {
    margin-right: -1em !important;
  }
}
.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.p-32 {
  padding: 8rem !important;
}

.p-40 {
  padding: 10rem !important;
}

.p-1em {
  padding: 1em !important;
}

@media (min-width: 600px) {
  .s\:p-0 {
    padding: 0 !important;
  }

  .s\:p-1 {
    padding: 0.25rem !important;
  }

  .s\:p-2 {
    padding: 0.5rem !important;
  }

  .s\:p-3 {
    padding: 0.75rem !important;
  }

  .s\:p-4 {
    padding: 1rem !important;
  }

  .s\:p-5 {
    padding: 1.25rem !important;
  }

  .s\:p-6 {
    padding: 1.5rem !important;
  }

  .s\:p-8 {
    padding: 2rem !important;
  }

  .s\:p-10 {
    padding: 2.5rem !important;
  }

  .s\:p-12 {
    padding: 3rem !important;
  }

  .s\:p-14 {
    padding: 3.5rem !important;
  }

  .s\:p-16 {
    padding: 4rem !important;
  }

  .s\:p-20 {
    padding: 5rem !important;
  }

  .s\:p-24 {
    padding: 6rem !important;
  }

  .s\:p-32 {
    padding: 8rem !important;
  }

  .s\:p-40 {
    padding: 10rem !important;
  }

  .s\:p-1em {
    padding: 1em !important;
  }
}
@media (min-width: 900px) {
  .m\:p-0 {
    padding: 0 !important;
  }

  .m\:p-1 {
    padding: 0.25rem !important;
  }

  .m\:p-2 {
    padding: 0.5rem !important;
  }

  .m\:p-3 {
    padding: 0.75rem !important;
  }

  .m\:p-4 {
    padding: 1rem !important;
  }

  .m\:p-5 {
    padding: 1.25rem !important;
  }

  .m\:p-6 {
    padding: 1.5rem !important;
  }

  .m\:p-8 {
    padding: 2rem !important;
  }

  .m\:p-10 {
    padding: 2.5rem !important;
  }

  .m\:p-12 {
    padding: 3rem !important;
  }

  .m\:p-14 {
    padding: 3.5rem !important;
  }

  .m\:p-16 {
    padding: 4rem !important;
  }

  .m\:p-20 {
    padding: 5rem !important;
  }

  .m\:p-24 {
    padding: 6rem !important;
  }

  .m\:p-32 {
    padding: 8rem !important;
  }

  .m\:p-40 {
    padding: 10rem !important;
  }

  .m\:p-1em {
    padding: 1em !important;
  }
}
@media (min-width: 1200px) {
  .l\:p-0 {
    padding: 0 !important;
  }

  .l\:p-1 {
    padding: 0.25rem !important;
  }

  .l\:p-2 {
    padding: 0.5rem !important;
  }

  .l\:p-3 {
    padding: 0.75rem !important;
  }

  .l\:p-4 {
    padding: 1rem !important;
  }

  .l\:p-5 {
    padding: 1.25rem !important;
  }

  .l\:p-6 {
    padding: 1.5rem !important;
  }

  .l\:p-8 {
    padding: 2rem !important;
  }

  .l\:p-10 {
    padding: 2.5rem !important;
  }

  .l\:p-12 {
    padding: 3rem !important;
  }

  .l\:p-14 {
    padding: 3.5rem !important;
  }

  .l\:p-16 {
    padding: 4rem !important;
  }

  .l\:p-20 {
    padding: 5rem !important;
  }

  .l\:p-24 {
    padding: 6rem !important;
  }

  .l\:p-32 {
    padding: 8rem !important;
  }

  .l\:p-40 {
    padding: 10rem !important;
  }

  .l\:p-1em {
    padding: 1em !important;
  }
}
@media (min-width: 1600px) {
  .xl\:p-0 {
    padding: 0 !important;
  }

  .xl\:p-1 {
    padding: 0.25rem !important;
  }

  .xl\:p-2 {
    padding: 0.5rem !important;
  }

  .xl\:p-3 {
    padding: 0.75rem !important;
  }

  .xl\:p-4 {
    padding: 1rem !important;
  }

  .xl\:p-5 {
    padding: 1.25rem !important;
  }

  .xl\:p-6 {
    padding: 1.5rem !important;
  }

  .xl\:p-8 {
    padding: 2rem !important;
  }

  .xl\:p-10 {
    padding: 2.5rem !important;
  }

  .xl\:p-12 {
    padding: 3rem !important;
  }

  .xl\:p-14 {
    padding: 3.5rem !important;
  }

  .xl\:p-16 {
    padding: 4rem !important;
  }

  .xl\:p-20 {
    padding: 5rem !important;
  }

  .xl\:p-24 {
    padding: 6rem !important;
  }

  .xl\:p-32 {
    padding: 8rem !important;
  }

  .xl\:p-40 {
    padding: 10rem !important;
  }

  .xl\:p-1em {
    padding: 1em !important;
  }
}
.py-0,
.pt-0 {
  padding-top: 0 !important;
}

.py-1,
.pt-1 {
  padding-top: 0.25rem !important;
}

.py-2,
.pt-2 {
  padding-top: 0.5rem !important;
}

.py-3,
.pt-3 {
  padding-top: 0.75rem !important;
}

.py-4,
.pt-4 {
  padding-top: 1rem !important;
}

.py-5,
.pt-5 {
  padding-top: 1.25rem !important;
}

.py-6,
.pt-6 {
  padding-top: 1.5rem !important;
}

.py-8,
.pt-8 {
  padding-top: 2rem !important;
}

.py-10,
.pt-10 {
  padding-top: 2.5rem !important;
}

.py-12,
.pt-12 {
  padding-top: 3rem !important;
}

.py-14,
.pt-14 {
  padding-top: 3.5rem !important;
}

.py-16,
.pt-16 {
  padding-top: 4rem !important;
}

.py-20,
.pt-20 {
  padding-top: 5rem !important;
}

.py-24,
.pt-24 {
  padding-top: 6rem !important;
}

.py-32,
.pt-32 {
  padding-top: 8rem !important;
}

.py-40,
.pt-40 {
  padding-top: 10rem !important;
}

.py-1em,
.pt-1em {
  padding-top: 1em !important;
}

.py-container,
.pt-container {
  padding-top: var(--padding-y) !important;
}

@media (min-width: 600px) {
  .s\:py-0,
.s\:pt-0 {
    padding-top: 0 !important;
  }

  .s\:py-1,
.s\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .s\:py-2,
.s\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .s\:py-3,
.s\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .s\:py-4,
.s\:pt-4 {
    padding-top: 1rem !important;
  }

  .s\:py-5,
.s\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .s\:py-6,
.s\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .s\:py-8,
.s\:pt-8 {
    padding-top: 2rem !important;
  }

  .s\:py-10,
.s\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .s\:py-12,
.s\:pt-12 {
    padding-top: 3rem !important;
  }

  .s\:py-14,
.s\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .s\:py-16,
.s\:pt-16 {
    padding-top: 4rem !important;
  }

  .s\:py-20,
.s\:pt-20 {
    padding-top: 5rem !important;
  }

  .s\:py-24,
.s\:pt-24 {
    padding-top: 6rem !important;
  }

  .s\:py-32,
.s\:pt-32 {
    padding-top: 8rem !important;
  }

  .s\:py-40,
.s\:pt-40 {
    padding-top: 10rem !important;
  }

  .s\:py-1em,
.s\:pt-1em {
    padding-top: 1em !important;
  }

  .s\:py-container,
.s\:pt-container {
    padding-top: var(--padding-y) !important;
  }
}
@media (min-width: 900px) {
  .m\:py-0,
.m\:pt-0 {
    padding-top: 0 !important;
  }

  .m\:py-1,
.m\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .m\:py-2,
.m\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .m\:py-3,
.m\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .m\:py-4,
.m\:pt-4 {
    padding-top: 1rem !important;
  }

  .m\:py-5,
.m\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .m\:py-6,
.m\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .m\:py-8,
.m\:pt-8 {
    padding-top: 2rem !important;
  }

  .m\:py-10,
.m\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .m\:py-12,
.m\:pt-12 {
    padding-top: 3rem !important;
  }

  .m\:py-14,
.m\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .m\:py-16,
.m\:pt-16 {
    padding-top: 4rem !important;
  }

  .m\:py-20,
.m\:pt-20 {
    padding-top: 5rem !important;
  }

  .m\:py-24,
.m\:pt-24 {
    padding-top: 6rem !important;
  }

  .m\:py-32,
.m\:pt-32 {
    padding-top: 8rem !important;
  }

  .m\:py-40,
.m\:pt-40 {
    padding-top: 10rem !important;
  }

  .m\:py-1em,
.m\:pt-1em {
    padding-top: 1em !important;
  }

  .m\:py-container,
.m\:pt-container {
    padding-top: var(--padding-y) !important;
  }
}
@media (min-width: 1200px) {
  .l\:py-0,
.l\:pt-0 {
    padding-top: 0 !important;
  }

  .l\:py-1,
.l\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .l\:py-2,
.l\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .l\:py-3,
.l\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .l\:py-4,
.l\:pt-4 {
    padding-top: 1rem !important;
  }

  .l\:py-5,
.l\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .l\:py-6,
.l\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .l\:py-8,
.l\:pt-8 {
    padding-top: 2rem !important;
  }

  .l\:py-10,
.l\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .l\:py-12,
.l\:pt-12 {
    padding-top: 3rem !important;
  }

  .l\:py-14,
.l\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .l\:py-16,
.l\:pt-16 {
    padding-top: 4rem !important;
  }

  .l\:py-20,
.l\:pt-20 {
    padding-top: 5rem !important;
  }

  .l\:py-24,
.l\:pt-24 {
    padding-top: 6rem !important;
  }

  .l\:py-32,
.l\:pt-32 {
    padding-top: 8rem !important;
  }

  .l\:py-40,
.l\:pt-40 {
    padding-top: 10rem !important;
  }

  .l\:py-1em,
.l\:pt-1em {
    padding-top: 1em !important;
  }

  .l\:py-container,
.l\:pt-container {
    padding-top: var(--padding-y) !important;
  }
}
@media (min-width: 1600px) {
  .xl\:py-0,
.xl\:pt-0 {
    padding-top: 0 !important;
  }

  .xl\:py-1,
.xl\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .xl\:py-2,
.xl\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .xl\:py-3,
.xl\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .xl\:py-4,
.xl\:pt-4 {
    padding-top: 1rem !important;
  }

  .xl\:py-5,
.xl\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .xl\:py-6,
.xl\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .xl\:py-8,
.xl\:pt-8 {
    padding-top: 2rem !important;
  }

  .xl\:py-10,
.xl\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .xl\:py-12,
.xl\:pt-12 {
    padding-top: 3rem !important;
  }

  .xl\:py-14,
.xl\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .xl\:py-16,
.xl\:pt-16 {
    padding-top: 4rem !important;
  }

  .xl\:py-20,
.xl\:pt-20 {
    padding-top: 5rem !important;
  }

  .xl\:py-24,
.xl\:pt-24 {
    padding-top: 6rem !important;
  }

  .xl\:py-32,
.xl\:pt-32 {
    padding-top: 8rem !important;
  }

  .xl\:py-40,
.xl\:pt-40 {
    padding-top: 10rem !important;
  }

  .xl\:py-1em,
.xl\:pt-1em {
    padding-top: 1em !important;
  }

  .xl\:py-container,
.xl\:pt-container {
    padding-top: var(--padding-y) !important;
  }
}
.py-0,
.pb-0 {
  padding-bottom: 0 !important;
}

.py-1,
.pb-1 {
  padding-bottom: 0.25rem !important;
}

.py-2,
.pb-2 {
  padding-bottom: 0.5rem !important;
}

.py-3,
.pb-3 {
  padding-bottom: 0.75rem !important;
}

.py-4,
.pb-4 {
  padding-bottom: 1rem !important;
}

.py-5,
.pb-5 {
  padding-bottom: 1.25rem !important;
}

.py-6,
.pb-6 {
  padding-bottom: 1.5rem !important;
}

.py-8,
.pb-8 {
  padding-bottom: 2rem !important;
}

.py-10,
.pb-10 {
  padding-bottom: 2.5rem !important;
}

.py-12,
.pb-12 {
  padding-bottom: 3rem !important;
}

.py-14,
.pb-14 {
  padding-bottom: 3.5rem !important;
}

.py-16,
.pb-16 {
  padding-bottom: 4rem !important;
}

.py-20,
.pb-20 {
  padding-bottom: 5rem !important;
}

.py-24,
.pb-24 {
  padding-bottom: 6rem !important;
}

.py-32,
.pb-32 {
  padding-bottom: 8rem !important;
}

.py-40,
.pb-40 {
  padding-bottom: 10rem !important;
}

.py-1em,
.pb-1em {
  padding-bottom: 1em !important;
}

.py-container,
.pb-container {
  padding-bottom: var(--padding-y) !important;
}

@media (min-width: 600px) {
  .s\:py-0,
.s\:pb-0 {
    padding-bottom: 0 !important;
  }

  .s\:py-1,
.s\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .s\:py-2,
.s\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .s\:py-3,
.s\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .s\:py-4,
.s\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .s\:py-5,
.s\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .s\:py-6,
.s\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .s\:py-8,
.s\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .s\:py-10,
.s\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .s\:py-12,
.s\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .s\:py-14,
.s\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .s\:py-16,
.s\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .s\:py-20,
.s\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .s\:py-24,
.s\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .s\:py-32,
.s\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .s\:py-40,
.s\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .s\:py-1em,
.s\:pb-1em {
    padding-bottom: 1em !important;
  }

  .s\:py-container,
.s\:pb-container {
    padding-bottom: var(--padding-y) !important;
  }
}
@media (min-width: 900px) {
  .m\:py-0,
.m\:pb-0 {
    padding-bottom: 0 !important;
  }

  .m\:py-1,
.m\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .m\:py-2,
.m\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .m\:py-3,
.m\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .m\:py-4,
.m\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .m\:py-5,
.m\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .m\:py-6,
.m\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .m\:py-8,
.m\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .m\:py-10,
.m\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .m\:py-12,
.m\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .m\:py-14,
.m\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .m\:py-16,
.m\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .m\:py-20,
.m\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .m\:py-24,
.m\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .m\:py-32,
.m\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .m\:py-40,
.m\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .m\:py-1em,
.m\:pb-1em {
    padding-bottom: 1em !important;
  }

  .m\:py-container,
.m\:pb-container {
    padding-bottom: var(--padding-y) !important;
  }
}
@media (min-width: 1200px) {
  .l\:py-0,
.l\:pb-0 {
    padding-bottom: 0 !important;
  }

  .l\:py-1,
.l\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .l\:py-2,
.l\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .l\:py-3,
.l\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .l\:py-4,
.l\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .l\:py-5,
.l\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .l\:py-6,
.l\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .l\:py-8,
.l\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .l\:py-10,
.l\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .l\:py-12,
.l\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .l\:py-14,
.l\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .l\:py-16,
.l\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .l\:py-20,
.l\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .l\:py-24,
.l\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .l\:py-32,
.l\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .l\:py-40,
.l\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .l\:py-1em,
.l\:pb-1em {
    padding-bottom: 1em !important;
  }

  .l\:py-container,
.l\:pb-container {
    padding-bottom: var(--padding-y) !important;
  }
}
@media (min-width: 1600px) {
  .xl\:py-0,
.xl\:pb-0 {
    padding-bottom: 0 !important;
  }

  .xl\:py-1,
.xl\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .xl\:py-2,
.xl\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .xl\:py-3,
.xl\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .xl\:py-4,
.xl\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .xl\:py-5,
.xl\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .xl\:py-6,
.xl\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .xl\:py-8,
.xl\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .xl\:py-10,
.xl\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .xl\:py-12,
.xl\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .xl\:py-14,
.xl\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .xl\:py-16,
.xl\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .xl\:py-20,
.xl\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .xl\:py-24,
.xl\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .xl\:py-32,
.xl\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .xl\:py-40,
.xl\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .xl\:py-1em,
.xl\:pb-1em {
    padding-bottom: 1em !important;
  }

  .xl\:py-container,
.xl\:pb-container {
    padding-bottom: var(--padding-y) !important;
  }
}
.px-0,
.pl-0 {
  padding-left: 0 !important;
}

.px-1,
.pl-1 {
  padding-left: 0.25rem !important;
}

.px-2,
.pl-2 {
  padding-left: 0.5rem !important;
}

.px-3,
.pl-3 {
  padding-left: 0.75rem !important;
}

.px-4,
.pl-4 {
  padding-left: 1rem !important;
}

.px-5,
.pl-5 {
  padding-left: 1.25rem !important;
}

.px-6,
.pl-6 {
  padding-left: 1.5rem !important;
}

.px-8,
.pl-8 {
  padding-left: 2rem !important;
}

.px-10,
.pl-10 {
  padding-left: 2.5rem !important;
}

.px-12,
.pl-12 {
  padding-left: 3rem !important;
}

.px-14,
.pl-14 {
  padding-left: 3.5rem !important;
}

.px-16,
.pl-16 {
  padding-left: 4rem !important;
}

.px-20,
.pl-20 {
  padding-left: 5rem !important;
}

.px-24,
.pl-24 {
  padding-left: 6rem !important;
}

.px-32,
.pl-32 {
  padding-left: 8rem !important;
}

.px-40,
.pl-40 {
  padding-left: 10rem !important;
}

.px-1em,
.pl-1em {
  padding-left: 1em !important;
}

@media (min-width: 600px) {
  .s\:px-0,
.s\:pl-0 {
    padding-left: 0 !important;
  }

  .s\:px-1,
.s\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .s\:px-2,
.s\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .s\:px-3,
.s\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .s\:px-4,
.s\:pl-4 {
    padding-left: 1rem !important;
  }

  .s\:px-5,
.s\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .s\:px-6,
.s\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .s\:px-8,
.s\:pl-8 {
    padding-left: 2rem !important;
  }

  .s\:px-10,
.s\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .s\:px-12,
.s\:pl-12 {
    padding-left: 3rem !important;
  }

  .s\:px-14,
.s\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .s\:px-16,
.s\:pl-16 {
    padding-left: 4rem !important;
  }

  .s\:px-20,
.s\:pl-20 {
    padding-left: 5rem !important;
  }

  .s\:px-24,
.s\:pl-24 {
    padding-left: 6rem !important;
  }

  .s\:px-32,
.s\:pl-32 {
    padding-left: 8rem !important;
  }

  .s\:px-40,
.s\:pl-40 {
    padding-left: 10rem !important;
  }

  .s\:px-1em,
.s\:pl-1em {
    padding-left: 1em !important;
  }
}
@media (min-width: 900px) {
  .m\:px-0,
.m\:pl-0 {
    padding-left: 0 !important;
  }

  .m\:px-1,
.m\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .m\:px-2,
.m\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .m\:px-3,
.m\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .m\:px-4,
.m\:pl-4 {
    padding-left: 1rem !important;
  }

  .m\:px-5,
.m\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .m\:px-6,
.m\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .m\:px-8,
.m\:pl-8 {
    padding-left: 2rem !important;
  }

  .m\:px-10,
.m\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .m\:px-12,
.m\:pl-12 {
    padding-left: 3rem !important;
  }

  .m\:px-14,
.m\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .m\:px-16,
.m\:pl-16 {
    padding-left: 4rem !important;
  }

  .m\:px-20,
.m\:pl-20 {
    padding-left: 5rem !important;
  }

  .m\:px-24,
.m\:pl-24 {
    padding-left: 6rem !important;
  }

  .m\:px-32,
.m\:pl-32 {
    padding-left: 8rem !important;
  }

  .m\:px-40,
.m\:pl-40 {
    padding-left: 10rem !important;
  }

  .m\:px-1em,
.m\:pl-1em {
    padding-left: 1em !important;
  }
}
@media (min-width: 1200px) {
  .l\:px-0,
.l\:pl-0 {
    padding-left: 0 !important;
  }

  .l\:px-1,
.l\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .l\:px-2,
.l\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .l\:px-3,
.l\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .l\:px-4,
.l\:pl-4 {
    padding-left: 1rem !important;
  }

  .l\:px-5,
.l\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .l\:px-6,
.l\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .l\:px-8,
.l\:pl-8 {
    padding-left: 2rem !important;
  }

  .l\:px-10,
.l\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .l\:px-12,
.l\:pl-12 {
    padding-left: 3rem !important;
  }

  .l\:px-14,
.l\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .l\:px-16,
.l\:pl-16 {
    padding-left: 4rem !important;
  }

  .l\:px-20,
.l\:pl-20 {
    padding-left: 5rem !important;
  }

  .l\:px-24,
.l\:pl-24 {
    padding-left: 6rem !important;
  }

  .l\:px-32,
.l\:pl-32 {
    padding-left: 8rem !important;
  }

  .l\:px-40,
.l\:pl-40 {
    padding-left: 10rem !important;
  }

  .l\:px-1em,
.l\:pl-1em {
    padding-left: 1em !important;
  }
}
@media (min-width: 1600px) {
  .xl\:px-0,
.xl\:pl-0 {
    padding-left: 0 !important;
  }

  .xl\:px-1,
.xl\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .xl\:px-2,
.xl\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .xl\:px-3,
.xl\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .xl\:px-4,
.xl\:pl-4 {
    padding-left: 1rem !important;
  }

  .xl\:px-5,
.xl\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .xl\:px-6,
.xl\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .xl\:px-8,
.xl\:pl-8 {
    padding-left: 2rem !important;
  }

  .xl\:px-10,
.xl\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .xl\:px-12,
.xl\:pl-12 {
    padding-left: 3rem !important;
  }

  .xl\:px-14,
.xl\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .xl\:px-16,
.xl\:pl-16 {
    padding-left: 4rem !important;
  }

  .xl\:px-20,
.xl\:pl-20 {
    padding-left: 5rem !important;
  }

  .xl\:px-24,
.xl\:pl-24 {
    padding-left: 6rem !important;
  }

  .xl\:px-32,
.xl\:pl-32 {
    padding-left: 8rem !important;
  }

  .xl\:px-40,
.xl\:pl-40 {
    padding-left: 10rem !important;
  }

  .xl\:px-1em,
.xl\:pl-1em {
    padding-left: 1em !important;
  }
}
.px-0,
.pr-0 {
  padding-right: 0 !important;
}

.px-1,
.pr-1 {
  padding-right: 0.25rem !important;
}

.px-2,
.pr-2 {
  padding-right: 0.5rem !important;
}

.px-3,
.pr-3 {
  padding-right: 0.75rem !important;
}

.px-4,
.pr-4 {
  padding-right: 1rem !important;
}

.px-5,
.pr-5 {
  padding-right: 1.25rem !important;
}

.px-6,
.pr-6 {
  padding-right: 1.5rem !important;
}

.px-8,
.pr-8 {
  padding-right: 2rem !important;
}

.px-10,
.pr-10 {
  padding-right: 2.5rem !important;
}

.px-12,
.pr-12 {
  padding-right: 3rem !important;
}

.px-14,
.pr-14 {
  padding-right: 3.5rem !important;
}

.px-16,
.pr-16 {
  padding-right: 4rem !important;
}

.px-20,
.pr-20 {
  padding-right: 5rem !important;
}

.px-24,
.pr-24 {
  padding-right: 6rem !important;
}

.px-32,
.pr-32 {
  padding-right: 8rem !important;
}

.px-40,
.pr-40 {
  padding-right: 10rem !important;
}

.px-1em,
.pr-1em {
  padding-right: 1em !important;
}

@media (min-width: 600px) {
  .s\:px-0,
.s\:pr-0 {
    padding-right: 0 !important;
  }

  .s\:px-1,
.s\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .s\:px-2,
.s\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .s\:px-3,
.s\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .s\:px-4,
.s\:pr-4 {
    padding-right: 1rem !important;
  }

  .s\:px-5,
.s\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .s\:px-6,
.s\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .s\:px-8,
.s\:pr-8 {
    padding-right: 2rem !important;
  }

  .s\:px-10,
.s\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .s\:px-12,
.s\:pr-12 {
    padding-right: 3rem !important;
  }

  .s\:px-14,
.s\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .s\:px-16,
.s\:pr-16 {
    padding-right: 4rem !important;
  }

  .s\:px-20,
.s\:pr-20 {
    padding-right: 5rem !important;
  }

  .s\:px-24,
.s\:pr-24 {
    padding-right: 6rem !important;
  }

  .s\:px-32,
.s\:pr-32 {
    padding-right: 8rem !important;
  }

  .s\:px-40,
.s\:pr-40 {
    padding-right: 10rem !important;
  }

  .s\:px-1em,
.s\:pr-1em {
    padding-right: 1em !important;
  }
}
@media (min-width: 900px) {
  .m\:px-0,
.m\:pr-0 {
    padding-right: 0 !important;
  }

  .m\:px-1,
.m\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .m\:px-2,
.m\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .m\:px-3,
.m\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .m\:px-4,
.m\:pr-4 {
    padding-right: 1rem !important;
  }

  .m\:px-5,
.m\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .m\:px-6,
.m\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .m\:px-8,
.m\:pr-8 {
    padding-right: 2rem !important;
  }

  .m\:px-10,
.m\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .m\:px-12,
.m\:pr-12 {
    padding-right: 3rem !important;
  }

  .m\:px-14,
.m\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .m\:px-16,
.m\:pr-16 {
    padding-right: 4rem !important;
  }

  .m\:px-20,
.m\:pr-20 {
    padding-right: 5rem !important;
  }

  .m\:px-24,
.m\:pr-24 {
    padding-right: 6rem !important;
  }

  .m\:px-32,
.m\:pr-32 {
    padding-right: 8rem !important;
  }

  .m\:px-40,
.m\:pr-40 {
    padding-right: 10rem !important;
  }

  .m\:px-1em,
.m\:pr-1em {
    padding-right: 1em !important;
  }
}
@media (min-width: 1200px) {
  .l\:px-0,
.l\:pr-0 {
    padding-right: 0 !important;
  }

  .l\:px-1,
.l\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .l\:px-2,
.l\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .l\:px-3,
.l\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .l\:px-4,
.l\:pr-4 {
    padding-right: 1rem !important;
  }

  .l\:px-5,
.l\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .l\:px-6,
.l\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .l\:px-8,
.l\:pr-8 {
    padding-right: 2rem !important;
  }

  .l\:px-10,
.l\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .l\:px-12,
.l\:pr-12 {
    padding-right: 3rem !important;
  }

  .l\:px-14,
.l\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .l\:px-16,
.l\:pr-16 {
    padding-right: 4rem !important;
  }

  .l\:px-20,
.l\:pr-20 {
    padding-right: 5rem !important;
  }

  .l\:px-24,
.l\:pr-24 {
    padding-right: 6rem !important;
  }

  .l\:px-32,
.l\:pr-32 {
    padding-right: 8rem !important;
  }

  .l\:px-40,
.l\:pr-40 {
    padding-right: 10rem !important;
  }

  .l\:px-1em,
.l\:pr-1em {
    padding-right: 1em !important;
  }
}
@media (min-width: 1600px) {
  .xl\:px-0,
.xl\:pr-0 {
    padding-right: 0 !important;
  }

  .xl\:px-1,
.xl\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .xl\:px-2,
.xl\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .xl\:px-3,
.xl\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .xl\:px-4,
.xl\:pr-4 {
    padding-right: 1rem !important;
  }

  .xl\:px-5,
.xl\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .xl\:px-6,
.xl\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .xl\:px-8,
.xl\:pr-8 {
    padding-right: 2rem !important;
  }

  .xl\:px-10,
.xl\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .xl\:px-12,
.xl\:pr-12 {
    padding-right: 3rem !important;
  }

  .xl\:px-14,
.xl\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .xl\:px-16,
.xl\:pr-16 {
    padding-right: 4rem !important;
  }

  .xl\:px-20,
.xl\:pr-20 {
    padding-right: 5rem !important;
  }

  .xl\:px-24,
.xl\:pr-24 {
    padding-right: 6rem !important;
  }

  .xl\:px-32,
.xl\:pr-32 {
    padding-right: 8rem !important;
  }

  .xl\:px-40,
.xl\:pr-40 {
    padding-right: 10rem !important;
  }

  .xl\:px-1em,
.xl\:pr-1em {
    padding-right: 1em !important;
  }
}
.my-0-children > :first-child {
  margin-top: 0 !important;
}
.my-0-children > :last-child {
  margin-bottom: 0 !important;
}

.w-auto {
  width: auto !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-66 {
  width: 66.6% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-100px {
  width: 100px !important;
}

.w-150px {
  width: 150px !important;
}

.w-200px {
  width: 200px !important;
}

.w-250px {
  width: 250px !important;
}

.w-300px {
  width: 300px !important;
}

.w-350px {
  width: 350px !important;
}

.w-400px {
  width: 400px !important;
}

.w-450px {
  width: 450px !important;
}

.w-500px {
  width: 500px !important;
}

.w-550px {
  width: 550px !important;
}

.w-600px {
  width: 600px !important;
}

.w-650px {
  width: 650px !important;
}

.w-700px {
  width: 700px !important;
}

.w-750px {
  width: 750px !important;
}

.w-800px {
  width: 800px !important;
}

.w-900px {
  width: 900px !important;
}

.w-950px {
  width: 950px !important;
}

.w-1000px {
  width: 1000px !important;
}

.w-1100px {
  width: 1100px !important;
}

.w-1200px {
  width: 1200px !important;
}

@media (min-width: 600px) {
  .s\:w-auto {
    width: auto !important;
  }

  .s\:w-25 {
    width: 25% !important;
  }

  .s\:w-50 {
    width: 50% !important;
  }

  .s\:w-66 {
    width: 66.6% !important;
  }

  .s\:w-75 {
    width: 75% !important;
  }

  .s\:w-100 {
    width: 100% !important;
  }

  .s\:w-100px {
    width: 100px !important;
  }

  .s\:w-150px {
    width: 150px !important;
  }

  .s\:w-200px {
    width: 200px !important;
  }

  .s\:w-250px {
    width: 250px !important;
  }

  .s\:w-300px {
    width: 300px !important;
  }

  .s\:w-350px {
    width: 350px !important;
  }

  .s\:w-400px {
    width: 400px !important;
  }

  .s\:w-450px {
    width: 450px !important;
  }

  .s\:w-500px {
    width: 500px !important;
  }

  .s\:w-550px {
    width: 550px !important;
  }

  .s\:w-600px {
    width: 600px !important;
  }

  .s\:w-650px {
    width: 650px !important;
  }

  .s\:w-700px {
    width: 700px !important;
  }

  .s\:w-750px {
    width: 750px !important;
  }

  .s\:w-800px {
    width: 800px !important;
  }

  .s\:w-900px {
    width: 900px !important;
  }

  .s\:w-950px {
    width: 950px !important;
  }

  .s\:w-1000px {
    width: 1000px !important;
  }

  .s\:w-1100px {
    width: 1100px !important;
  }

  .s\:w-1200px {
    width: 1200px !important;
  }
}
@media (min-width: 900px) {
  .m\:w-auto {
    width: auto !important;
  }

  .m\:w-25 {
    width: 25% !important;
  }

  .m\:w-50 {
    width: 50% !important;
  }

  .m\:w-66 {
    width: 66.6% !important;
  }

  .m\:w-75 {
    width: 75% !important;
  }

  .m\:w-100 {
    width: 100% !important;
  }

  .m\:w-100px {
    width: 100px !important;
  }

  .m\:w-150px {
    width: 150px !important;
  }

  .m\:w-200px {
    width: 200px !important;
  }

  .m\:w-250px {
    width: 250px !important;
  }

  .m\:w-300px {
    width: 300px !important;
  }

  .m\:w-350px {
    width: 350px !important;
  }

  .m\:w-400px {
    width: 400px !important;
  }

  .m\:w-450px {
    width: 450px !important;
  }

  .m\:w-500px {
    width: 500px !important;
  }

  .m\:w-550px {
    width: 550px !important;
  }

  .m\:w-600px {
    width: 600px !important;
  }

  .m\:w-650px {
    width: 650px !important;
  }

  .m\:w-700px {
    width: 700px !important;
  }

  .m\:w-750px {
    width: 750px !important;
  }

  .m\:w-800px {
    width: 800px !important;
  }

  .m\:w-900px {
    width: 900px !important;
  }

  .m\:w-950px {
    width: 950px !important;
  }

  .m\:w-1000px {
    width: 1000px !important;
  }

  .m\:w-1100px {
    width: 1100px !important;
  }

  .m\:w-1200px {
    width: 1200px !important;
  }
}
@media (min-width: 1200px) {
  .l\:w-auto {
    width: auto !important;
  }

  .l\:w-25 {
    width: 25% !important;
  }

  .l\:w-50 {
    width: 50% !important;
  }

  .l\:w-66 {
    width: 66.6% !important;
  }

  .l\:w-75 {
    width: 75% !important;
  }

  .l\:w-100 {
    width: 100% !important;
  }

  .l\:w-100px {
    width: 100px !important;
  }

  .l\:w-150px {
    width: 150px !important;
  }

  .l\:w-200px {
    width: 200px !important;
  }

  .l\:w-250px {
    width: 250px !important;
  }

  .l\:w-300px {
    width: 300px !important;
  }

  .l\:w-350px {
    width: 350px !important;
  }

  .l\:w-400px {
    width: 400px !important;
  }

  .l\:w-450px {
    width: 450px !important;
  }

  .l\:w-500px {
    width: 500px !important;
  }

  .l\:w-550px {
    width: 550px !important;
  }

  .l\:w-600px {
    width: 600px !important;
  }

  .l\:w-650px {
    width: 650px !important;
  }

  .l\:w-700px {
    width: 700px !important;
  }

  .l\:w-750px {
    width: 750px !important;
  }

  .l\:w-800px {
    width: 800px !important;
  }

  .l\:w-900px {
    width: 900px !important;
  }

  .l\:w-950px {
    width: 950px !important;
  }

  .l\:w-1000px {
    width: 1000px !important;
  }

  .l\:w-1100px {
    width: 1100px !important;
  }

  .l\:w-1200px {
    width: 1200px !important;
  }
}
@media (min-width: 1600px) {
  .xl\:w-auto {
    width: auto !important;
  }

  .xl\:w-25 {
    width: 25% !important;
  }

  .xl\:w-50 {
    width: 50% !important;
  }

  .xl\:w-66 {
    width: 66.6% !important;
  }

  .xl\:w-75 {
    width: 75% !important;
  }

  .xl\:w-100 {
    width: 100% !important;
  }

  .xl\:w-100px {
    width: 100px !important;
  }

  .xl\:w-150px {
    width: 150px !important;
  }

  .xl\:w-200px {
    width: 200px !important;
  }

  .xl\:w-250px {
    width: 250px !important;
  }

  .xl\:w-300px {
    width: 300px !important;
  }

  .xl\:w-350px {
    width: 350px !important;
  }

  .xl\:w-400px {
    width: 400px !important;
  }

  .xl\:w-450px {
    width: 450px !important;
  }

  .xl\:w-500px {
    width: 500px !important;
  }

  .xl\:w-550px {
    width: 550px !important;
  }

  .xl\:w-600px {
    width: 600px !important;
  }

  .xl\:w-650px {
    width: 650px !important;
  }

  .xl\:w-700px {
    width: 700px !important;
  }

  .xl\:w-750px {
    width: 750px !important;
  }

  .xl\:w-800px {
    width: 800px !important;
  }

  .xl\:w-900px {
    width: 900px !important;
  }

  .xl\:w-950px {
    width: 950px !important;
  }

  .xl\:w-1000px {
    width: 1000px !important;
  }

  .xl\:w-1100px {
    width: 1100px !important;
  }

  .xl\:w-1200px {
    width: 1200px !important;
  }
}
.minw-auto {
  min-width: auto !important;
}

.minw-25 {
  min-width: 25% !important;
}

.minw-50 {
  min-width: 50% !important;
}

.minw-66 {
  min-width: 66.6% !important;
}

.minw-75 {
  min-width: 75% !important;
}

.minw-100 {
  min-width: 100% !important;
}

.minw-100px {
  min-width: 100px !important;
}

.minw-150px {
  min-width: 150px !important;
}

.minw-200px {
  min-width: 200px !important;
}

.minw-250px {
  min-width: 250px !important;
}

.minw-300px {
  min-width: 300px !important;
}

.minw-350px {
  min-width: 350px !important;
}

.minw-400px {
  min-width: 400px !important;
}

.minw-450px {
  min-width: 450px !important;
}

.minw-500px {
  min-width: 500px !important;
}

.minw-550px {
  min-width: 550px !important;
}

.minw-600px {
  min-width: 600px !important;
}

.minw-650px {
  min-width: 650px !important;
}

.minw-700px {
  min-width: 700px !important;
}

.minw-750px {
  min-width: 750px !important;
}

.minw-800px {
  min-width: 800px !important;
}

.minw-900px {
  min-width: 900px !important;
}

.minw-950px {
  min-width: 950px !important;
}

.minw-1000px {
  min-width: 1000px !important;
}

.minw-1100px {
  min-width: 1100px !important;
}

.minw-1200px {
  min-width: 1200px !important;
}

@media (min-width: 600px) {
  .s\:minw-auto {
    min-width: auto !important;
  }

  .s\:minw-25 {
    min-width: 25% !important;
  }

  .s\:minw-50 {
    min-width: 50% !important;
  }

  .s\:minw-66 {
    min-width: 66.6% !important;
  }

  .s\:minw-75 {
    min-width: 75% !important;
  }

  .s\:minw-100 {
    min-width: 100% !important;
  }

  .s\:minw-100px {
    min-width: 100px !important;
  }

  .s\:minw-150px {
    min-width: 150px !important;
  }

  .s\:minw-200px {
    min-width: 200px !important;
  }

  .s\:minw-250px {
    min-width: 250px !important;
  }

  .s\:minw-300px {
    min-width: 300px !important;
  }

  .s\:minw-350px {
    min-width: 350px !important;
  }

  .s\:minw-400px {
    min-width: 400px !important;
  }

  .s\:minw-450px {
    min-width: 450px !important;
  }

  .s\:minw-500px {
    min-width: 500px !important;
  }

  .s\:minw-550px {
    min-width: 550px !important;
  }

  .s\:minw-600px {
    min-width: 600px !important;
  }

  .s\:minw-650px {
    min-width: 650px !important;
  }

  .s\:minw-700px {
    min-width: 700px !important;
  }

  .s\:minw-750px {
    min-width: 750px !important;
  }

  .s\:minw-800px {
    min-width: 800px !important;
  }

  .s\:minw-900px {
    min-width: 900px !important;
  }

  .s\:minw-950px {
    min-width: 950px !important;
  }

  .s\:minw-1000px {
    min-width: 1000px !important;
  }

  .s\:minw-1100px {
    min-width: 1100px !important;
  }

  .s\:minw-1200px {
    min-width: 1200px !important;
  }
}
@media (min-width: 900px) {
  .m\:minw-auto {
    min-width: auto !important;
  }

  .m\:minw-25 {
    min-width: 25% !important;
  }

  .m\:minw-50 {
    min-width: 50% !important;
  }

  .m\:minw-66 {
    min-width: 66.6% !important;
  }

  .m\:minw-75 {
    min-width: 75% !important;
  }

  .m\:minw-100 {
    min-width: 100% !important;
  }

  .m\:minw-100px {
    min-width: 100px !important;
  }

  .m\:minw-150px {
    min-width: 150px !important;
  }

  .m\:minw-200px {
    min-width: 200px !important;
  }

  .m\:minw-250px {
    min-width: 250px !important;
  }

  .m\:minw-300px {
    min-width: 300px !important;
  }

  .m\:minw-350px {
    min-width: 350px !important;
  }

  .m\:minw-400px {
    min-width: 400px !important;
  }

  .m\:minw-450px {
    min-width: 450px !important;
  }

  .m\:minw-500px {
    min-width: 500px !important;
  }

  .m\:minw-550px {
    min-width: 550px !important;
  }

  .m\:minw-600px {
    min-width: 600px !important;
  }

  .m\:minw-650px {
    min-width: 650px !important;
  }

  .m\:minw-700px {
    min-width: 700px !important;
  }

  .m\:minw-750px {
    min-width: 750px !important;
  }

  .m\:minw-800px {
    min-width: 800px !important;
  }

  .m\:minw-900px {
    min-width: 900px !important;
  }

  .m\:minw-950px {
    min-width: 950px !important;
  }

  .m\:minw-1000px {
    min-width: 1000px !important;
  }

  .m\:minw-1100px {
    min-width: 1100px !important;
  }

  .m\:minw-1200px {
    min-width: 1200px !important;
  }
}
@media (min-width: 1200px) {
  .l\:minw-auto {
    min-width: auto !important;
  }

  .l\:minw-25 {
    min-width: 25% !important;
  }

  .l\:minw-50 {
    min-width: 50% !important;
  }

  .l\:minw-66 {
    min-width: 66.6% !important;
  }

  .l\:minw-75 {
    min-width: 75% !important;
  }

  .l\:minw-100 {
    min-width: 100% !important;
  }

  .l\:minw-100px {
    min-width: 100px !important;
  }

  .l\:minw-150px {
    min-width: 150px !important;
  }

  .l\:minw-200px {
    min-width: 200px !important;
  }

  .l\:minw-250px {
    min-width: 250px !important;
  }

  .l\:minw-300px {
    min-width: 300px !important;
  }

  .l\:minw-350px {
    min-width: 350px !important;
  }

  .l\:minw-400px {
    min-width: 400px !important;
  }

  .l\:minw-450px {
    min-width: 450px !important;
  }

  .l\:minw-500px {
    min-width: 500px !important;
  }

  .l\:minw-550px {
    min-width: 550px !important;
  }

  .l\:minw-600px {
    min-width: 600px !important;
  }

  .l\:minw-650px {
    min-width: 650px !important;
  }

  .l\:minw-700px {
    min-width: 700px !important;
  }

  .l\:minw-750px {
    min-width: 750px !important;
  }

  .l\:minw-800px {
    min-width: 800px !important;
  }

  .l\:minw-900px {
    min-width: 900px !important;
  }

  .l\:minw-950px {
    min-width: 950px !important;
  }

  .l\:minw-1000px {
    min-width: 1000px !important;
  }

  .l\:minw-1100px {
    min-width: 1100px !important;
  }

  .l\:minw-1200px {
    min-width: 1200px !important;
  }
}
@media (min-width: 1600px) {
  .xl\:minw-auto {
    min-width: auto !important;
  }

  .xl\:minw-25 {
    min-width: 25% !important;
  }

  .xl\:minw-50 {
    min-width: 50% !important;
  }

  .xl\:minw-66 {
    min-width: 66.6% !important;
  }

  .xl\:minw-75 {
    min-width: 75% !important;
  }

  .xl\:minw-100 {
    min-width: 100% !important;
  }

  .xl\:minw-100px {
    min-width: 100px !important;
  }

  .xl\:minw-150px {
    min-width: 150px !important;
  }

  .xl\:minw-200px {
    min-width: 200px !important;
  }

  .xl\:minw-250px {
    min-width: 250px !important;
  }

  .xl\:minw-300px {
    min-width: 300px !important;
  }

  .xl\:minw-350px {
    min-width: 350px !important;
  }

  .xl\:minw-400px {
    min-width: 400px !important;
  }

  .xl\:minw-450px {
    min-width: 450px !important;
  }

  .xl\:minw-500px {
    min-width: 500px !important;
  }

  .xl\:minw-550px {
    min-width: 550px !important;
  }

  .xl\:minw-600px {
    min-width: 600px !important;
  }

  .xl\:minw-650px {
    min-width: 650px !important;
  }

  .xl\:minw-700px {
    min-width: 700px !important;
  }

  .xl\:minw-750px {
    min-width: 750px !important;
  }

  .xl\:minw-800px {
    min-width: 800px !important;
  }

  .xl\:minw-900px {
    min-width: 900px !important;
  }

  .xl\:minw-950px {
    min-width: 950px !important;
  }

  .xl\:minw-1000px {
    min-width: 1000px !important;
  }

  .xl\:minw-1100px {
    min-width: 1100px !important;
  }

  .xl\:minw-1200px {
    min-width: 1200px !important;
  }
}
.mw-auto {
  max-width: auto !important;
}

.mw-25 {
  max-width: 25% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-66 {
  max-width: 66.6% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-100px {
  max-width: 100px !important;
}

.mw-150px {
  max-width: 150px !important;
}

.mw-200px {
  max-width: 200px !important;
}

.mw-250px {
  max-width: 250px !important;
}

.mw-300px {
  max-width: 300px !important;
}

.mw-350px {
  max-width: 350px !important;
}

.mw-400px {
  max-width: 400px !important;
}

.mw-450px {
  max-width: 450px !important;
}

.mw-500px {
  max-width: 500px !important;
}

.mw-550px {
  max-width: 550px !important;
}

.mw-600px {
  max-width: 600px !important;
}

.mw-650px {
  max-width: 650px !important;
}

.mw-700px {
  max-width: 700px !important;
}

.mw-750px {
  max-width: 750px !important;
}

.mw-800px {
  max-width: 800px !important;
}

.mw-900px {
  max-width: 900px !important;
}

.mw-950px {
  max-width: 950px !important;
}

.mw-1000px {
  max-width: 1000px !important;
}

.mw-1100px {
  max-width: 1100px !important;
}

.mw-1200px {
  max-width: 1200px !important;
}

.mw-none {
  max-width: none !important;
}

@media (min-width: 600px) {
  .s\:mw-auto {
    max-width: auto !important;
  }

  .s\:mw-25 {
    max-width: 25% !important;
  }

  .s\:mw-50 {
    max-width: 50% !important;
  }

  .s\:mw-66 {
    max-width: 66.6% !important;
  }

  .s\:mw-75 {
    max-width: 75% !important;
  }

  .s\:mw-100 {
    max-width: 100% !important;
  }

  .s\:mw-100px {
    max-width: 100px !important;
  }

  .s\:mw-150px {
    max-width: 150px !important;
  }

  .s\:mw-200px {
    max-width: 200px !important;
  }

  .s\:mw-250px {
    max-width: 250px !important;
  }

  .s\:mw-300px {
    max-width: 300px !important;
  }

  .s\:mw-350px {
    max-width: 350px !important;
  }

  .s\:mw-400px {
    max-width: 400px !important;
  }

  .s\:mw-450px {
    max-width: 450px !important;
  }

  .s\:mw-500px {
    max-width: 500px !important;
  }

  .s\:mw-550px {
    max-width: 550px !important;
  }

  .s\:mw-600px {
    max-width: 600px !important;
  }

  .s\:mw-650px {
    max-width: 650px !important;
  }

  .s\:mw-700px {
    max-width: 700px !important;
  }

  .s\:mw-750px {
    max-width: 750px !important;
  }

  .s\:mw-800px {
    max-width: 800px !important;
  }

  .s\:mw-900px {
    max-width: 900px !important;
  }

  .s\:mw-950px {
    max-width: 950px !important;
  }

  .s\:mw-1000px {
    max-width: 1000px !important;
  }

  .s\:mw-1100px {
    max-width: 1100px !important;
  }

  .s\:mw-1200px {
    max-width: 1200px !important;
  }

  .s\:mw-none {
    max-width: none !important;
  }
}
@media (min-width: 900px) {
  .m\:mw-auto {
    max-width: auto !important;
  }

  .m\:mw-25 {
    max-width: 25% !important;
  }

  .m\:mw-50 {
    max-width: 50% !important;
  }

  .m\:mw-66 {
    max-width: 66.6% !important;
  }

  .m\:mw-75 {
    max-width: 75% !important;
  }

  .m\:mw-100 {
    max-width: 100% !important;
  }

  .m\:mw-100px {
    max-width: 100px !important;
  }

  .m\:mw-150px {
    max-width: 150px !important;
  }

  .m\:mw-200px {
    max-width: 200px !important;
  }

  .m\:mw-250px {
    max-width: 250px !important;
  }

  .m\:mw-300px {
    max-width: 300px !important;
  }

  .m\:mw-350px {
    max-width: 350px !important;
  }

  .m\:mw-400px {
    max-width: 400px !important;
  }

  .m\:mw-450px {
    max-width: 450px !important;
  }

  .m\:mw-500px {
    max-width: 500px !important;
  }

  .m\:mw-550px {
    max-width: 550px !important;
  }

  .m\:mw-600px {
    max-width: 600px !important;
  }

  .m\:mw-650px {
    max-width: 650px !important;
  }

  .m\:mw-700px {
    max-width: 700px !important;
  }

  .m\:mw-750px {
    max-width: 750px !important;
  }

  .m\:mw-800px {
    max-width: 800px !important;
  }

  .m\:mw-900px {
    max-width: 900px !important;
  }

  .m\:mw-950px {
    max-width: 950px !important;
  }

  .m\:mw-1000px {
    max-width: 1000px !important;
  }

  .m\:mw-1100px {
    max-width: 1100px !important;
  }

  .m\:mw-1200px {
    max-width: 1200px !important;
  }

  .m\:mw-none {
    max-width: none !important;
  }
}
@media (min-width: 1200px) {
  .l\:mw-auto {
    max-width: auto !important;
  }

  .l\:mw-25 {
    max-width: 25% !important;
  }

  .l\:mw-50 {
    max-width: 50% !important;
  }

  .l\:mw-66 {
    max-width: 66.6% !important;
  }

  .l\:mw-75 {
    max-width: 75% !important;
  }

  .l\:mw-100 {
    max-width: 100% !important;
  }

  .l\:mw-100px {
    max-width: 100px !important;
  }

  .l\:mw-150px {
    max-width: 150px !important;
  }

  .l\:mw-200px {
    max-width: 200px !important;
  }

  .l\:mw-250px {
    max-width: 250px !important;
  }

  .l\:mw-300px {
    max-width: 300px !important;
  }

  .l\:mw-350px {
    max-width: 350px !important;
  }

  .l\:mw-400px {
    max-width: 400px !important;
  }

  .l\:mw-450px {
    max-width: 450px !important;
  }

  .l\:mw-500px {
    max-width: 500px !important;
  }

  .l\:mw-550px {
    max-width: 550px !important;
  }

  .l\:mw-600px {
    max-width: 600px !important;
  }

  .l\:mw-650px {
    max-width: 650px !important;
  }

  .l\:mw-700px {
    max-width: 700px !important;
  }

  .l\:mw-750px {
    max-width: 750px !important;
  }

  .l\:mw-800px {
    max-width: 800px !important;
  }

  .l\:mw-900px {
    max-width: 900px !important;
  }

  .l\:mw-950px {
    max-width: 950px !important;
  }

  .l\:mw-1000px {
    max-width: 1000px !important;
  }

  .l\:mw-1100px {
    max-width: 1100px !important;
  }

  .l\:mw-1200px {
    max-width: 1200px !important;
  }

  .l\:mw-none {
    max-width: none !important;
  }
}
@media (min-width: 1600px) {
  .xl\:mw-auto {
    max-width: auto !important;
  }

  .xl\:mw-25 {
    max-width: 25% !important;
  }

  .xl\:mw-50 {
    max-width: 50% !important;
  }

  .xl\:mw-66 {
    max-width: 66.6% !important;
  }

  .xl\:mw-75 {
    max-width: 75% !important;
  }

  .xl\:mw-100 {
    max-width: 100% !important;
  }

  .xl\:mw-100px {
    max-width: 100px !important;
  }

  .xl\:mw-150px {
    max-width: 150px !important;
  }

  .xl\:mw-200px {
    max-width: 200px !important;
  }

  .xl\:mw-250px {
    max-width: 250px !important;
  }

  .xl\:mw-300px {
    max-width: 300px !important;
  }

  .xl\:mw-350px {
    max-width: 350px !important;
  }

  .xl\:mw-400px {
    max-width: 400px !important;
  }

  .xl\:mw-450px {
    max-width: 450px !important;
  }

  .xl\:mw-500px {
    max-width: 500px !important;
  }

  .xl\:mw-550px {
    max-width: 550px !important;
  }

  .xl\:mw-600px {
    max-width: 600px !important;
  }

  .xl\:mw-650px {
    max-width: 650px !important;
  }

  .xl\:mw-700px {
    max-width: 700px !important;
  }

  .xl\:mw-750px {
    max-width: 750px !important;
  }

  .xl\:mw-800px {
    max-width: 800px !important;
  }

  .xl\:mw-900px {
    max-width: 900px !important;
  }

  .xl\:mw-950px {
    max-width: 950px !important;
  }

  .xl\:mw-1000px {
    max-width: 1000px !important;
  }

  .xl\:mw-1100px {
    max-width: 1100px !important;
  }

  .xl\:mw-1200px {
    max-width: 1200px !important;
  }

  .xl\:mw-none {
    max-width: none !important;
  }
}
.mw-10ch {
  max-width: 10ch !important;
}

.mw-15ch {
  max-width: 15ch !important;
}

.mw-20ch {
  max-width: 20ch !important;
}

.mw-25ch {
  max-width: 25ch !important;
}

.mw-30ch {
  max-width: 30ch !important;
}

.mw-35ch {
  max-width: 35ch !important;
}

.mw-40ch {
  max-width: 40ch !important;
}

.mw-45ch {
  max-width: 45ch !important;
}

.mw-50ch {
  max-width: 50ch !important;
}

.mw-55ch {
  max-width: 55ch !important;
}

.mw-60ch {
  max-width: 60ch !important;
}

.mw-65ch {
  max-width: 65ch !important;
}

.mw-70ch {
  max-width: 70ch !important;
}

.mw-75ch {
  max-width: 75ch !important;
}

.mw-80ch {
  max-width: 80ch !important;
}

@media (min-width: 600px) {
  .s\:mw-10ch {
    max-width: 10ch !important;
  }

  .s\:mw-15ch {
    max-width: 15ch !important;
  }

  .s\:mw-20ch {
    max-width: 20ch !important;
  }

  .s\:mw-25ch {
    max-width: 25ch !important;
  }

  .s\:mw-30ch {
    max-width: 30ch !important;
  }

  .s\:mw-35ch {
    max-width: 35ch !important;
  }

  .s\:mw-40ch {
    max-width: 40ch !important;
  }

  .s\:mw-45ch {
    max-width: 45ch !important;
  }

  .s\:mw-50ch {
    max-width: 50ch !important;
  }

  .s\:mw-55ch {
    max-width: 55ch !important;
  }

  .s\:mw-60ch {
    max-width: 60ch !important;
  }

  .s\:mw-65ch {
    max-width: 65ch !important;
  }

  .s\:mw-70ch {
    max-width: 70ch !important;
  }

  .s\:mw-75ch {
    max-width: 75ch !important;
  }

  .s\:mw-80ch {
    max-width: 80ch !important;
  }
}
@media (min-width: 900px) {
  .m\:mw-10ch {
    max-width: 10ch !important;
  }

  .m\:mw-15ch {
    max-width: 15ch !important;
  }

  .m\:mw-20ch {
    max-width: 20ch !important;
  }

  .m\:mw-25ch {
    max-width: 25ch !important;
  }

  .m\:mw-30ch {
    max-width: 30ch !important;
  }

  .m\:mw-35ch {
    max-width: 35ch !important;
  }

  .m\:mw-40ch {
    max-width: 40ch !important;
  }

  .m\:mw-45ch {
    max-width: 45ch !important;
  }

  .m\:mw-50ch {
    max-width: 50ch !important;
  }

  .m\:mw-55ch {
    max-width: 55ch !important;
  }

  .m\:mw-60ch {
    max-width: 60ch !important;
  }

  .m\:mw-65ch {
    max-width: 65ch !important;
  }

  .m\:mw-70ch {
    max-width: 70ch !important;
  }

  .m\:mw-75ch {
    max-width: 75ch !important;
  }

  .m\:mw-80ch {
    max-width: 80ch !important;
  }
}
@media (min-width: 1200px) {
  .l\:mw-10ch {
    max-width: 10ch !important;
  }

  .l\:mw-15ch {
    max-width: 15ch !important;
  }

  .l\:mw-20ch {
    max-width: 20ch !important;
  }

  .l\:mw-25ch {
    max-width: 25ch !important;
  }

  .l\:mw-30ch {
    max-width: 30ch !important;
  }

  .l\:mw-35ch {
    max-width: 35ch !important;
  }

  .l\:mw-40ch {
    max-width: 40ch !important;
  }

  .l\:mw-45ch {
    max-width: 45ch !important;
  }

  .l\:mw-50ch {
    max-width: 50ch !important;
  }

  .l\:mw-55ch {
    max-width: 55ch !important;
  }

  .l\:mw-60ch {
    max-width: 60ch !important;
  }

  .l\:mw-65ch {
    max-width: 65ch !important;
  }

  .l\:mw-70ch {
    max-width: 70ch !important;
  }

  .l\:mw-75ch {
    max-width: 75ch !important;
  }

  .l\:mw-80ch {
    max-width: 80ch !important;
  }
}
@media (min-width: 1600px) {
  .xl\:mw-10ch {
    max-width: 10ch !important;
  }

  .xl\:mw-15ch {
    max-width: 15ch !important;
  }

  .xl\:mw-20ch {
    max-width: 20ch !important;
  }

  .xl\:mw-25ch {
    max-width: 25ch !important;
  }

  .xl\:mw-30ch {
    max-width: 30ch !important;
  }

  .xl\:mw-35ch {
    max-width: 35ch !important;
  }

  .xl\:mw-40ch {
    max-width: 40ch !important;
  }

  .xl\:mw-45ch {
    max-width: 45ch !important;
  }

  .xl\:mw-50ch {
    max-width: 50ch !important;
  }

  .xl\:mw-55ch {
    max-width: 55ch !important;
  }

  .xl\:mw-60ch {
    max-width: 60ch !important;
  }

  .xl\:mw-65ch {
    max-width: 65ch !important;
  }

  .xl\:mw-70ch {
    max-width: 70ch !important;
  }

  .xl\:mw-75ch {
    max-width: 75ch !important;
  }

  .xl\:mw-80ch {
    max-width: 80ch !important;
  }
}
.h-100 {
  height: 100% !important;
}

[class*=gx-] {
  column-gap: var(--gap-x);
}

[class*=gy-] {
  row-gap: var(--gap-y);
}

.gap-x-0 {
  --gap-x: 0 !important;
}

.gap-x-1 {
  --gap-x: 0.25rem !important;
}

.gap-x-2 {
  --gap-x: 0.5rem !important;
}

.gap-x-3 {
  --gap-x: 0.75rem !important;
}

.gap-x-4 {
  --gap-x: 1rem !important;
}

.gap-x-5 {
  --gap-x: 1.25rem !important;
}

.gap-x-6 {
  --gap-x: 1.5rem !important;
}

.gap-x-8 {
  --gap-x: 2rem !important;
}

.gap-x-10 {
  --gap-x: 2.5rem !important;
}

.gap-x-12 {
  --gap-x: 3rem !important;
}

.gap-x-14 {
  --gap-x: 3.5rem !important;
}

.gap-x-16 {
  --gap-x: 4rem !important;
}

.gap-x-20 {
  --gap-x: 5rem !important;
}

.gap-x-24 {
  --gap-x: 6rem !important;
}

.gap-x-32 {
  --gap-x: 8rem !important;
}

.gap-x-40 {
  --gap-x: 10rem !important;
}

.gap-x-1em {
  --gap-x: 1em !important;
}

@media (min-width: 600px) {
  .s\:gap-x-0 {
    --gap-x: 0 !important;
  }

  .s\:gap-x-1 {
    --gap-x: 0.25rem !important;
  }

  .s\:gap-x-2 {
    --gap-x: 0.5rem !important;
  }

  .s\:gap-x-3 {
    --gap-x: 0.75rem !important;
  }

  .s\:gap-x-4 {
    --gap-x: 1rem !important;
  }

  .s\:gap-x-5 {
    --gap-x: 1.25rem !important;
  }

  .s\:gap-x-6 {
    --gap-x: 1.5rem !important;
  }

  .s\:gap-x-8 {
    --gap-x: 2rem !important;
  }

  .s\:gap-x-10 {
    --gap-x: 2.5rem !important;
  }

  .s\:gap-x-12 {
    --gap-x: 3rem !important;
  }

  .s\:gap-x-14 {
    --gap-x: 3.5rem !important;
  }

  .s\:gap-x-16 {
    --gap-x: 4rem !important;
  }

  .s\:gap-x-20 {
    --gap-x: 5rem !important;
  }

  .s\:gap-x-24 {
    --gap-x: 6rem !important;
  }

  .s\:gap-x-32 {
    --gap-x: 8rem !important;
  }

  .s\:gap-x-40 {
    --gap-x: 10rem !important;
  }

  .s\:gap-x-1em {
    --gap-x: 1em !important;
  }
}
@media (min-width: 900px) {
  .m\:gap-x-0 {
    --gap-x: 0 !important;
  }

  .m\:gap-x-1 {
    --gap-x: 0.25rem !important;
  }

  .m\:gap-x-2 {
    --gap-x: 0.5rem !important;
  }

  .m\:gap-x-3 {
    --gap-x: 0.75rem !important;
  }

  .m\:gap-x-4 {
    --gap-x: 1rem !important;
  }

  .m\:gap-x-5 {
    --gap-x: 1.25rem !important;
  }

  .m\:gap-x-6 {
    --gap-x: 1.5rem !important;
  }

  .m\:gap-x-8 {
    --gap-x: 2rem !important;
  }

  .m\:gap-x-10 {
    --gap-x: 2.5rem !important;
  }

  .m\:gap-x-12 {
    --gap-x: 3rem !important;
  }

  .m\:gap-x-14 {
    --gap-x: 3.5rem !important;
  }

  .m\:gap-x-16 {
    --gap-x: 4rem !important;
  }

  .m\:gap-x-20 {
    --gap-x: 5rem !important;
  }

  .m\:gap-x-24 {
    --gap-x: 6rem !important;
  }

  .m\:gap-x-32 {
    --gap-x: 8rem !important;
  }

  .m\:gap-x-40 {
    --gap-x: 10rem !important;
  }

  .m\:gap-x-1em {
    --gap-x: 1em !important;
  }
}
@media (min-width: 1200px) {
  .l\:gap-x-0 {
    --gap-x: 0 !important;
  }

  .l\:gap-x-1 {
    --gap-x: 0.25rem !important;
  }

  .l\:gap-x-2 {
    --gap-x: 0.5rem !important;
  }

  .l\:gap-x-3 {
    --gap-x: 0.75rem !important;
  }

  .l\:gap-x-4 {
    --gap-x: 1rem !important;
  }

  .l\:gap-x-5 {
    --gap-x: 1.25rem !important;
  }

  .l\:gap-x-6 {
    --gap-x: 1.5rem !important;
  }

  .l\:gap-x-8 {
    --gap-x: 2rem !important;
  }

  .l\:gap-x-10 {
    --gap-x: 2.5rem !important;
  }

  .l\:gap-x-12 {
    --gap-x: 3rem !important;
  }

  .l\:gap-x-14 {
    --gap-x: 3.5rem !important;
  }

  .l\:gap-x-16 {
    --gap-x: 4rem !important;
  }

  .l\:gap-x-20 {
    --gap-x: 5rem !important;
  }

  .l\:gap-x-24 {
    --gap-x: 6rem !important;
  }

  .l\:gap-x-32 {
    --gap-x: 8rem !important;
  }

  .l\:gap-x-40 {
    --gap-x: 10rem !important;
  }

  .l\:gap-x-1em {
    --gap-x: 1em !important;
  }
}
@media (min-width: 1600px) {
  .xl\:gap-x-0 {
    --gap-x: 0 !important;
  }

  .xl\:gap-x-1 {
    --gap-x: 0.25rem !important;
  }

  .xl\:gap-x-2 {
    --gap-x: 0.5rem !important;
  }

  .xl\:gap-x-3 {
    --gap-x: 0.75rem !important;
  }

  .xl\:gap-x-4 {
    --gap-x: 1rem !important;
  }

  .xl\:gap-x-5 {
    --gap-x: 1.25rem !important;
  }

  .xl\:gap-x-6 {
    --gap-x: 1.5rem !important;
  }

  .xl\:gap-x-8 {
    --gap-x: 2rem !important;
  }

  .xl\:gap-x-10 {
    --gap-x: 2.5rem !important;
  }

  .xl\:gap-x-12 {
    --gap-x: 3rem !important;
  }

  .xl\:gap-x-14 {
    --gap-x: 3.5rem !important;
  }

  .xl\:gap-x-16 {
    --gap-x: 4rem !important;
  }

  .xl\:gap-x-20 {
    --gap-x: 5rem !important;
  }

  .xl\:gap-x-24 {
    --gap-x: 6rem !important;
  }

  .xl\:gap-x-32 {
    --gap-x: 8rem !important;
  }

  .xl\:gap-x-40 {
    --gap-x: 10rem !important;
  }

  .xl\:gap-x-1em {
    --gap-x: 1em !important;
  }
}
.gap-y-0 {
  --gap-y: 0 !important;
}

.gap-y-1 {
  --gap-y: 0.25rem !important;
}

.gap-y-2 {
  --gap-y: 0.5rem !important;
}

.gap-y-3 {
  --gap-y: 0.75rem !important;
}

.gap-y-4 {
  --gap-y: 1rem !important;
}

.gap-y-5 {
  --gap-y: 1.25rem !important;
}

.gap-y-6 {
  --gap-y: 1.5rem !important;
}

.gap-y-8 {
  --gap-y: 2rem !important;
}

.gap-y-10 {
  --gap-y: 2.5rem !important;
}

.gap-y-12 {
  --gap-y: 3rem !important;
}

.gap-y-14 {
  --gap-y: 3.5rem !important;
}

.gap-y-16 {
  --gap-y: 4rem !important;
}

.gap-y-20 {
  --gap-y: 5rem !important;
}

.gap-y-24 {
  --gap-y: 6rem !important;
}

.gap-y-32 {
  --gap-y: 8rem !important;
}

.gap-y-40 {
  --gap-y: 10rem !important;
}

.gap-y-1em {
  --gap-y: 1em !important;
}

@media (min-width: 600px) {
  .s\:gap-y-0 {
    --gap-y: 0 !important;
  }

  .s\:gap-y-1 {
    --gap-y: 0.25rem !important;
  }

  .s\:gap-y-2 {
    --gap-y: 0.5rem !important;
  }

  .s\:gap-y-3 {
    --gap-y: 0.75rem !important;
  }

  .s\:gap-y-4 {
    --gap-y: 1rem !important;
  }

  .s\:gap-y-5 {
    --gap-y: 1.25rem !important;
  }

  .s\:gap-y-6 {
    --gap-y: 1.5rem !important;
  }

  .s\:gap-y-8 {
    --gap-y: 2rem !important;
  }

  .s\:gap-y-10 {
    --gap-y: 2.5rem !important;
  }

  .s\:gap-y-12 {
    --gap-y: 3rem !important;
  }

  .s\:gap-y-14 {
    --gap-y: 3.5rem !important;
  }

  .s\:gap-y-16 {
    --gap-y: 4rem !important;
  }

  .s\:gap-y-20 {
    --gap-y: 5rem !important;
  }

  .s\:gap-y-24 {
    --gap-y: 6rem !important;
  }

  .s\:gap-y-32 {
    --gap-y: 8rem !important;
  }

  .s\:gap-y-40 {
    --gap-y: 10rem !important;
  }

  .s\:gap-y-1em {
    --gap-y: 1em !important;
  }
}
@media (min-width: 900px) {
  .m\:gap-y-0 {
    --gap-y: 0 !important;
  }

  .m\:gap-y-1 {
    --gap-y: 0.25rem !important;
  }

  .m\:gap-y-2 {
    --gap-y: 0.5rem !important;
  }

  .m\:gap-y-3 {
    --gap-y: 0.75rem !important;
  }

  .m\:gap-y-4 {
    --gap-y: 1rem !important;
  }

  .m\:gap-y-5 {
    --gap-y: 1.25rem !important;
  }

  .m\:gap-y-6 {
    --gap-y: 1.5rem !important;
  }

  .m\:gap-y-8 {
    --gap-y: 2rem !important;
  }

  .m\:gap-y-10 {
    --gap-y: 2.5rem !important;
  }

  .m\:gap-y-12 {
    --gap-y: 3rem !important;
  }

  .m\:gap-y-14 {
    --gap-y: 3.5rem !important;
  }

  .m\:gap-y-16 {
    --gap-y: 4rem !important;
  }

  .m\:gap-y-20 {
    --gap-y: 5rem !important;
  }

  .m\:gap-y-24 {
    --gap-y: 6rem !important;
  }

  .m\:gap-y-32 {
    --gap-y: 8rem !important;
  }

  .m\:gap-y-40 {
    --gap-y: 10rem !important;
  }

  .m\:gap-y-1em {
    --gap-y: 1em !important;
  }
}
@media (min-width: 1200px) {
  .l\:gap-y-0 {
    --gap-y: 0 !important;
  }

  .l\:gap-y-1 {
    --gap-y: 0.25rem !important;
  }

  .l\:gap-y-2 {
    --gap-y: 0.5rem !important;
  }

  .l\:gap-y-3 {
    --gap-y: 0.75rem !important;
  }

  .l\:gap-y-4 {
    --gap-y: 1rem !important;
  }

  .l\:gap-y-5 {
    --gap-y: 1.25rem !important;
  }

  .l\:gap-y-6 {
    --gap-y: 1.5rem !important;
  }

  .l\:gap-y-8 {
    --gap-y: 2rem !important;
  }

  .l\:gap-y-10 {
    --gap-y: 2.5rem !important;
  }

  .l\:gap-y-12 {
    --gap-y: 3rem !important;
  }

  .l\:gap-y-14 {
    --gap-y: 3.5rem !important;
  }

  .l\:gap-y-16 {
    --gap-y: 4rem !important;
  }

  .l\:gap-y-20 {
    --gap-y: 5rem !important;
  }

  .l\:gap-y-24 {
    --gap-y: 6rem !important;
  }

  .l\:gap-y-32 {
    --gap-y: 8rem !important;
  }

  .l\:gap-y-40 {
    --gap-y: 10rem !important;
  }

  .l\:gap-y-1em {
    --gap-y: 1em !important;
  }
}
@media (min-width: 1600px) {
  .xl\:gap-y-0 {
    --gap-y: 0 !important;
  }

  .xl\:gap-y-1 {
    --gap-y: 0.25rem !important;
  }

  .xl\:gap-y-2 {
    --gap-y: 0.5rem !important;
  }

  .xl\:gap-y-3 {
    --gap-y: 0.75rem !important;
  }

  .xl\:gap-y-4 {
    --gap-y: 1rem !important;
  }

  .xl\:gap-y-5 {
    --gap-y: 1.25rem !important;
  }

  .xl\:gap-y-6 {
    --gap-y: 1.5rem !important;
  }

  .xl\:gap-y-8 {
    --gap-y: 2rem !important;
  }

  .xl\:gap-y-10 {
    --gap-y: 2.5rem !important;
  }

  .xl\:gap-y-12 {
    --gap-y: 3rem !important;
  }

  .xl\:gap-y-14 {
    --gap-y: 3.5rem !important;
  }

  .xl\:gap-y-16 {
    --gap-y: 4rem !important;
  }

  .xl\:gap-y-20 {
    --gap-y: 5rem !important;
  }

  .xl\:gap-y-24 {
    --gap-y: 6rem !important;
  }

  .xl\:gap-y-32 {
    --gap-y: 8rem !important;
  }

  .xl\:gap-y-40 {
    --gap-y: 10rem !important;
  }

  .xl\:gap-y-1em {
    --gap-y: 1em !important;
  }
}
[class*=translate-] {
  transform: translateX(var(--translateX, 0px)) translateY(var(--translateY, 0px));
}

.translate-x-50 {
  --translateX: -50% !important;
}

@media (min-width: 600px) {
  .s\:translate-x-50 {
    --translateX: -50% !important;
  }
}
@media (min-width: 900px) {
  .m\:translate-x-50 {
    --translateX: -50% !important;
  }
}
@media (min-width: 1200px) {
  .l\:translate-x-50 {
    --translateX: -50% !important;
  }
}
@media (min-width: 1600px) {
  .xl\:translate-x-50 {
    --translateX: -50% !important;
  }
}
.border {
  border: 1px solid #e5e7f1;
}
.border-top {
  border-top: 1px solid #e5e7f1;
}
.border-bottom {
  border-bottom: 1px solid #e5e7f1;
}
.border-white {
  border: 1px solid #fff;
}
.border-2px {
  border-width: 2px;
}

.rounded-s {
  border-radius: 0.25rem !important;
}

.rounded-m {
  border-radius: 0.375rem !important;
}

.rounded-l {
  border-radius: 0.75rem !important;
}

.rounded-xl {
  border-radius: 1.25rem !important;
}

.rounded-2xl {
  border-radius: 2rem !important;
}

.rounded-3xl {
  border-radius: 2.5rem !important;
}

.rounded-circle {
  border-radius: 9999px !important;
}

.relative {
  position: relative;
}

.zindex-1 {
  z-index: 1;
}
.zindex-2 {
  z-index: 2;
}
.zindex-3 {
  z-index: 3;
}

.overflow-hidden {
  overflow: hidden;
}

.mt-multiply-0 {
  --margin-multiplier-top: 0 !important;
}

.mt-multiply-025 {
  --margin-multiplier-top: 0.25 !important;
}

.mt-multiply-05 {
  --margin-multiplier-top: 0.5 !important;
}

.mt-multiply-075 {
  --margin-multiplier-top: 0.75 !important;
}

.mt-multiply-1 {
  --margin-multiplier-top: 1 !important;
}

.mt-multiply-125 {
  --margin-multiplier-top: 1.25 !important;
}

.mt-multiply-15 {
  --margin-multiplier-top: 1.5 !important;
}

.mt-multiply-175 {
  --margin-multiplier-top: 1.75 !important;
}

.mt-multiply-2 {
  --margin-multiplier-top: 2 !important;
}

@media (min-width: 600px) {
  .s\:mt-multiply-0 {
    --margin-multiplier-top: 0 !important;
  }

  .s\:mt-multiply-025 {
    --margin-multiplier-top: 0.25 !important;
  }

  .s\:mt-multiply-05 {
    --margin-multiplier-top: 0.5 !important;
  }

  .s\:mt-multiply-075 {
    --margin-multiplier-top: 0.75 !important;
  }

  .s\:mt-multiply-1 {
    --margin-multiplier-top: 1 !important;
  }

  .s\:mt-multiply-125 {
    --margin-multiplier-top: 1.25 !important;
  }

  .s\:mt-multiply-15 {
    --margin-multiplier-top: 1.5 !important;
  }

  .s\:mt-multiply-175 {
    --margin-multiplier-top: 1.75 !important;
  }

  .s\:mt-multiply-2 {
    --margin-multiplier-top: 2 !important;
  }
}
@media (min-width: 900px) {
  .m\:mt-multiply-0 {
    --margin-multiplier-top: 0 !important;
  }

  .m\:mt-multiply-025 {
    --margin-multiplier-top: 0.25 !important;
  }

  .m\:mt-multiply-05 {
    --margin-multiplier-top: 0.5 !important;
  }

  .m\:mt-multiply-075 {
    --margin-multiplier-top: 0.75 !important;
  }

  .m\:mt-multiply-1 {
    --margin-multiplier-top: 1 !important;
  }

  .m\:mt-multiply-125 {
    --margin-multiplier-top: 1.25 !important;
  }

  .m\:mt-multiply-15 {
    --margin-multiplier-top: 1.5 !important;
  }

  .m\:mt-multiply-175 {
    --margin-multiplier-top: 1.75 !important;
  }

  .m\:mt-multiply-2 {
    --margin-multiplier-top: 2 !important;
  }
}
@media (min-width: 1200px) {
  .l\:mt-multiply-0 {
    --margin-multiplier-top: 0 !important;
  }

  .l\:mt-multiply-025 {
    --margin-multiplier-top: 0.25 !important;
  }

  .l\:mt-multiply-05 {
    --margin-multiplier-top: 0.5 !important;
  }

  .l\:mt-multiply-075 {
    --margin-multiplier-top: 0.75 !important;
  }

  .l\:mt-multiply-1 {
    --margin-multiplier-top: 1 !important;
  }

  .l\:mt-multiply-125 {
    --margin-multiplier-top: 1.25 !important;
  }

  .l\:mt-multiply-15 {
    --margin-multiplier-top: 1.5 !important;
  }

  .l\:mt-multiply-175 {
    --margin-multiplier-top: 1.75 !important;
  }

  .l\:mt-multiply-2 {
    --margin-multiplier-top: 2 !important;
  }
}
@media (min-width: 1600px) {
  .xl\:mt-multiply-0 {
    --margin-multiplier-top: 0 !important;
  }

  .xl\:mt-multiply-025 {
    --margin-multiplier-top: 0.25 !important;
  }

  .xl\:mt-multiply-05 {
    --margin-multiplier-top: 0.5 !important;
  }

  .xl\:mt-multiply-075 {
    --margin-multiplier-top: 0.75 !important;
  }

  .xl\:mt-multiply-1 {
    --margin-multiplier-top: 1 !important;
  }

  .xl\:mt-multiply-125 {
    --margin-multiplier-top: 1.25 !important;
  }

  .xl\:mt-multiply-15 {
    --margin-multiplier-top: 1.5 !important;
  }

  .xl\:mt-multiply-175 {
    --margin-multiplier-top: 1.75 !important;
  }

  .xl\:mt-multiply-2 {
    --margin-multiplier-top: 2 !important;
  }
}
.mb-multiply-0 {
  --margin-multiplier-bottom: 0 !important;
}

.mb-multiply-025 {
  --margin-multiplier-bottom: 0.25 !important;
}

.mb-multiply-05 {
  --margin-multiplier-bottom: 0.5 !important;
}

.mb-multiply-075 {
  --margin-multiplier-bottom: 0.75 !important;
}

.mb-multiply-1 {
  --margin-multiplier-bottom: 1 !important;
}

.mb-multiply-125 {
  --margin-multiplier-bottom: 1.25 !important;
}

.mb-multiply-15 {
  --margin-multiplier-bottom: 1.5 !important;
}

.mb-multiply-175 {
  --margin-multiplier-bottom: 1.75 !important;
}

.mb-multiply-2 {
  --margin-multiplier-bottom: 2 !important;
}

@media (min-width: 600px) {
  .s\:mb-multiply-0 {
    --margin-multiplier-bottom: 0 !important;
  }

  .s\:mb-multiply-025 {
    --margin-multiplier-bottom: 0.25 !important;
  }

  .s\:mb-multiply-05 {
    --margin-multiplier-bottom: 0.5 !important;
  }

  .s\:mb-multiply-075 {
    --margin-multiplier-bottom: 0.75 !important;
  }

  .s\:mb-multiply-1 {
    --margin-multiplier-bottom: 1 !important;
  }

  .s\:mb-multiply-125 {
    --margin-multiplier-bottom: 1.25 !important;
  }

  .s\:mb-multiply-15 {
    --margin-multiplier-bottom: 1.5 !important;
  }

  .s\:mb-multiply-175 {
    --margin-multiplier-bottom: 1.75 !important;
  }

  .s\:mb-multiply-2 {
    --margin-multiplier-bottom: 2 !important;
  }
}
@media (min-width: 900px) {
  .m\:mb-multiply-0 {
    --margin-multiplier-bottom: 0 !important;
  }

  .m\:mb-multiply-025 {
    --margin-multiplier-bottom: 0.25 !important;
  }

  .m\:mb-multiply-05 {
    --margin-multiplier-bottom: 0.5 !important;
  }

  .m\:mb-multiply-075 {
    --margin-multiplier-bottom: 0.75 !important;
  }

  .m\:mb-multiply-1 {
    --margin-multiplier-bottom: 1 !important;
  }

  .m\:mb-multiply-125 {
    --margin-multiplier-bottom: 1.25 !important;
  }

  .m\:mb-multiply-15 {
    --margin-multiplier-bottom: 1.5 !important;
  }

  .m\:mb-multiply-175 {
    --margin-multiplier-bottom: 1.75 !important;
  }

  .m\:mb-multiply-2 {
    --margin-multiplier-bottom: 2 !important;
  }
}
@media (min-width: 1200px) {
  .l\:mb-multiply-0 {
    --margin-multiplier-bottom: 0 !important;
  }

  .l\:mb-multiply-025 {
    --margin-multiplier-bottom: 0.25 !important;
  }

  .l\:mb-multiply-05 {
    --margin-multiplier-bottom: 0.5 !important;
  }

  .l\:mb-multiply-075 {
    --margin-multiplier-bottom: 0.75 !important;
  }

  .l\:mb-multiply-1 {
    --margin-multiplier-bottom: 1 !important;
  }

  .l\:mb-multiply-125 {
    --margin-multiplier-bottom: 1.25 !important;
  }

  .l\:mb-multiply-15 {
    --margin-multiplier-bottom: 1.5 !important;
  }

  .l\:mb-multiply-175 {
    --margin-multiplier-bottom: 1.75 !important;
  }

  .l\:mb-multiply-2 {
    --margin-multiplier-bottom: 2 !important;
  }
}
@media (min-width: 1600px) {
  .xl\:mb-multiply-0 {
    --margin-multiplier-bottom: 0 !important;
  }

  .xl\:mb-multiply-025 {
    --margin-multiplier-bottom: 0.25 !important;
  }

  .xl\:mb-multiply-05 {
    --margin-multiplier-bottom: 0.5 !important;
  }

  .xl\:mb-multiply-075 {
    --margin-multiplier-bottom: 0.75 !important;
  }

  .xl\:mb-multiply-1 {
    --margin-multiplier-bottom: 1 !important;
  }

  .xl\:mb-multiply-125 {
    --margin-multiplier-bottom: 1.25 !important;
  }

  .xl\:mb-multiply-15 {
    --margin-multiplier-bottom: 1.5 !important;
  }

  .xl\:mb-multiply-175 {
    --margin-multiplier-bottom: 1.75 !important;
  }

  .xl\:mb-multiply-2 {
    --margin-multiplier-bottom: 2 !important;
  }
}
.pt-multiply-0 {
  --padding-multiplier-top: 0 !important;
}

.pt-multiply-025 {
  --padding-multiplier-top: 0.25 !important;
}

.pt-multiply-05 {
  --padding-multiplier-top: 0.5 !important;
}

.pt-multiply-075 {
  --padding-multiplier-top: 0.75 !important;
}

.pt-multiply-1 {
  --padding-multiplier-top: 1 !important;
}

.pt-multiply-125 {
  --padding-multiplier-top: 1.25 !important;
}

.pt-multiply-15 {
  --padding-multiplier-top: 1.5 !important;
}

.pt-multiply-175 {
  --padding-multiplier-top: 1.75 !important;
}

.pt-multiply-2 {
  --padding-multiplier-top: 2 !important;
}

@media (min-width: 600px) {
  .s\:pt-multiply-0 {
    --padding-multiplier-top: 0 !important;
  }

  .s\:pt-multiply-025 {
    --padding-multiplier-top: 0.25 !important;
  }

  .s\:pt-multiply-05 {
    --padding-multiplier-top: 0.5 !important;
  }

  .s\:pt-multiply-075 {
    --padding-multiplier-top: 0.75 !important;
  }

  .s\:pt-multiply-1 {
    --padding-multiplier-top: 1 !important;
  }

  .s\:pt-multiply-125 {
    --padding-multiplier-top: 1.25 !important;
  }

  .s\:pt-multiply-15 {
    --padding-multiplier-top: 1.5 !important;
  }

  .s\:pt-multiply-175 {
    --padding-multiplier-top: 1.75 !important;
  }

  .s\:pt-multiply-2 {
    --padding-multiplier-top: 2 !important;
  }
}
@media (min-width: 900px) {
  .m\:pt-multiply-0 {
    --padding-multiplier-top: 0 !important;
  }

  .m\:pt-multiply-025 {
    --padding-multiplier-top: 0.25 !important;
  }

  .m\:pt-multiply-05 {
    --padding-multiplier-top: 0.5 !important;
  }

  .m\:pt-multiply-075 {
    --padding-multiplier-top: 0.75 !important;
  }

  .m\:pt-multiply-1 {
    --padding-multiplier-top: 1 !important;
  }

  .m\:pt-multiply-125 {
    --padding-multiplier-top: 1.25 !important;
  }

  .m\:pt-multiply-15 {
    --padding-multiplier-top: 1.5 !important;
  }

  .m\:pt-multiply-175 {
    --padding-multiplier-top: 1.75 !important;
  }

  .m\:pt-multiply-2 {
    --padding-multiplier-top: 2 !important;
  }
}
@media (min-width: 1200px) {
  .l\:pt-multiply-0 {
    --padding-multiplier-top: 0 !important;
  }

  .l\:pt-multiply-025 {
    --padding-multiplier-top: 0.25 !important;
  }

  .l\:pt-multiply-05 {
    --padding-multiplier-top: 0.5 !important;
  }

  .l\:pt-multiply-075 {
    --padding-multiplier-top: 0.75 !important;
  }

  .l\:pt-multiply-1 {
    --padding-multiplier-top: 1 !important;
  }

  .l\:pt-multiply-125 {
    --padding-multiplier-top: 1.25 !important;
  }

  .l\:pt-multiply-15 {
    --padding-multiplier-top: 1.5 !important;
  }

  .l\:pt-multiply-175 {
    --padding-multiplier-top: 1.75 !important;
  }

  .l\:pt-multiply-2 {
    --padding-multiplier-top: 2 !important;
  }
}
@media (min-width: 1600px) {
  .xl\:pt-multiply-0 {
    --padding-multiplier-top: 0 !important;
  }

  .xl\:pt-multiply-025 {
    --padding-multiplier-top: 0.25 !important;
  }

  .xl\:pt-multiply-05 {
    --padding-multiplier-top: 0.5 !important;
  }

  .xl\:pt-multiply-075 {
    --padding-multiplier-top: 0.75 !important;
  }

  .xl\:pt-multiply-1 {
    --padding-multiplier-top: 1 !important;
  }

  .xl\:pt-multiply-125 {
    --padding-multiplier-top: 1.25 !important;
  }

  .xl\:pt-multiply-15 {
    --padding-multiplier-top: 1.5 !important;
  }

  .xl\:pt-multiply-175 {
    --padding-multiplier-top: 1.75 !important;
  }

  .xl\:pt-multiply-2 {
    --padding-multiplier-top: 2 !important;
  }
}
.pb-multiply-0 {
  --padding-multiplier-bottom: 0 !important;
}

.pb-multiply-025 {
  --padding-multiplier-bottom: 0.25 !important;
}

.pb-multiply-05 {
  --padding-multiplier-bottom: 0.5 !important;
}

.pb-multiply-075 {
  --padding-multiplier-bottom: 0.75 !important;
}

.pb-multiply-1 {
  --padding-multiplier-bottom: 1 !important;
}

.pb-multiply-125 {
  --padding-multiplier-bottom: 1.25 !important;
}

.pb-multiply-15 {
  --padding-multiplier-bottom: 1.5 !important;
}

.pb-multiply-175 {
  --padding-multiplier-bottom: 1.75 !important;
}

.pb-multiply-2 {
  --padding-multiplier-bottom: 2 !important;
}

@media (min-width: 600px) {
  .s\:pb-multiply-0 {
    --padding-multiplier-bottom: 0 !important;
  }

  .s\:pb-multiply-025 {
    --padding-multiplier-bottom: 0.25 !important;
  }

  .s\:pb-multiply-05 {
    --padding-multiplier-bottom: 0.5 !important;
  }

  .s\:pb-multiply-075 {
    --padding-multiplier-bottom: 0.75 !important;
  }

  .s\:pb-multiply-1 {
    --padding-multiplier-bottom: 1 !important;
  }

  .s\:pb-multiply-125 {
    --padding-multiplier-bottom: 1.25 !important;
  }

  .s\:pb-multiply-15 {
    --padding-multiplier-bottom: 1.5 !important;
  }

  .s\:pb-multiply-175 {
    --padding-multiplier-bottom: 1.75 !important;
  }

  .s\:pb-multiply-2 {
    --padding-multiplier-bottom: 2 !important;
  }
}
@media (min-width: 900px) {
  .m\:pb-multiply-0 {
    --padding-multiplier-bottom: 0 !important;
  }

  .m\:pb-multiply-025 {
    --padding-multiplier-bottom: 0.25 !important;
  }

  .m\:pb-multiply-05 {
    --padding-multiplier-bottom: 0.5 !important;
  }

  .m\:pb-multiply-075 {
    --padding-multiplier-bottom: 0.75 !important;
  }

  .m\:pb-multiply-1 {
    --padding-multiplier-bottom: 1 !important;
  }

  .m\:pb-multiply-125 {
    --padding-multiplier-bottom: 1.25 !important;
  }

  .m\:pb-multiply-15 {
    --padding-multiplier-bottom: 1.5 !important;
  }

  .m\:pb-multiply-175 {
    --padding-multiplier-bottom: 1.75 !important;
  }

  .m\:pb-multiply-2 {
    --padding-multiplier-bottom: 2 !important;
  }
}
@media (min-width: 1200px) {
  .l\:pb-multiply-0 {
    --padding-multiplier-bottom: 0 !important;
  }

  .l\:pb-multiply-025 {
    --padding-multiplier-bottom: 0.25 !important;
  }

  .l\:pb-multiply-05 {
    --padding-multiplier-bottom: 0.5 !important;
  }

  .l\:pb-multiply-075 {
    --padding-multiplier-bottom: 0.75 !important;
  }

  .l\:pb-multiply-1 {
    --padding-multiplier-bottom: 1 !important;
  }

  .l\:pb-multiply-125 {
    --padding-multiplier-bottom: 1.25 !important;
  }

  .l\:pb-multiply-15 {
    --padding-multiplier-bottom: 1.5 !important;
  }

  .l\:pb-multiply-175 {
    --padding-multiplier-bottom: 1.75 !important;
  }

  .l\:pb-multiply-2 {
    --padding-multiplier-bottom: 2 !important;
  }
}
@media (min-width: 1600px) {
  .xl\:pb-multiply-0 {
    --padding-multiplier-bottom: 0 !important;
  }

  .xl\:pb-multiply-025 {
    --padding-multiplier-bottom: 0.25 !important;
  }

  .xl\:pb-multiply-05 {
    --padding-multiplier-bottom: 0.5 !important;
  }

  .xl\:pb-multiply-075 {
    --padding-multiplier-bottom: 0.75 !important;
  }

  .xl\:pb-multiply-1 {
    --padding-multiplier-bottom: 1 !important;
  }

  .xl\:pb-multiply-125 {
    --padding-multiplier-bottom: 1.25 !important;
  }

  .xl\:pb-multiply-15 {
    --padding-multiplier-bottom: 1.5 !important;
  }

  .xl\:pb-multiply-175 {
    --padding-multiplier-bottom: 1.75 !important;
  }

  .xl\:pb-multiply-2 {
    --padding-multiplier-bottom: 2 !important;
  }
}
.shadow {
  box-shadow: 0 0 2rem rgba(7, 107, 173, 0.15);
}

.columns-1 {
  column-count: 1 !important;
}

.columns-2 {
  column-count: 2 !important;
}

.columns-3 {
  column-count: 3 !important;
}

.columns-4 {
  column-count: 4 !important;
}

@media (min-width: 600px) {
  .s\:columns-1 {
    column-count: 1 !important;
  }

  .s\:columns-2 {
    column-count: 2 !important;
  }

  .s\:columns-3 {
    column-count: 3 !important;
  }

  .s\:columns-4 {
    column-count: 4 !important;
  }
}
@media (min-width: 900px) {
  .m\:columns-1 {
    column-count: 1 !important;
  }

  .m\:columns-2 {
    column-count: 2 !important;
  }

  .m\:columns-3 {
    column-count: 3 !important;
  }

  .m\:columns-4 {
    column-count: 4 !important;
  }
}
@media (min-width: 1200px) {
  .l\:columns-1 {
    column-count: 1 !important;
  }

  .l\:columns-2 {
    column-count: 2 !important;
  }

  .l\:columns-3 {
    column-count: 3 !important;
  }

  .l\:columns-4 {
    column-count: 4 !important;
  }
}
@media (min-width: 1600px) {
  .xl\:columns-1 {
    column-count: 1 !important;
  }

  .xl\:columns-2 {
    column-count: 2 !important;
  }

  .xl\:columns-3 {
    column-count: 3 !important;
  }

  .xl\:columns-4 {
    column-count: 4 !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

@media (min-width: 600px) {
  .s\:flex-row {
    flex-direction: row !important;
  }

  .s\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .s\:flex-column {
    flex-direction: column !important;
  }

  .s\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 900px) {
  .m\:flex-row {
    flex-direction: row !important;
  }

  .m\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .m\:flex-column {
    flex-direction: column !important;
  }

  .m\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 1200px) {
  .l\:flex-row {
    flex-direction: row !important;
  }

  .l\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .l\:flex-column {
    flex-direction: column !important;
  }

  .l\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media (min-width: 1600px) {
  .xl\:flex-row {
    flex-direction: row !important;
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xl\:flex-column {
    flex-direction: column !important;
  }

  .xl\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

@media (min-width: 600px) {
  .s\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .s\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media (min-width: 900px) {
  .m\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .m\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media (min-width: 1200px) {
  .l\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .l\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media (min-width: 1600px) {
  .xl\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-stretch {
  justify-content: stretch !important;
}

@media (min-width: 600px) {
  .s\:justify-content-start {
    justify-content: flex-start !important;
  }

  .s\:justify-content-center {
    justify-content: center !important;
  }

  .s\:justify-content-end {
    justify-content: flex-end !important;
  }

  .s\:justify-content-between {
    justify-content: space-between !important;
  }

  .s\:justify-content-around {
    justify-content: space-around !important;
  }

  .s\:justify-content-stretch {
    justify-content: stretch !important;
  }
}
@media (min-width: 900px) {
  .m\:justify-content-start {
    justify-content: flex-start !important;
  }

  .m\:justify-content-center {
    justify-content: center !important;
  }

  .m\:justify-content-end {
    justify-content: flex-end !important;
  }

  .m\:justify-content-between {
    justify-content: space-between !important;
  }

  .m\:justify-content-around {
    justify-content: space-around !important;
  }

  .m\:justify-content-stretch {
    justify-content: stretch !important;
  }
}
@media (min-width: 1200px) {
  .l\:justify-content-start {
    justify-content: flex-start !important;
  }

  .l\:justify-content-center {
    justify-content: center !important;
  }

  .l\:justify-content-end {
    justify-content: flex-end !important;
  }

  .l\:justify-content-between {
    justify-content: space-between !important;
  }

  .l\:justify-content-around {
    justify-content: space-around !important;
  }

  .l\:justify-content-stretch {
    justify-content: stretch !important;
  }
}
@media (min-width: 1600px) {
  .xl\:justify-content-start {
    justify-content: flex-start !important;
  }

  .xl\:justify-content-center {
    justify-content: center !important;
  }

  .xl\:justify-content-end {
    justify-content: flex-end !important;
  }

  .xl\:justify-content-between {
    justify-content: space-between !important;
  }

  .xl\:justify-content-around {
    justify-content: space-around !important;
  }

  .xl\:justify-content-stretch {
    justify-content: stretch !important;
  }
}
.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

@media (min-width: 600px) {
  .s\:align-items-start {
    align-items: flex-start !important;
  }

  .s\:align-items-center {
    align-items: center !important;
  }

  .s\:align-items-end {
    align-items: flex-end !important;
  }

  .s\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media (min-width: 900px) {
  .m\:align-items-start {
    align-items: flex-start !important;
  }

  .m\:align-items-center {
    align-items: center !important;
  }

  .m\:align-items-end {
    align-items: flex-end !important;
  }

  .m\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media (min-width: 1200px) {
  .l\:align-items-start {
    align-items: flex-start !important;
  }

  .l\:align-items-center {
    align-items: center !important;
  }

  .l\:align-items-end {
    align-items: flex-end !important;
  }

  .l\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media (min-width: 1600px) {
  .xl\:align-items-start {
    align-items: flex-start !important;
  }

  .xl\:align-items-center {
    align-items: center !important;
  }

  .xl\:align-items-end {
    align-items: flex-end !important;
  }

  .xl\:align-items-baseline {
    align-items: baseline !important;
  }
}
.align-self-start {
  align-self: flex-start !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

@media (min-width: 600px) {
  .s\:align-self-start {
    align-self: flex-start !important;
  }

  .s\:align-self-center {
    align-self: center !important;
  }

  .s\:align-self-end {
    align-self: flex-end !important;
  }

  .s\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media (min-width: 900px) {
  .m\:align-self-start {
    align-self: flex-start !important;
  }

  .m\:align-self-center {
    align-self: center !important;
  }

  .m\:align-self-end {
    align-self: flex-end !important;
  }

  .m\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media (min-width: 1200px) {
  .l\:align-self-start {
    align-self: flex-start !important;
  }

  .l\:align-self-center {
    align-self: center !important;
  }

  .l\:align-self-end {
    align-self: flex-end !important;
  }

  .l\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media (min-width: 1600px) {
  .xl\:align-self-start {
    align-self: flex-start !important;
  }

  .xl\:align-self-center {
    align-self: center !important;
  }

  .xl\:align-self-end {
    align-self: flex-end !important;
  }

  .xl\:align-self-baseline {
    align-self: baseline !important;
  }
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

@media (min-width: 600px) {
  .s\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .s\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media (min-width: 900px) {
  .m\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .m\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media (min-width: 1200px) {
  .l\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .l\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media (min-width: 1600px) {
  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xl\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

@media (min-width: 600px) {
  .s\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .s\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media (min-width: 900px) {
  .m\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .m\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media (min-width: 1200px) {
  .l\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .l\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media (min-width: 1600px) {
  .xl\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .xl\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
.g-0 {
  gap: 0 !important;
}

.g-1 {
  gap: 0.25rem !important;
}

.g-2 {
  gap: 0.5rem !important;
}

.g-3 {
  gap: 0.75rem !important;
}

.g-4 {
  gap: 1rem !important;
}

.g-5 {
  gap: 1.25rem !important;
}

.g-6 {
  gap: 1.5rem !important;
}

.g-8 {
  gap: 2rem !important;
}

.g-10 {
  gap: 2.5rem !important;
}

.g-12 {
  gap: 3rem !important;
}

.g-14 {
  gap: 3.5rem !important;
}

.g-16 {
  gap: 4rem !important;
}

.g-20 {
  gap: 5rem !important;
}

.g-24 {
  gap: 6rem !important;
}

.g-32 {
  gap: 8rem !important;
}

.g-40 {
  gap: 10rem !important;
}

.g-1em {
  gap: 1em !important;
}

@media (min-width: 600px) {
  .s\:g-0 {
    gap: 0 !important;
  }

  .s\:g-1 {
    gap: 0.25rem !important;
  }

  .s\:g-2 {
    gap: 0.5rem !important;
  }

  .s\:g-3 {
    gap: 0.75rem !important;
  }

  .s\:g-4 {
    gap: 1rem !important;
  }

  .s\:g-5 {
    gap: 1.25rem !important;
  }

  .s\:g-6 {
    gap: 1.5rem !important;
  }

  .s\:g-8 {
    gap: 2rem !important;
  }

  .s\:g-10 {
    gap: 2.5rem !important;
  }

  .s\:g-12 {
    gap: 3rem !important;
  }

  .s\:g-14 {
    gap: 3.5rem !important;
  }

  .s\:g-16 {
    gap: 4rem !important;
  }

  .s\:g-20 {
    gap: 5rem !important;
  }

  .s\:g-24 {
    gap: 6rem !important;
  }

  .s\:g-32 {
    gap: 8rem !important;
  }

  .s\:g-40 {
    gap: 10rem !important;
  }

  .s\:g-1em {
    gap: 1em !important;
  }
}
@media (min-width: 900px) {
  .m\:g-0 {
    gap: 0 !important;
  }

  .m\:g-1 {
    gap: 0.25rem !important;
  }

  .m\:g-2 {
    gap: 0.5rem !important;
  }

  .m\:g-3 {
    gap: 0.75rem !important;
  }

  .m\:g-4 {
    gap: 1rem !important;
  }

  .m\:g-5 {
    gap: 1.25rem !important;
  }

  .m\:g-6 {
    gap: 1.5rem !important;
  }

  .m\:g-8 {
    gap: 2rem !important;
  }

  .m\:g-10 {
    gap: 2.5rem !important;
  }

  .m\:g-12 {
    gap: 3rem !important;
  }

  .m\:g-14 {
    gap: 3.5rem !important;
  }

  .m\:g-16 {
    gap: 4rem !important;
  }

  .m\:g-20 {
    gap: 5rem !important;
  }

  .m\:g-24 {
    gap: 6rem !important;
  }

  .m\:g-32 {
    gap: 8rem !important;
  }

  .m\:g-40 {
    gap: 10rem !important;
  }

  .m\:g-1em {
    gap: 1em !important;
  }
}
@media (min-width: 1200px) {
  .l\:g-0 {
    gap: 0 !important;
  }

  .l\:g-1 {
    gap: 0.25rem !important;
  }

  .l\:g-2 {
    gap: 0.5rem !important;
  }

  .l\:g-3 {
    gap: 0.75rem !important;
  }

  .l\:g-4 {
    gap: 1rem !important;
  }

  .l\:g-5 {
    gap: 1.25rem !important;
  }

  .l\:g-6 {
    gap: 1.5rem !important;
  }

  .l\:g-8 {
    gap: 2rem !important;
  }

  .l\:g-10 {
    gap: 2.5rem !important;
  }

  .l\:g-12 {
    gap: 3rem !important;
  }

  .l\:g-14 {
    gap: 3.5rem !important;
  }

  .l\:g-16 {
    gap: 4rem !important;
  }

  .l\:g-20 {
    gap: 5rem !important;
  }

  .l\:g-24 {
    gap: 6rem !important;
  }

  .l\:g-32 {
    gap: 8rem !important;
  }

  .l\:g-40 {
    gap: 10rem !important;
  }

  .l\:g-1em {
    gap: 1em !important;
  }
}
@media (min-width: 1600px) {
  .xl\:g-0 {
    gap: 0 !important;
  }

  .xl\:g-1 {
    gap: 0.25rem !important;
  }

  .xl\:g-2 {
    gap: 0.5rem !important;
  }

  .xl\:g-3 {
    gap: 0.75rem !important;
  }

  .xl\:g-4 {
    gap: 1rem !important;
  }

  .xl\:g-5 {
    gap: 1.25rem !important;
  }

  .xl\:g-6 {
    gap: 1.5rem !important;
  }

  .xl\:g-8 {
    gap: 2rem !important;
  }

  .xl\:g-10 {
    gap: 2.5rem !important;
  }

  .xl\:g-12 {
    gap: 3rem !important;
  }

  .xl\:g-14 {
    gap: 3.5rem !important;
  }

  .xl\:g-16 {
    gap: 4rem !important;
  }

  .xl\:g-20 {
    gap: 5rem !important;
  }

  .xl\:g-24 {
    gap: 6rem !important;
  }

  .xl\:g-32 {
    gap: 8rem !important;
  }

  .xl\:g-40 {
    gap: 10rem !important;
  }

  .xl\:g-1em {
    gap: 1em !important;
  }
}
.gx-0 {
  column-gap: 0 !important;
}

.gx-1 {
  column-gap: 0.25rem !important;
}

.gx-2 {
  column-gap: 0.5rem !important;
}

.gx-3 {
  column-gap: 0.75rem !important;
}

.gx-4 {
  column-gap: 1rem !important;
}

.gx-5 {
  column-gap: 1.25rem !important;
}

.gx-6 {
  column-gap: 1.5rem !important;
}

.gx-8 {
  column-gap: 2rem !important;
}

.gx-10 {
  column-gap: 2.5rem !important;
}

.gx-12 {
  column-gap: 3rem !important;
}

.gx-14 {
  column-gap: 3.5rem !important;
}

.gx-16 {
  column-gap: 4rem !important;
}

.gx-20 {
  column-gap: 5rem !important;
}

.gx-24 {
  column-gap: 6rem !important;
}

.gx-32 {
  column-gap: 8rem !important;
}

.gx-40 {
  column-gap: 10rem !important;
}

.gx-1em {
  column-gap: 1em !important;
}

@media (min-width: 600px) {
  .s\:gx-0 {
    column-gap: 0 !important;
  }

  .s\:gx-1 {
    column-gap: 0.25rem !important;
  }

  .s\:gx-2 {
    column-gap: 0.5rem !important;
  }

  .s\:gx-3 {
    column-gap: 0.75rem !important;
  }

  .s\:gx-4 {
    column-gap: 1rem !important;
  }

  .s\:gx-5 {
    column-gap: 1.25rem !important;
  }

  .s\:gx-6 {
    column-gap: 1.5rem !important;
  }

  .s\:gx-8 {
    column-gap: 2rem !important;
  }

  .s\:gx-10 {
    column-gap: 2.5rem !important;
  }

  .s\:gx-12 {
    column-gap: 3rem !important;
  }

  .s\:gx-14 {
    column-gap: 3.5rem !important;
  }

  .s\:gx-16 {
    column-gap: 4rem !important;
  }

  .s\:gx-20 {
    column-gap: 5rem !important;
  }

  .s\:gx-24 {
    column-gap: 6rem !important;
  }

  .s\:gx-32 {
    column-gap: 8rem !important;
  }

  .s\:gx-40 {
    column-gap: 10rem !important;
  }

  .s\:gx-1em {
    column-gap: 1em !important;
  }
}
@media (min-width: 900px) {
  .m\:gx-0 {
    column-gap: 0 !important;
  }

  .m\:gx-1 {
    column-gap: 0.25rem !important;
  }

  .m\:gx-2 {
    column-gap: 0.5rem !important;
  }

  .m\:gx-3 {
    column-gap: 0.75rem !important;
  }

  .m\:gx-4 {
    column-gap: 1rem !important;
  }

  .m\:gx-5 {
    column-gap: 1.25rem !important;
  }

  .m\:gx-6 {
    column-gap: 1.5rem !important;
  }

  .m\:gx-8 {
    column-gap: 2rem !important;
  }

  .m\:gx-10 {
    column-gap: 2.5rem !important;
  }

  .m\:gx-12 {
    column-gap: 3rem !important;
  }

  .m\:gx-14 {
    column-gap: 3.5rem !important;
  }

  .m\:gx-16 {
    column-gap: 4rem !important;
  }

  .m\:gx-20 {
    column-gap: 5rem !important;
  }

  .m\:gx-24 {
    column-gap: 6rem !important;
  }

  .m\:gx-32 {
    column-gap: 8rem !important;
  }

  .m\:gx-40 {
    column-gap: 10rem !important;
  }

  .m\:gx-1em {
    column-gap: 1em !important;
  }
}
@media (min-width: 1200px) {
  .l\:gx-0 {
    column-gap: 0 !important;
  }

  .l\:gx-1 {
    column-gap: 0.25rem !important;
  }

  .l\:gx-2 {
    column-gap: 0.5rem !important;
  }

  .l\:gx-3 {
    column-gap: 0.75rem !important;
  }

  .l\:gx-4 {
    column-gap: 1rem !important;
  }

  .l\:gx-5 {
    column-gap: 1.25rem !important;
  }

  .l\:gx-6 {
    column-gap: 1.5rem !important;
  }

  .l\:gx-8 {
    column-gap: 2rem !important;
  }

  .l\:gx-10 {
    column-gap: 2.5rem !important;
  }

  .l\:gx-12 {
    column-gap: 3rem !important;
  }

  .l\:gx-14 {
    column-gap: 3.5rem !important;
  }

  .l\:gx-16 {
    column-gap: 4rem !important;
  }

  .l\:gx-20 {
    column-gap: 5rem !important;
  }

  .l\:gx-24 {
    column-gap: 6rem !important;
  }

  .l\:gx-32 {
    column-gap: 8rem !important;
  }

  .l\:gx-40 {
    column-gap: 10rem !important;
  }

  .l\:gx-1em {
    column-gap: 1em !important;
  }
}
@media (min-width: 1600px) {
  .xl\:gx-0 {
    column-gap: 0 !important;
  }

  .xl\:gx-1 {
    column-gap: 0.25rem !important;
  }

  .xl\:gx-2 {
    column-gap: 0.5rem !important;
  }

  .xl\:gx-3 {
    column-gap: 0.75rem !important;
  }

  .xl\:gx-4 {
    column-gap: 1rem !important;
  }

  .xl\:gx-5 {
    column-gap: 1.25rem !important;
  }

  .xl\:gx-6 {
    column-gap: 1.5rem !important;
  }

  .xl\:gx-8 {
    column-gap: 2rem !important;
  }

  .xl\:gx-10 {
    column-gap: 2.5rem !important;
  }

  .xl\:gx-12 {
    column-gap: 3rem !important;
  }

  .xl\:gx-14 {
    column-gap: 3.5rem !important;
  }

  .xl\:gx-16 {
    column-gap: 4rem !important;
  }

  .xl\:gx-20 {
    column-gap: 5rem !important;
  }

  .xl\:gx-24 {
    column-gap: 6rem !important;
  }

  .xl\:gx-32 {
    column-gap: 8rem !important;
  }

  .xl\:gx-40 {
    column-gap: 10rem !important;
  }

  .xl\:gx-1em {
    column-gap: 1em !important;
  }
}
.gy-0 {
  row-gap: 0 !important;
}

.gy-1 {
  row-gap: 0.25rem !important;
}

.gy-2 {
  row-gap: 0.5rem !important;
}

.gy-3 {
  row-gap: 0.75rem !important;
}

.gy-4 {
  row-gap: 1rem !important;
}

.gy-5 {
  row-gap: 1.25rem !important;
}

.gy-6 {
  row-gap: 1.5rem !important;
}

.gy-8 {
  row-gap: 2rem !important;
}

.gy-10 {
  row-gap: 2.5rem !important;
}

.gy-12 {
  row-gap: 3rem !important;
}

.gy-14 {
  row-gap: 3.5rem !important;
}

.gy-16 {
  row-gap: 4rem !important;
}

.gy-20 {
  row-gap: 5rem !important;
}

.gy-24 {
  row-gap: 6rem !important;
}

.gy-32 {
  row-gap: 8rem !important;
}

.gy-40 {
  row-gap: 10rem !important;
}

.gy-1em {
  row-gap: 1em !important;
}

@media (min-width: 600px) {
  .s\:gy-0 {
    row-gap: 0 !important;
  }

  .s\:gy-1 {
    row-gap: 0.25rem !important;
  }

  .s\:gy-2 {
    row-gap: 0.5rem !important;
  }

  .s\:gy-3 {
    row-gap: 0.75rem !important;
  }

  .s\:gy-4 {
    row-gap: 1rem !important;
  }

  .s\:gy-5 {
    row-gap: 1.25rem !important;
  }

  .s\:gy-6 {
    row-gap: 1.5rem !important;
  }

  .s\:gy-8 {
    row-gap: 2rem !important;
  }

  .s\:gy-10 {
    row-gap: 2.5rem !important;
  }

  .s\:gy-12 {
    row-gap: 3rem !important;
  }

  .s\:gy-14 {
    row-gap: 3.5rem !important;
  }

  .s\:gy-16 {
    row-gap: 4rem !important;
  }

  .s\:gy-20 {
    row-gap: 5rem !important;
  }

  .s\:gy-24 {
    row-gap: 6rem !important;
  }

  .s\:gy-32 {
    row-gap: 8rem !important;
  }

  .s\:gy-40 {
    row-gap: 10rem !important;
  }

  .s\:gy-1em {
    row-gap: 1em !important;
  }
}
@media (min-width: 900px) {
  .m\:gy-0 {
    row-gap: 0 !important;
  }

  .m\:gy-1 {
    row-gap: 0.25rem !important;
  }

  .m\:gy-2 {
    row-gap: 0.5rem !important;
  }

  .m\:gy-3 {
    row-gap: 0.75rem !important;
  }

  .m\:gy-4 {
    row-gap: 1rem !important;
  }

  .m\:gy-5 {
    row-gap: 1.25rem !important;
  }

  .m\:gy-6 {
    row-gap: 1.5rem !important;
  }

  .m\:gy-8 {
    row-gap: 2rem !important;
  }

  .m\:gy-10 {
    row-gap: 2.5rem !important;
  }

  .m\:gy-12 {
    row-gap: 3rem !important;
  }

  .m\:gy-14 {
    row-gap: 3.5rem !important;
  }

  .m\:gy-16 {
    row-gap: 4rem !important;
  }

  .m\:gy-20 {
    row-gap: 5rem !important;
  }

  .m\:gy-24 {
    row-gap: 6rem !important;
  }

  .m\:gy-32 {
    row-gap: 8rem !important;
  }

  .m\:gy-40 {
    row-gap: 10rem !important;
  }

  .m\:gy-1em {
    row-gap: 1em !important;
  }
}
@media (min-width: 1200px) {
  .l\:gy-0 {
    row-gap: 0 !important;
  }

  .l\:gy-1 {
    row-gap: 0.25rem !important;
  }

  .l\:gy-2 {
    row-gap: 0.5rem !important;
  }

  .l\:gy-3 {
    row-gap: 0.75rem !important;
  }

  .l\:gy-4 {
    row-gap: 1rem !important;
  }

  .l\:gy-5 {
    row-gap: 1.25rem !important;
  }

  .l\:gy-6 {
    row-gap: 1.5rem !important;
  }

  .l\:gy-8 {
    row-gap: 2rem !important;
  }

  .l\:gy-10 {
    row-gap: 2.5rem !important;
  }

  .l\:gy-12 {
    row-gap: 3rem !important;
  }

  .l\:gy-14 {
    row-gap: 3.5rem !important;
  }

  .l\:gy-16 {
    row-gap: 4rem !important;
  }

  .l\:gy-20 {
    row-gap: 5rem !important;
  }

  .l\:gy-24 {
    row-gap: 6rem !important;
  }

  .l\:gy-32 {
    row-gap: 8rem !important;
  }

  .l\:gy-40 {
    row-gap: 10rem !important;
  }

  .l\:gy-1em {
    row-gap: 1em !important;
  }
}
@media (min-width: 1600px) {
  .xl\:gy-0 {
    row-gap: 0 !important;
  }

  .xl\:gy-1 {
    row-gap: 0.25rem !important;
  }

  .xl\:gy-2 {
    row-gap: 0.5rem !important;
  }

  .xl\:gy-3 {
    row-gap: 0.75rem !important;
  }

  .xl\:gy-4 {
    row-gap: 1rem !important;
  }

  .xl\:gy-5 {
    row-gap: 1.25rem !important;
  }

  .xl\:gy-6 {
    row-gap: 1.5rem !important;
  }

  .xl\:gy-8 {
    row-gap: 2rem !important;
  }

  .xl\:gy-10 {
    row-gap: 2.5rem !important;
  }

  .xl\:gy-12 {
    row-gap: 3rem !important;
  }

  .xl\:gy-14 {
    row-gap: 3.5rem !important;
  }

  .xl\:gy-16 {
    row-gap: 4rem !important;
  }

  .xl\:gy-20 {
    row-gap: 5rem !important;
  }

  .xl\:gy-24 {
    row-gap: 6rem !important;
  }

  .xl\:gy-32 {
    row-gap: 8rem !important;
  }

  .xl\:gy-40 {
    row-gap: 10rem !important;
  }

  .xl\:gy-1em {
    row-gap: 1em !important;
  }
}
.flex-auto {
  flex: auto !important;
}

.flex-none {
  flex: none !important;
}

.flex-0 {
  flex: 0 !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-2 {
  flex: 2 !important;
}

.flex-3 {
  flex: 3 !important;
}

.flex-4 {
  flex: 4 !important;
}

.flex-5 {
  flex: 5 !important;
}

@media (min-width: 600px) {
  .s\:flex-auto {
    flex: auto !important;
  }

  .s\:flex-none {
    flex: none !important;
  }

  .s\:flex-0 {
    flex: 0 !important;
  }

  .s\:flex-1 {
    flex: 1 !important;
  }

  .s\:flex-2 {
    flex: 2 !important;
  }

  .s\:flex-3 {
    flex: 3 !important;
  }

  .s\:flex-4 {
    flex: 4 !important;
  }

  .s\:flex-5 {
    flex: 5 !important;
  }
}
@media (min-width: 900px) {
  .m\:flex-auto {
    flex: auto !important;
  }

  .m\:flex-none {
    flex: none !important;
  }

  .m\:flex-0 {
    flex: 0 !important;
  }

  .m\:flex-1 {
    flex: 1 !important;
  }

  .m\:flex-2 {
    flex: 2 !important;
  }

  .m\:flex-3 {
    flex: 3 !important;
  }

  .m\:flex-4 {
    flex: 4 !important;
  }

  .m\:flex-5 {
    flex: 5 !important;
  }
}
@media (min-width: 1200px) {
  .l\:flex-auto {
    flex: auto !important;
  }

  .l\:flex-none {
    flex: none !important;
  }

  .l\:flex-0 {
    flex: 0 !important;
  }

  .l\:flex-1 {
    flex: 1 !important;
  }

  .l\:flex-2 {
    flex: 2 !important;
  }

  .l\:flex-3 {
    flex: 3 !important;
  }

  .l\:flex-4 {
    flex: 4 !important;
  }

  .l\:flex-5 {
    flex: 5 !important;
  }
}
@media (min-width: 1600px) {
  .xl\:flex-auto {
    flex: auto !important;
  }

  .xl\:flex-none {
    flex: none !important;
  }

  .xl\:flex-0 {
    flex: 0 !important;
  }

  .xl\:flex-1 {
    flex: 1 !important;
  }

  .xl\:flex-2 {
    flex: 2 !important;
  }

  .xl\:flex-3 {
    flex: 3 !important;
  }

  .xl\:flex-4 {
    flex: 4 !important;
  }

  .xl\:flex-5 {
    flex: 5 !important;
  }
}
.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

@media (min-width: 600px) {
  .s\:order-0 {
    order: 0 !important;
  }

  .s\:order-1 {
    order: 1 !important;
  }

  .s\:order-2 {
    order: 2 !important;
  }

  .s\:order-3 {
    order: 3 !important;
  }

  .s\:order-4 {
    order: 4 !important;
  }

  .s\:order-5 {
    order: 5 !important;
  }
}
@media (min-width: 900px) {
  .m\:order-0 {
    order: 0 !important;
  }

  .m\:order-1 {
    order: 1 !important;
  }

  .m\:order-2 {
    order: 2 !important;
  }

  .m\:order-3 {
    order: 3 !important;
  }

  .m\:order-4 {
    order: 4 !important;
  }

  .m\:order-5 {
    order: 5 !important;
  }
}
@media (min-width: 1200px) {
  .l\:order-0 {
    order: 0 !important;
  }

  .l\:order-1 {
    order: 1 !important;
  }

  .l\:order-2 {
    order: 2 !important;
  }

  .l\:order-3 {
    order: 3 !important;
  }

  .l\:order-4 {
    order: 4 !important;
  }

  .l\:order-5 {
    order: 5 !important;
  }
}
@media (min-width: 1600px) {
  .xl\:order-0 {
    order: 0 !important;
  }

  .xl\:order-1 {
    order: 1 !important;
  }

  .xl\:order-2 {
    order: 2 !important;
  }

  .xl\:order-3 {
    order: 3 !important;
  }

  .xl\:order-4 {
    order: 4 !important;
  }

  .xl\:order-5 {
    order: 5 !important;
  }
}