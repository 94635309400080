@use "sass:map";
@use "./modules/variables" as var;
@use "./modules/mixins";
@use "./modules/functions";

// Utilities

// Text
@include mixins.breakpoint-iterator(var.$font-sizes, "fs", ("font-size"));
@include mixins.breakpoint-iterator(var.$text-align, "text", ("text-align"));
@include mixins.breakpoint-iterator(var.$font-weights, "fw", ("font-weight"));
@include mixins.breakpoint-iterator(var.$text-wrap, "text", ("white-space"));
@include mixins.rule-iterator(("italic": "italic", "normal": "normal"), "", "fst", ("font-style"));
@include mixins.rule-iterator(var.$colors, "", "text", ("color"));
@include mixins.rule-iterator(var.$grays, "", "text-gray", ("color"));
@include mixins.rule-iterator(var.$line-heights, "", "lh", ("line-height"));
.text {
  &-break {
    word-break: break-word;
  }
  &-uppercase {
    text-transform: uppercase !important;
  }
  &-no-transform {
    text-transform: none !important;
  }
  &-muted {
    color: var.$text-muted;
  }
}

.font {
  &-primary {
    font-family: var.$font-family-primary;
  }
  &-secondary {
    font-family: var.$font-family-secondary;
  }
  &-tertiary {
    font-family: var.$font-family-tertiary;
  }
}

.ellipsis {
  @include mixins.ellipsis;
}

// Background color
@include mixins.breakpoint-iterator(var.$grays, "bg-gray", ("background-color"));
@include mixins.breakpoint-iterator(map.merge(var.$colors, ("transparent": transparent)), "bg", ("background-color"));

// Display {
@include mixins.breakpoint-iterator(var.$display, "d", ("display"));

// Spacing
@include mixins.breakpoint-iterator(map.merge(var.$spacers, ("auto": auto)), "m", ("margin"));
@include mixins.breakpoint-iterator(map.merge(var.$spacers, ("auto": auto, "container": "calc(var(--margin-y) * var(--margin-multiplier-top, 1))")), "mt", ("margin-top"));
@include mixins.breakpoint-iterator(map.merge(var.$spacers, ("auto": auto, "container": "calc(var(--margin-y) * var(--margin-multiplier-bottom, 1))")), "mb", ("margin-bottom"));
@include mixins.breakpoint-iterator(map.merge(var.$spacers, ("auto": auto)), "ml", ("margin-left"));
@include mixins.breakpoint-iterator(map.merge(var.$spacers, ("auto": auto)), "mr", ("margin-right"));
@include mixins.breakpoint-iterator(var.$spacers, "ml", ("margin-left"), true);
@include mixins.breakpoint-iterator(var.$spacers, "mr", ("margin-right"), true);
@include mixins.breakpoint-iterator(var.$spacers, "p", ("padding"));
@include mixins.breakpoint-iterator(map.merge(var.$spacers, ("container": "var(--padding-y)")), "pt", ("padding-top"));
@include mixins.breakpoint-iterator(map.merge(var.$spacers, ("container": "var(--padding-y)")), "pb", ("padding-bottom"));
@include mixins.breakpoint-iterator(var.$spacers, "pl", ("padding-left"));
@include mixins.breakpoint-iterator(var.$spacers, "pr", ("padding-right"));

.my-0-children {
  > :first-child {
    margin-top: 0 !important;
  }
  > :last-child {
    margin-bottom: 0 !important;
  }
}

// Width
@include mixins.breakpoint-iterator(var.$sizes, "w", ("width"));

// Min width
@include mixins.breakpoint-iterator(var.$sizes, "minw", ("min-width"));

// Max width
@include mixins.breakpoint-iterator(map.merge(var.$sizes, (none: none)), "mw", ("max-width"));
@include mixins.breakpoint-iterator(var.$sizes-char, "mw", ("max-width"));

// Height
//@include mixins.breakpoint-iterator((100: 100%), "h", ("height"));
.h-100 {
  height: 100% !important;
}

// Gap
[class*="gx-"] {
  column-gap: var(--gap-x);
}
[class*="gy-"] {
  row-gap: var(--gap-y);
}
@include mixins.breakpoint-iterator(var.$spacers, "gap-x", ("--gap-x"));
@include mixins.breakpoint-iterator(var.$spacers, "gap-y", ("--gap-y"));


// Transform
[class*="translate-"] {
  transform: translateX(var(--translateX, 0px)) translateY(var(--translateY, 0px));
}
@include mixins.breakpoint-iterator((50: -50%), "translate-x", ("--translateX"));
//@include mixins.breakpoint-iterator(var.$spacers, "translate-y", ("--translateY"));

// Border
//@include mixins.breakpoint-iterator(var.$borders, 'border', ('border-bottom'));
.border {
  border: map.get(var.$borders, '1');

  &-top {
    border-top: map.get(var.$borders, '1');
  }

  &-bottom {
    border-bottom: map.get(var.$borders, '1');
  }

  &-white {
    border: 1px solid #fff;
  }

  &-2px {
    border-width: 2px;
  }
}

// Border radius
//@include mixins.breakpoint-iterator(var.$border-radii, 'rounded', ('border-radius'));
@include mixins.rule-iterator(var.$border-radii, '', 'rounded', ('border-radius'));

// Opacity
//@include mixins.rule-iterator(var.$opacity, '', 'opacity', 'opacity');

// Pointer events
//@include mixins.rule-iterator(var.$pointer-events, '', 'pointer-events', 'pointer-events');

// Position
.relative {
  position: relative;
}

// Z-index
.zindex {
  &-1 {
    z-index: 1;
  }

  &-2 {
    z-index: 2;
  }

  &-3 {
    z-index: 3;
  }
}

// Overflow
.overflow {
  &-hidden {
    overflow: hidden;
  }
}

// Section margin/padding multipliers
@include mixins.breakpoint-iterator(var.$section-multipliers, "mt-multiply", ("--margin-multiplier-top"));
@include mixins.breakpoint-iterator(var.$section-multipliers, "mb-multiply", ("--margin-multiplier-bottom"));
@include mixins.breakpoint-iterator(var.$section-multipliers, "pt-multiply", ("--padding-multiplier-top"));
@include mixins.breakpoint-iterator(var.$section-multipliers, "pb-multiply", ("--padding-multiplier-bottom"));

// Aspect ratio
//@include mixins.breakpoint-iterator(var.$aspect-ratios, "aspect", ("aspect-ratio"));

// Shadow
.shadow {
  box-shadow: 0 0 2rem rgba(var.$brand-blue, .15);
}

// Columns
@include mixins.breakpoint-iterator((1: 1, 2: 2, 3: 3, 4: 4), "columns", ("column-count"));

// Flex
@include mixins.breakpoint-iterator(var.$flex-directions, "flex", ("flex-direction"));
@include mixins.breakpoint-iterator(var.$flex-wrap, "flex", ("flex-wrap"));
@include mixins.breakpoint-iterator(var.$flex-justify-content, "justify-content", ("justify-content"));
@include mixins.breakpoint-iterator(var.$flex-align-items, "align-items", ("align-items"));
@include mixins.breakpoint-iterator(var.$flex-align-items, "align-self", ("align-self"));
@include mixins.breakpoint-iterator(var.$flex-shrink, "flex-shrink", ("flex-shrink"));
@include mixins.breakpoint-iterator(var.$flex-shrink, "flex-grow", ("flex-grow"));
@include mixins.breakpoint-iterator(var.$spacers, "g", ("gap"));
@include mixins.breakpoint-iterator(var.$spacers, "gx", ("column-gap"));
@include mixins.breakpoint-iterator(var.$spacers, "gy", ("row-gap"));
@include mixins.breakpoint-iterator(var.$flex-values, "flex", ("flex"));
@include mixins.breakpoint-iterator(var.$orders, "order", ("order"));
